import React, { useState, useEffect } from 'react';
import { X, ChevronRight, ChevronLeft } from 'lucide-react';

const TourStep = ({ target, title, description, position }) => {
  const [coords, setCoords] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      const element = document.querySelector(target);
      if (element) {
        const rect = element.getBoundingClientRect();
        setCoords({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [target]);

  return (
    <div 
      className="absolute z-50 bg-white p-4 rounded-lg shadow-lg"
      style={{
        top: `${coords.top + (position === 'bottom' ? 20 : -120)}px`,
        left: `${coords.left}px`,
        maxWidth: '300px'
      }}
    >
      <h3 className="text-lg font-bold mb-2">{title}</h3>
      <p>{description}</p>
    </div>
  );
};

const InteractiveTour = ({ steps, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);

  // Check if steps is undefined or empty
  if (!steps || steps.length === 0) {
    console.warn('No steps provided for the tour');
    return null;
  }

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="fixed inset-0 z-40 bg-black bg-opacity-30">
      {steps[currentStep] && <TourStep {...steps[currentStep]} />}
      <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg flex items-center">
        <button 
          onClick={handlePrevious} 
          disabled={currentStep === 0}
          className="p-2 disabled:opacity-50"
        >
          <ChevronLeft size={24} />
        </button>
        <span className="mx-4">
          {currentStep + 1} / {steps.length}
        </span>
        <button 
          onClick={handleNext}
          className="p-2"
        >
          {currentStep === steps.length - 1 ? <X size={24} /> : <ChevronRight size={24} />}
        </button>
      </div>
    </div>
  );
};

export default InteractiveTour;