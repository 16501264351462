import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import TermsAndConditions from './TermsAndConditions'; // Make sure to import the TermsAndConditions component

const Signup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    whyHere: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);

  const getInputClassName = (fieldName) => {
    const hasError = error && error.includes(fieldName);
    const baseClassName = 'w-full px-3 py-2 border rounded focus:outline-none';
    const dynamicClassName = hasError ? 'focus:border-red-500' : 'focus:border-blue-500';
    return `${baseClassName} ${dynamicClassName}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'confirmPassword') {
      setPasswordMatch(value === formData.password);
    }

    if (name === 'password') {
      setShowPasswordStrength(value !== '');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const passwordStrength = () => {
    const { password } = formData;

    const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    const mediumRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})/;

    if (strongRegex.test(password)) {
      return 'Strong';
    } else if (mediumRegex.test(password)) {
      return 'Medium';
    } else {
      return 'Weak';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      setError('You must accept the Terms and Conditions to sign up.');
      return;
    }
    setIsLoading(true);

    const isAnyFieldEmpty = Object.values(formData).some((value) => value === '');

  
    try {
      // Generate verification code
      const verificationCode = Math.floor(100000 + Math.random() * 900000).toString();

      // Make API call to send verification code and signup data
      const response = await axios.post('https://serenimindbackend.onrender.com/api/signup/', {
        ...formData,
        verificationCode,
        
      });

      console.log('Signup successful', response.data);

      // Send verification email
// Send verification email
      await sendVerificationEmail(formData.firstName, formData.email, verificationCode);
      setIsLoading(false);

      // Redirect to verification page
      navigate(`/verification?email=${formData.email}`);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.error('Error Response:', error.response.data);

        if (error.response.data.errors) {
          const { errors } = error.response.data;

          if (errors.email) {
            console.error('Email Error:', errors.email[0]);
            setError(errors.email[0]);
          }

          if (errors.username) {
            console.error('Username Error:', errors.username[0]);
            setError(errors.username[0]);
          }
        }
      } else if (error.request) {
        console.error('No response received');
      } else {
        console.error('Error during request setup:', error.message);
      }
    }
  };

  const sendVerificationEmail = async (firstName, email, verificationCode) => {
    try {
      // Send verification email using Django's email sending functionality
      const response = await axios.post('https://serenimindbackend.onrender.com/api/send-verification-email/', {
        email,
        verificationCode,
        firstName,
      });

      console.log('Verification email sent successfully', response.data);
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
    setIsLoading(false); // Reset loading state after request is processed
  };

  return (
<div className="relative flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 px-4 py-6">
  <div className="absolute top-0 right-0 w-48 h-48 bg-green-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
  <div className="absolute bottom-0 left-0 w-48 h-48 bg-yellow-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
  <div className="absolute top-0 left-1/4 w-48 h-48 bg-pink-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>

  <div className="w-full max-w-md">
    <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
      <div className="px-8 py-10">
        <h2 className="mb-6 text-4xl font-bold text-center text-gray-800">
          Become a <span className="text-[#59844F]">Member</span>
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-700">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder='First name'
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              required
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-700">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder='Last name'
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              required
            />
          </div>
          <div>
            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-700">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              placeholder="Username (case sensitive) e.g @John77"
              max="10"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              placeholder='Email'
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              required
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-700">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder='Enter your password'
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 top-8"
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className="text-gray-400" />
            </button>
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-700">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder='Enter your password again'
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              required
            />
          </div>
          
          {showPasswordStrength && (
            <div className="mt-2">
              <p className="text-sm text-gray-600 mb-1">Password Strength: {passwordStrength()}</p>
              <div className="h-2 rounded-full bg-gray-200">
                <div
                  className={`h-full rounded-full transition-all duration-300 ${
                    passwordStrength() === 'Strong'
                      ? 'bg-green-500 w-full'
                      : passwordStrength() === 'Medium'
                      ? 'bg-yellow-500 w-2/3'
                      : 'bg-red-500 w-1/3'
                  }`}
                ></div>
              </div>
            </div>
          )}

          {!passwordMatch && (
            <p className="mt-2 text-sm text-red-600" id="password-error">
              Passwords do not match
            </p>
          )}

          {error && (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded" role="alert">
              <p>{error}</p>
            </div>
          )}

<div className="flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                  className="mr-2"
                />
                <label htmlFor="terms" className="text-sm text-gray-600">
                  I accept the{' '}
                  <button
                    type="button"
                    onClick={() => setShowTerms(true)}
                    className="text-[#59844F] hover:underline focus:outline-none"
                  >
                    Terms and Conditions
                  </button>
                </label>
              </div>

              {error && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded" role="alert">
                  <p>{error}</p>
                </div>
              )}

              <button
                type="submit"
                className="w-full bg-[#59844F] text-white py-3 px-4 rounded-md hover:bg-[#2c3a20] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#59844F] transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading || !acceptedTerms}
              >
                {isLoading ? (
                  <svg className="animate-spin h-5 w-5 mx-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  "Sign Up"
                )}
              </button>
            </form>
        <p className="mt-8 text-center text-sm text-gray-600">
          Already have an account?{' '}
          <Link to="/login" className="font-medium text-[#59844F] hover:text-[#2c3a20] transition">
            Login Now
          </Link>
        </p>
      </div>
    </div>
  </div>
  {showTerms && <TermsAndConditions onClose={() => setShowTerms(false)} />}
</div>
  );
};

export default Signup;

