import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faShare, faEdit, faGear, faArrowRight, faTimes, faBars, faFlag, faArrowDown, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import CommunityDataPage from '../community/CommunityData';
import axiosInstance from '../components/axiosInstance';
import { ClipLoader } from 'react-spinners'; // Install this with 'npm install react-spinners'

const CommunityPost = () => {
  const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const { communityId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [community, setCommunity] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [user_id, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [senderInfo, setSenderInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);

  useEffect(() => {
    const fetchedCommunity = CommunityDataPage().find(comm => comm.id === parseInt(communityId));
    setCommunity(fetchedCommunity);
    fetchMessages();
    getUserId();
  }, [communityId]);

  useEffect(() => {
    const fetchSenderInfo = async () => {
      try {
        const response = await axiosInstance.get(`https://serenimindbackend.onrender.com/api/messages/${communityId}/sender/`);
        setSenderInfo(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sender info:', error);
        setLoading(false);
      }
    };

    fetchSenderInfo();
  }, [communityId]);

  useEffect(() => {
    const handleScroll = () => {
      if (messageListRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
        setShowScrollButton(scrollHeight - scrollTop > clientHeight + 100);
      }
    };

    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messageList) {
        messageList.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const getUserId = async () => {
    try {
      const response = await axiosInstance.get(`https://serenimindbackend.onrender.com/api/user-info-dashboard/`);
      const { user_id, username } = response.data;
      setUserId(user_id);
      setUsername(username);
      return user_id;
    } catch (error) {
      console.error('Error fetching user ID:', error);
      return null;
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axiosInstance.get(`https://serenimindbackend.onrender.com/api/community/${communityId}/messages/`);
      setMessages(response.data);
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendMessage = async () => {
    setLoading(true);
    if (!newMessage.trim()) return;
    try {
      const userId = await getUserId();
      const data = {
        user: userId,
        community: communityId,
        content: newMessage,
      };
      const response = await axiosInstance.post(`https://serenimindbackend.onrender.com/api/community/${communityId}/messages/`, data);
      setMessages([...messages, response.data]);
      setNewMessage('');
      scrollToBottom();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert(error.response.data.error);
      } else {
        console.error('Error sending message:', error);
      }
    }finally{
      setLoading(false);
    }
  };

  const handleEditMessage = async (messageId, updatedContent) => {
    if (!updatedContent.trim()) return;
    try {
      await axiosInstance.put(`https://serenimindbackend.onrender.com/api/message/${messageId}/`, {
        content: updatedContent,
      });
      const updatedMessages = messages.map(message =>
        message.id === messageId ? { ...message, content: updatedContent } : message
      );
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Error editing message:', error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!('Are you sure you want to delete this message?')) return;
    try {
      await axiosInstance.delete(`https://serenimindbackend.onrender.com/api/message/${messageId}/`);
      const updatedMessages = messages.filter(message => message.id !== messageId);
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleReportMessage = async (messageId) => {
    setLoading(true); // Show spinner
    if (!('Are you sure you want to report this message?')) return;
    try {
      await axiosInstance.post(`https://serenimindbackend.onrender.com/api/report-message/${messageId}/`);
      alert('Message reported successfully');
    } catch (error) {
      console.error('Error reporting message:', error);
      alert('Failed to report message');
    }finally {
      setLoading(false);
    }
  };

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  const toggleActivities = () => {
    setIsActivitiesOpen(!isActivitiesOpen);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Side Navigation */}
      <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>

      {/* Main Content */}
      <div className="container mx-auto max-w-[950px] overflow-y-auto md:h-[600px]  ">
        <div className="container h-full max-w-4xl p-4 mx-auto">
          <div className="flex flex-col h-full overflow-hidden bg-white rounded-lg shadow-lg">
            {/* Community Header */}
            <div className="p-4 bg-gradient-to-r from-[#59844F] to-green-600">
              <h1 className="text-2xl font-bold text-white">{community.name} Community</h1>
            </div>

            {/* Message List */}
            <div ref={messageListRef} className="flex-1 p-4 overflow-y-auto">
              {messages.map(message => (
                <div key={message.id} className={`flex flex-col ${user_id === message.user.id ? 'items-end' : 'items-start'} mb-4 group`}>
                  <div className={`relative p-3 rounded-lg ${user_id === message.user.id ? 'bg-[#59844F] text-white' : 'bg-gray-200 text-gray-800'}`}>
                    <p className="mb-1">{message.content}</p>
                    <div className="flex items-center justify-between mt-2 text-sm">
                      <span className="font-semibold">{message.user.username}</span>
                      <span className="ml-2 text-xs opacity-75">{new Date(message.timestamp).toLocaleString()}</span>
                    </div>
                    {/* Report button */}
                    <button 
                      onClick={() => handleReportMessage(message.id)} 
                      className="absolute top-0 right-0 p-1 text-xs text-[#59844F] transition-opacity duration-200 bg-white rounded-full opacity-100"
                      title="Report message"
                    >
                      <FontAwesomeIcon icon={faFlag} />
                    </button>
                  </div>
                  {user_id === message.user_id && (
                    <div className="flex mt-1 space-x-2">
                      <button onClick={() => handleEditMessage(message.id, prompt('Edit message:', message.content))} className="text-sm text-[#59844F] hover:text-green-700">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => handleDeleteMessage(message.id)} className="text-sm text-red-500 hover:text-red-700">
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            {/* New Message Input */}
            <div className="p-4 bg-gray-100 border-t border-gray-300">
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                  placeholder="Type your message here..."
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#59844F]"
                />
                <button onClick={sendMessage} className="px-4 py-2 font-semibold text-white transition-colors duration-200 bg-[#59844F] rounded-full hover:bg-[#476b40] focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:ring-offset-2">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-green bg-opacity-75 z-50">
          <ClipLoader size={150} color={"#59844F"} />
        </div>
      )}
      </div>

      {/* Scroll to Bottom Button */}
      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className="fixed p-3 text-white transition-colors duration-200 transform -translate-x-1/2 bg-[#59844F] rounded-full shadow-lg bottom-8 left-1/2 hover:bg-[#46693f] focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:ring-offset-2"
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </button>
      )}
    </div>
  );
};

export default CommunityPost;