import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faShare, faEdit, faGear, faArrowRight, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CommunityDataPage from '../community/CommunityData';


const Community = () => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [communities, setCommunities] = useState([]);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };
const toggleActivities = () => {
  setIsActivitiesOpen(!isActivitiesOpen);
};
  
const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};

// Function to filter communities based on search term
const filteredCommunities = communities.filter(community =>
  community.categories.some(category => category.toLowerCase().includes(searchTerm.toLowerCase()))
);

// Fetch communities data from CommunityDataPage component
React.useEffect(() => {
  const data = CommunityDataPage();
  setCommunities(data);
}, []);

  return (
    <div className='flex min-h-screen bg-gray-100'>
             
             <div className={"`  transform  ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'}  w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav `"}>
        <div className="p-6">
          <nav>
            <ul className="space-y-2">
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
              </button></li>
            </ul>
          </nav>
        </div>
        
      </div>
      <div className="w-[900px] mx-auto bg-gray-200 overflow-y-auto md:h-[560px]">
      <div className="container px-4 py-8 mx-auto ">
      <h1 className="mb-6 text-3xl font-semibold text-center">Community Page</h1>
      <input
        type="text"
        placeholder="Search by category..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="w-full px-4 py-2 mb-6 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
      />
      {filteredCommunities.length === 0 && (
        <p className="mb-6 text-center text-gray-600">Community "{searchTerm}" not found.</p>
      )}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
        {filteredCommunities.slice(0, 30).map(community => (
          <div key={community.id} className="p-6 bg-white rounded-md shadow-md">
            <h2 className="mb-2 text-lg font-semibold">{community.name}</h2>
            <p className="mb-4 text-gray-600 text-[16px]">{community.description}</p>
            {/* Use Link component to navigate to the community page */}
            <Link to={`/community-post/${community.id}`} className="inline-flex items-center px-4 py-2 mb-2 mr-2 text-white bg-blue-500 rounded-md">
              <FontAwesomeIcon icon="user-plus" className="mr-2" /> Join
            </Link>
            <button className="inline-flex items-center px-4 py-2 mb-2 text-white bg-green-500 rounded-md">
              <FontAwesomeIcon icon="share-alt" className="mr-2" /> Share
            </button>
          </div>
        ))}
      </div>
    </div>
    </div>
    </div>
    
  );
};

export default Community;
