import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import navigate function

function CreateCommunities() {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await axios.post('https://serenimindbackend.onrender.com/api/communities/', formData);
            console.log('Community created successfully:', response.data);
            window.location.reload();
            // You can handle success, e.g., show a success message or redirect the user
        } catch (error) {
            setIsLoading(false);
            console.error('Error creating community:', error);
            // You can handle errors, e.g., show an error message to the user
        }
    };

    return (
        <div className="max-w-md mx-auto my-8">
            <form onSubmit={handleSubmit} className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
                <h2 className="mb-4 text-2xl">Create New Community</h2>
                <div className="mb-4">
                    <label htmlFor="name" className="block mb-2 text-sm font-bold text-gray-700">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter community name"
                        className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="description" className="block mb-2 text-sm font-bold text-gray-700">Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Enter community description"
                        className="w-full h-32 px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none resize-none focus:outline-none focus:shadow-outline"
                        required
                    ></textarea>
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                        disabled={isLoading} // Disable button when loading
                    >
                        {isLoading ? 'Creating...' : 'Create'}
                        {showSuccessMessage && (
          <div className="absolute p-4 bg-white border border-gray-300 rounded-md bottom-10 right-10">
            <p>Community has been created.</p>
          </div>
        )}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CreateCommunities;
