import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faBars, faCog, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

// Predefined list of movies with associated moods
const movieDatabase = [
  { 
    "title": "Up", 
    "mood": ["happy", "inspirational", "adventure"], 
    "image": "https://image.tmdb.org/t/p/w300/2TeJfUZMGolfDdW6DKhfIWqvq8y.jpg", 
    "description": "Carl sets out to fulfill his lifelong dream of a great adventure." 
  },
  { 
    "title": "Inside Out", 
    "mood": ["emotional", "family", "thoughtful"], 
    "image": "https://image.tmdb.org/t/p/w300/lRHE0vzf3oYJrhbsHXjIkF4Tl5A.jpg", 
    "description": "The emotions inside a young girl's head come to life." 
  },
  { 
    "title": "The Pursuit of Happyness", 
    "mood": ["inspired", "motivated", "emotional"], 
    "image": "https://images.squarespace-cdn.com/content/v1/5891089bbf629a5f22f794c8/1490801740328-6N2HU2UEV0VW64CVNLXQ/the-pusuit-of-happyness.jpg?format=1500w", 
    "description": "A struggling salesman takes custody of his son as he's poised to begin a life-changing professional career." 
  },
  { 
    "title": "Forrest Gump", 
    "mood": ["inspired", "heartwarming", "emotional"], 
    "image": "https://image.tmdb.org/t/p/w300/clolk7rB5lAjs41SD0Vt6IXYLMm.jpg", 
    "description": "The presidencies of Kennedy and Johnson, the Vietnam War, the Watergate scandal and other historical events unfold from the perspective of an Alabama man with an IQ of 75." 
  },
  { 
    "title": "The Intouchables", 
    "mood": ["uplifting", "heartwarming", "funny"], 
    "image": "https://image.tmdb.org/t/p/w300/323BP0itpxTsO0skTwdnVmf7YC9.jpg", 
    "description": "After he becomes a quadriplegic from a paragliding accident, an aristocrat hires a young man from the projects to be his caregiver." 
  },
  { 
    title: "The Peanut Butter Falcon", 
    mood: ["lost", "lonely", "frustrated"], 
    image: "https://i.ytimg.com/vi/UNl9RqjLCwc/maxresdefault.jpg", 
    description: "A young man with Down syndrome runs away from his care home to follow his dreams of becoming a professional wrestler."
  },
  { 
    title: "Big Fish", 
    mood: ["grief", "sad", "hopeless"], 
    image: "https://upload.wikimedia.org/wikipedia/en/4/41/Big_Fish_movie_poster.png", 
    description: "A son tries to learn more about his dying father by reliving the tall tales and legendary stories he told about his life."
  },
  { 
    title: "Julie & Julia", 
    mood: ["uninspired", "anxious", "lost"], 
    image: "https://www.confessionsofachocoholic.com/wp-content/uploads/2009/08/julie-and-julia.png", 
    description: "A woman sets out to cook all 524 recipes in Julia Child's cookbook while balancing her everyday life."
  },
  { 
    title: "The Secret Garden", 
    mood: ["sad", "grief", "lonely"], 
    image: "https://upload.wikimedia.org/wikipedia/en/2/2e/The_Secret_Garden_2020_film_poster.jpg", 
    description: "A young orphaned girl discovers a magical garden on her uncle's estate and unlocks the healing powers of nature."
  },
  { 
    title: "Chef", 
    mood: ["uninspired", "frustrated", "disheartened"], 
    image: "https://facts.net/wp-content/uploads/2023/06/42-facts-about-the-movie-chef-1687923123.jpg", 
    description: "After quitting his job, a chef starts a food truck to rediscover his love for cooking and reconnect with his son."
  },
  { 
    title: "Mamma Mia!", 
    mood: ["sad", "depressed", "hopeless"], 
    image: "https://upload.wikimedia.org/wikipedia/en/a/a6/MammaMiaTeaserPoster.JPG", 
    description: "A young woman invites three men to her wedding, hoping one of them is her father, all while music and chaos unfold."
  },
  { 
    title: "The Hundred-Foot Journey", 
    mood: ["lonely", "disheartened", "uninspired"], 
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyv4CPdcw82mAphigy7cnl2y7kSULeGIdKTg&s", 
    description: "An Indian family opens a restaurant in a quaint French village, creating competition with a nearby Michelin-starred restaurant."
  },
  { 
    title: "Finding Nemo", 
    mood: ["sad", "anxious", "depressed"], 
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz3Pz9p1EuhGmwv_TZXzPBrteQfnQHkpz-fw&s", 
    description: "A father embarks on a journey to find his son after the young clownfish is captured in the Great Barrier Reef."
  },
  { 
    title: "A Beautiful Mind", 
    mood: ["hopeless", "lost", "disillusioned"], 
    image: "https://resizing.flixster.com/-XZAfHZM39UwaGJIFWKAE8fS0ak=/v3/t/assets/p28869_p_v13_ap.jpg", 
    description: "The story of John Nash, a brilliant but socially awkward mathematician who battles schizophrenia and achieves greatness."
  },
  { 
    title: "Soul", 
    mood: ["lost", "uninspired", "anxious"], 
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU6pobf5NVlQRNFWYshbJoPsbFmdM_h3iIyw&s", 
    description: "A middle-school music teacher finds himself transported out of his body and must find his way back with the help of a soul-in-training."
  },
  { 
    title: "The Lion King", 
    mood: ["grief", "sad", "lonely"], 
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCNGI7Mz8944Mi9pqejpPMPXys_kYV49mcOA&s", 
    description: "A young lion prince, Simba, flees his kingdom after the murder of his father but must return to claim his rightful place."
  },
  { 
    title: "Ratatouille", 
    mood: ["uninspired", "lost", "anxious"], 
    image: "https://resizing.flixster.com/ySiX7RlyKRuuxCcAI7SgdkMAZ0U=/ems.cHJkLWVtcy1hc3NldHMvbW92aWVzLzc4ZmJhZjZiLTEzNWMtNDIwOC1hYzU1LTgwZjE3ZjQzNTdiNy5qcGc=", 
    description: "A rat named Remy dreams of becoming a great chef despite his family's wishes and the obvious problem of being a rat in a rat-phobic profession."
  },
  { 
    title: "The Bucket List", 
    mood: ["hopeless", "sad", "disillusioned"], 
    image: "https://m.media-amazon.com/images/M/MV5BMTY2NTUyMjIyNF5BMl5BanBnXkFtZTYwNzYwMDM4._V1_.jpg", 
    description: "Two terminally ill men escape from a cancer ward and head off on a road trip with a wish list of to-dos before they die."
  },
  { 
    title: "The Help", 
    mood: ["sad", "disheartened", "frustrated"], 
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmQLvvpPfSwUykruTv5GLoeswPOO3WxNfi1w&s", 
    description: "During the civil rights movement, an aspiring author interviews black maids to give voice to their untold stories."
  },
  { 
    title: "Little Women", 
    mood: ["lonely", "disheartened", "sad"], 
    image: "https://resizing.flixster.com/oK8sg8iQmuSh_x95hdUg4xleaf0=/fit-in/705x460/v2/https://resizing.flixster.com/-XZAfHZM39UwaGJIFWKAE8fS0ak=/v3/t/assets/p17008397_v_h9_ag.jpg", 
    description: "The March sisters come of age in the aftermath of the Civil War, learning about love, loss, and family bonds."
  },
  { 
    title: "About Time", 
    mood: ["heartbroken", "grief", "lonely"], 
    image: "https://resizing.flixster.com/q0tPUDg0ltX6rXKyYgSnTZWa7yI=/fit-in/352x330/v2/https://resizing.flixster.com/-XZAfHZM39UwaGJIFWKAE8fS0ak=/v3/t/assets/p9564054_v_v13_ax.jpg", 
    description: "A young man discovers he can travel in time, and uses his power to improve his life and find love."
  },
  { 
    title: "Eternal Sunshine of the Spotless Mind", 
    mood: ["heartbroken", "depressed", "grief"], 
    image: "https://www.nbcstore.com/cdn/shop/files/Size_Mobile_SBS_Banner_0f505c84-26dc-4891-908b-a5205918b73b.png?v=1710791291", 
    description: "After a painful breakup, a couple undergoes a procedure to erase memories of each other, but soon discover the value of their past."
  },
  { 
    title: "Moana", 
    mood: ["lost", "uninspired", "overwhelmed"], 
    image: "https://lumiere-a.akamaihd.net/v1/images/p_moana_20530_214883e3.jpeg?region=0%2C0%2C540%2C810", 
    description: "A young girl sets sail across the ocean to save her island, with the help of a once-mighty demigod."
  },
  { 
    title: "Wonder", 
    mood: ["depressed", "lonely", "sad"], 
    image: "https://ntvb.tmsimg.com/assets/p13369952_v_h10_aj.jpg?w=960&h=540", 
    description: "A boy with facial differences attends a mainstream school for the first time, teaching others the true meaning of kindness."
  },
  { 
    title: "The Holiday", 
    mood: ["heartbroken", "lonely", "sad"], 
    image: "https://unhurry.in/wp-content/uploads/2022/12/The-Holiday.jpeg", 
    description: "Two women, both heartbroken, swap homes for the holidays and find love and hope in unexpected places."
  },

  // Add more movies as needed
];

const Movies = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [recommendedMovies, setRecommendedMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const toggleSideNav = () => setIsSideNavOpen(!isSideNavOpen);

  const searchMoviesByMood = () => {
    setIsLoading(true);
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredMovies = movieDatabase.filter(movie => 
      movie.mood.some(mood => mood.includes(lowerCaseQuery))
    );
    setRecommendedMovies(filteredMovies);
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchQuery) {
      const debounce = setTimeout(() => {
        searchMoviesByMood();
      }, 300);
      return () => clearTimeout(debounce);
    }
  }, [searchQuery]);

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Side Navigation */}
      <div className={"`  transform  ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'}  w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav `"}>
        <div className="p-6">
          <nav>
            <ul className="space-y-2">
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
              </button></li>
            </ul>
          </nav>
        </div>
        
      </div>

      {/* Main Content */}
      <main className="flex-1 p-6 overflow-hidden container mx-auto px-4 w-[900px] overflow-y-auto md:h-[560px] ">

        <h1 className="text-3xl font-bold mb-6 text-center">Mood-Based Movie Recommender</h1>
        
        {/* Search Input */}
        <div className="max-w-md mx-auto mb-8">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Enter your mood..."
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
            />
            <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>

        {/* Movie Recommendations */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 flex-1">
          {isLoading ? (
            <p className="text-center col-span-full">Finding the perfect movies for your mood...</p>
          ) : recommendedMovies.length > 0 ? (
            recommendedMovies.map((movie, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                <img src={movie.image} alt={movie.title} className="w-full h-48 " />
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{movie.title}</h3>
                  <p className="text-sm text-gray-600 mb-2">{movie.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {movie.mood.map((mood, moodIndex) => (
                      <span key={moodIndex} className="px-2 py-1 bg-green-100 text-[#59844F] rounded-full text-xs">
                        {mood}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))
          ) : searchQuery && (
            <div className='flex justify-center text-center col-span-full'>
              <div className="text-center py-8 ">
            <p className="text-xl text-gray-600 mb-4 text-center">We couldn't find movies for that mood.</p>
            <p className="text-lg text-gray-500">
              Help us improve! <Link to="/contact" className="text-blue-500 hover:underline">Contact us</Link> to suggest movies for this mood.
            </p>
          </div>
            </div>
                  )}
        </div>
      </main>
    </div>
  );
};

export default Movies;