import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { ref } = useParams();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    setIsLoading(true); // Show the loading message
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await axios.post(
        `https://serenimindbackend.onrender.com/api/password_reset/confirm/`,
        { password, token: ref },
        { headers: { 'Content-Type': 'application/json' } }
      );

      console.log("Response received:", response);
      setSuccessMessage('Password reset successful. Redirecting to login page...');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      console.error("Error occurred:", error);
      console.error("Error response:", error.response);
      setErrorMessage('Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false); // Hide the loading message
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="mb-4 text-3xl font-bold">Reset Password</h1>
      <form onSubmit={handleResetPassword} className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2 font-semibold text-gray-700">New Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-2 font-semibold text-gray-700">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-[#59844F]"
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 font-bold text-white bg-[#59844F] rounded hover:bg-[#0a8755ea] focus:outline-none focus:shadow-outline"
        >
          Reset Password
        </button>
      </form>
      {isLoading && <p className="mt-4 text-blue-500">Resetting password...</p>}
      {successMessage && <p className="mt-4 text-green-500">{successMessage}</p>}
      {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
      <Link to="/login" className="mt-4 text-[#59844F] hover:text-[#0a8755ea]">Back to Login</Link>
    </div>
  );
};

export default ResetPasswordForm;
