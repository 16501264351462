import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBook, faBars, faRobot, faUserTie, faGamepad, faBlog, faJournalWhills, faUsers, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';  

const bookList = [
  { 
    title: "The Happiness of Pursuit",
    author: "Chris Guillebeau",
    genre: "Self-help",
    mood: ["inspired", "motivated", "tired"],
    link: "https://www.goodreads.com/book/show/18770589-the-happiness-of-pursuit",
    description: "Explores how ordinary people can pursue extraordinary goals and find purpose in their lives.",
    howItHelps: "Provides inspiration and practical advice for those feeling unmotivated or seeking direction in life.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1403535131i/20170321.jpg"
  },
  { 
    title: "Thinking, Fast and Slow",
    author: "Daniel Kahneman",
    genre: "Psychology",
    mood: ["curious", "thoughtful"],
    link: "https://www.goodreads.com/book/show/11468377-thinking-fast-and-slow",
    description: "Explores the two systems that drive the way we think and make choices.",
    howItHelps: "Offers insights into decision-making processes, helping readers understand and improve their thought patterns.",
    coverImage: "https://cdn.kobo.com/book-images/ec0ea1e4-af21-4129-91d4-f3c78766af67/1200/1200/False/thinking-fast-and-slow.jpg"
  },
  { 
    title: "The Alchemist",
    author: "Paulo Coelho",
    genre: "Fiction",
    mood: ["hopeful", "introspective"],
    link: "https://www.goodreads.com/book/show/865.The_Alchemist",
    description: "A philosophical novel about a young shepherd's journey to find his destiny.",
    howItHelps: "Inspires readers to follow their dreams and find their personal legend, offering hope and encouragement.",
    coverImage: "https://buybooks.ng/wp-content/uploads/2020/06/web-9-1.jpg"
  },
  { 
    title: "Man's Search for Meaning",
    author: "Viktor E. Frankl",
    genre: "Psychology",
    mood: ["reflective", "inspired"],
    link: "https://www.goodreads.com/book/show/4069.Man_s_Search_for_Meaning",
    description: "Frankl's memoir of life in Nazi death camps and its lessons for spiritual survival.",
    howItHelps: "Offers profound insights into finding meaning in life, even in the face of extreme suffering.",
    coverImage: "https://cdn.kobo.com/book-images/52c1eab8-f621-4f33-86e2-92f568001482/1200/1200/False/man-s-search-for-meaning.jpg"
  },
  { 
    title: "The Power of Now",
    author: "Eckhart Tolle",
    genre: "Spirituality",
    mood: ["calm", "mindful"],
    link: "https://www.goodreads.com/book/show/6708.The_Power_of_Now",
    description: "A guide to spiritual enlightenment focused on living in the present moment.",
    howItHelps: "Teaches techniques for reducing anxiety and stress by focusing on the present moment.",
    coverImage: "https://buybooks.ng/wp-content/uploads/2020/12/41cbPheOxmL._SX323_BO1204203200_.jpg"
  },
  {
    title: "To Kill a Mockingbird",
    author: "Harper Lee",
    genre: "Fiction",
    mood: ["thought-provoking", "empathetic"],
    link: "https://www.goodreads.com/book/show/2657.To_Kill_a_Mockingbird",
    description: "A novel about racial injustice and moral growth in the American South.",
    howItHelps: "Encourages readers to reflect on moral and social issues, promoting empathy and understanding.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn5xkKB1ByJI59VpGEBBkkFRN0_FJ9COkr2g&s"
  },
  {
    title: "1984",
    author: "George Orwell",
    genre: "Dystopian",
    mood: ["dark", "cautionary"],
    link: "https://www.goodreads.com/book/show/40961427-1984",
    description: "A dystopian novel exploring the dangers of totalitarianism and extreme political ideology.",
    howItHelps: "Raises awareness about the importance of individual freedoms and the dangers of oppressive governments.",
    coverImage: "https://m.media-amazon.com/images/I/71rpa1-kyvL._AC_UF1000,1000_QL80_.jpg"
  },
  {
    title: "Pride and Prejudice",
    author: "Jane Austen",
    genre: "Classic",
    mood: ["romantic", "satirical"],
    link: "https://www.goodreads.com/book/show/1885.Pride_and_Prejudice",
    description: "A classic novel that explores themes of love, reputation, and social class in 19th-century England.",
    howItHelps: "Provides insight into societal norms and personal relationships with a touch of humor and romance.",
    coverImage: "https://readaloudrevival.com/wp-content/uploads/2016/05/Pride-and-Prejudice.png.webp"
  },
  {
    title: "The Catcher in the Rye",
    author: "J.D. Salinger",
    genre: "Fiction",
    mood: ["reflective", "alienated"],
    link: "https://www.goodreads.com/book/show/5107.The_Catcher_in_the_Rye",
    description: "A novel about a teenager's struggles with identity and belonging.",
    howItHelps: "Resonates with readers who feel out of place, offering a voice to their own feelings of isolation.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmtWgq6tTVtT_CluoMU0-PntXerZBCh1aXmA&s"
  },
  {
    title: "The Great Gatsby",
    author: "F. Scott Fitzgerald",
    genre: "Classic",
    mood: ["tragic", "opulent"],
    link: "https://www.goodreads.com/book/show/4671.The_Great_Gatsby",
    description: "A tale of wealth, love, and disillusionment in 1920s America.",
    howItHelps: "Explores themes of ambition and the American Dream, offering a critical look at social stratification.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQigoqAAyygUsm-If44c82OYi5RzmFDyqYhXg&s"
  },
  {
    title: "The Hobbit",
    author: "J.R.R. Tolkien",
    genre: "Fantasy",
    mood: ["adventurous", "whimsical"],
    link: "https://www.goodreads.com/book/show/5907.The_Hobbit",
    description: "A fantasy novel about a hobbit's unexpected journey to help reclaim a stolen treasure.",
    howItHelps: "Encourages imagination and the joy of adventure, appealing to readers of all ages.",
    coverImage: "https://m.media-amazon.com/images/I/712cDO7d73L._AC_UF1000,1000_QL80_.jpg"
  },
  {
    title: "Moby-Dick",
    author: "Herman Melville",
    genre: "Classic",
    mood: ["epic", "philosophical"],
    link: "https://www.goodreads.com/book/show/153747.Moby_Dick",
    description: "An epic tale of a sea captain's obsession with hunting a giant white whale.",
    howItHelps: "Offers deep philosophical insights into the human condition and the nature of obsession.",
    coverImage: "https://images.penguinrandomhouse.com/cover/9780143105954"
  },
  {
    title: "Brave New World",
    author: "Aldous Huxley",
    genre: "Dystopian",
    mood: ["satirical", "critical"],
    link: "https://www.goodreads.com/book/show/5479.Brave_New_World",
    description: "A dystopian novel about a future society driven by technological advancements and social conditioning.",
    howItHelps: "Challenges readers to think critically about technology and its impact on human values and freedom.",
    coverImage: "https://upload.wikimedia.org/wikipedia/en/6/62/BraveNewWorld_FirstEdition.jpg"
  },
  {
    title: "The Road",
    author: "Cormac McCarthy",
    genre: "Post-Apocalyptic",
    mood: ["bleak", "hopeful"],
    link: "https://www.goodreads.com/book/show/6288.The_Road",
    description: "A harrowing journey of a father and son across a desolate post-apocalyptic landscape.",
    howItHelps: "Explores themes of survival and the bond between parent and child, offering a sense of hope amid despair.",
    coverImage: "https://www.writing.ie/wp-content/uploads/2021/07/The-road.jpg"
  },
  {
    title: "Jane Eyre",
    author: "Charlotte Brontë",
    genre: "Classic",
    mood: ["passionate", "resilient"],
    link: "https://www.goodreads.com/book/show/10210.Jane_Eyre",
    description: "A novel about the trials and triumphs of an orphaned young woman who finds love and independence.",
    howItHelps: "Empowers readers with its themes of personal integrity and self-respect.",
    coverImage: "https://m.media-amazon.com/images/I/91zU70Aw9IS._AC_UF1000,1000_QL80_.jpg"
  },
  {
    title: "The Bell Jar",
    author: "Sylvia Plath",
    genre: "Fiction",
    mood: ["dark", "introspective"],
    link: "https://www.goodreads.com/book/show/6588.The_Bell_Jar",
    description: "A semi-autobiographical novel about a young woman's struggle with mental illness.",
    howItHelps: "Provides a raw and honest exploration of mental health issues, fostering understanding and empathy.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA-0QEuROxnqSjpvdHay_RogriIy6FvpkxrQ&s"
  },
  {
    title: "The Hitchhiker's Guide to the Galaxy",
    author: "Douglas Adams",
    genre: "Science Fiction",
    mood: ["humorous", "quirky"],
    link: "https://www.goodreads.com/book/show/11.The_Hitchhikers_Guide_to_the_Galaxy",
    description: "A comedic science fiction adventure about an ordinary man's journey through space.",
    howItHelps: "Offers a light-hearted and humorous escape from reality with clever social commentary.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1404613595i/13.jpg"
  },
  {
    title: "The Little Prince",
    author: "Antoine de Saint-Exupéry",
    genre: "Children's Fiction",
    mood: ["whimsical", "philosophical"],
    link: "https://www.goodreads.com/book/show/15799365-the-little-prince",
    description: "A philosophical tale about a young prince's travels and the wisdom he gains along the way.",
    howItHelps: "Encourages readers to see the world through a childlike lens and appreciate the simple, important things in life.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpuNT3KgTeEilCsIRBwh5EkxiyR0lI5w2vnQ&s"
  },
  {
    title: "Catch-22",
    author: "Joseph Heller",
    genre: "Satire",
    mood: ["darkly humorous", "absurd"],
    link: "https://www.goodreads.com/book/show/4689.Catch_22",
    description: "A satirical novel about the absurdities of war and bureaucracy.",
    howItHelps: "Offers a critical and humorous perspective on the irrationalities of war and institutional power.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROczHEUuyIHf9171M6Hbl5ANFjDYEPZztW3Q&s"
  },
  {
    title: "Fahrenheit 451",
    author: "Ray Bradbury",
    genre: "Dystopian",
    mood: ["thought-provoking", "disturbing"],
    link: "https://www.goodreads.com/book/show/13079982-fahrenheit-451",
    description: "A dystopian novel about a future society where books are banned and 'firemen' burn any that are found.",
    howItHelps: "Raises awareness about the importance of intellectual freedom and the dangers of censorship.",
    coverImage: "https://upload.wikimedia.org/wikipedia/en/d/db/Fahrenheit_451_1st_ed_cover.jpg"
  },
  {
    title: "The Shining",
    author: "Stephen King",
    genre: "Horror",
    mood: ["suspenseful", "terrifying"],
    link: "https://www.goodreads.com/book/show/11588.The_Shining",
    description: "A horror novel about a family's terrifying experiences in an isolated, haunted hotel.",
    howItHelps: "Provides a gripping and intense reading experience, exploring themes of isolation and madness.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1353277730i/11588.jpg"
  },
  {
    title: "Little Women",
    author: "Louisa May Alcott",
    genre: "Classic",
    mood: ["heartwarming", "inspirational"],
    link: "https://www.goodreads.com/book/show/6518.Little_Women",
    description: "A coming-of-age story about the lives and adventures of the four March sisters.",
    howItHelps: "Offers a warm and encouraging depiction of family life and personal growth.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4aaGZhVjsnqjoqYaX5gpNb-HlOFXJt2V1bw&s"
  },
  {
    title: "Life of Pi",
    author: "Yann Martel",
    genre: "Adventure",
    mood: ["philosophical", "inspirational"],
    link: "https://www.goodreads.com/book/show/4214.Life_of_Pi",
    description: "A fantastical survival story about a young boy stranded on a lifeboat with a Bengal tiger.",
    howItHelps: "Explores themes of faith, survival, and the power of storytelling.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1631251689i/4214.jpg"
  },
  {
    title: "The Handmaid's Tale",
    author: "Margaret Atwood",
    genre: "Dystopian",
    mood: ["disturbing", "thought-provoking"],
    link: "https://www.goodreads.com/book/show/38447.The_Handmaid_s_Tale",
    description: "A dystopian novel about a future society where women are subjugated and controlled.",
    howItHelps: "Challenges readers to consider issues of power, control, and gender dynamics.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdJtmpw83NXDpAFztfhV__X3onTF1LD6NkwA&s"
  },
  {
    title: "The Color Purple",
    author: "Alice Walker",
    genre: "Fiction",
    mood: ["powerful", "uplifting", "tired"],
    link: "https://www.goodreads.com/book/show/11486.The_Color_Purple",
    description: "A novel about the struggles and triumphs of African American women in the early 20th century South.",
    howItHelps: "Offers a powerful story of resilience and personal empowerment.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6S1aSUv2fpAkPjgtB98_cdN9cI34KPnNIuw&s"
  },
  {
    title: "The Picture of Dorian Gray",
    author: "Oscar Wilde",
    genre: "Classic",
    mood: ["gothic", "philosophical"],
    link: "https://www.goodreads.com/book/show/5297.The_Picture_of_Dorian_Gray",
    description: "A novel about a man who remains eternally young while a portrait of him ages and shows the effects of his moral corruption.",
    howItHelps: "Explores themes of vanity, moral decay, and the consequences of living a life without accountability.",
    coverImage: "https://d28hgpri8am2if.cloudfront.net/book_images/onix/cvr9781625587534/the-picture-of-dorian-gray-9781625587534_hr.jpg"
  },
  {
    title: "The Girl with the Dragon Tattoo",
    author: "Stieg Larsson",
    genre: "Thriller",
    mood: ["suspenseful", "intriguing"],
    link: "https://www.goodreads.com/book/show/2429135-the-girl-with-the-dragon-tattoo",
    description: "A mystery novel about a journalist and a hacker investigating a wealthy family's dark secrets.",
    howItHelps: "Provides a gripping and intricate plot with themes of justice and uncovering hidden truths.",
    coverImage: "https://m.media-amazon.com/images/I/517brh4+tIL._SY780_.jpg"
  },
  {
    title: "Sapiens: A Brief History of Humankind",
    author: "Yuval Noah Harari",
    genre: "Non-Fiction",
    mood: ["insightful", "reflective", "tired"],
    link: "https://www.goodreads.com/book/show/23692271-sapiens",
    description: "A non-fiction book exploring the history and impact of Homo sapiens on the world.",
    howItHelps: "Provides a broad perspective on human history and encourages reflection on our place in the world.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTanE7SEPINNgusbwRTgYmv8fJwSqWN09BwKw&s"
  },
  {
    title: "Dune",
    author: "Frank Herbert",
    genre: "Science Fiction",
    mood: ["epic", "immersive"],
    link: "https://www.goodreads.com/book/show/234225.Dune",
    description: "A science fiction epic set in a desert world with political intrigue, mysticism, and a struggle for control of a valuable resource.",
    howItHelps: "Offers a complex and immersive world with themes of power, religion, and ecology.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSskqZcad-5UkO49-E_nnkYEjQYpdH8DoMKrA&s"
  },
  {
    title: "The Outsiders",
    author: "S.E. Hinton",
    genre: "Young Adult",
    mood: ["emotional", "dramatic"],
    link: "https://www.goodreads.com/book/show/231804.The_Outsiders",
    description: "A novel about teenage gang rivalries and the challenges of growing up in a divided society.",
    howItHelps: "Explores themes of identity, class struggle, and the power of friendship and family.",
    coverImage: "https://m.media-amazon.com/images/I/71Bg39CmhoL._AC_UF1000,1000_QL80_.jpg"
  },
  {
    title: "The Secret Life of Bees",
    author: "Sue Monk Kidd",
    genre: "Fiction",
    mood: ["heartwarming", "inspirational"],
    link: "https://www.goodreads.com/book/show/37435.The_Secret_Life_of_Bees",
    description: "A novel about a young girl's search for her mother and her journey towards self-discovery.",
    howItHelps: "Provides a touching story about family, identity, and the importance of community.",
    coverImage: "https://m.media-amazon.com/images/I/81-sRJzvQJL._AC_UF1000,1000_QL80_.jpg"
  },
  {
    title: "The Poisonwood Bible",
    author: "Barbara Kingsolver",
    genre: "Fiction",
    mood: ["thoughtful", "challenging"],
    link: "https://www.goodreads.com/book/show/5714.The_Poisonwood_Bible",
    description: "A novel about an evangelical missionary family’s time in the Congo and its impact on their lives.",
    howItHelps: "Explores themes of cultural clash, colonialism, and personal redemption.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1644073807i/7244.jpg"
  },
  {
    title: "The Subtle Art of Not Giving a F*ck",
    author: "Mark Manson",
    genre: "Self-Help",
    mood: ["pragmatic", "refreshing"],
    link: "https://www.goodreads.com/book/show/28257707-the-subtle-art-of-not-giving-a-f-k",
    description: "A self-help book that challenges conventional wisdom about happiness and success.",
    howItHelps: "Offers a no-nonsense approach to personal development and finding what truly matters in life.",
    coverImage: "https://buybooks.ng/wp-content/uploads/2020/11/71QKQ9mwV7L.jpg"
  },
  {
    title: "Murder on the Orient Express",
    author: "Agatha Christie",
    genre: "Mystery",
    mood: ["suspenseful", "intriguing"],
    link: "https://www.goodreads.com/book/show/850326.Murder_on_the_Orient_Express",
    description: "A classic mystery novel featuring detective Hercule Poirot solving a murder on a luxury train.",
    howItHelps: "Provides a classic and engaging mystery with intricate plotting and character development.",
    coverImage: "https://i0.wp.com/efsunland.com/wp-content/uploads/2017/01/murder-on-the-orient-express_cover.jpg?fit=969%2C1500&ssl=1"
  },
  {
    title: "The Silmarillion",
    author: "J.R.R. Tolkien",
    genre: "Fantasy",
    mood: ["epic", "mythic"],
    link: "https://www.goodreads.com/book/show/23282012-the-silmarillion",
    description: "A collection of mythopoeic stories that form the foundation of Tolkien's Middle-earth legendarium.",
    howItHelps: "Offers a deep dive into the lore and history of Middle-earth, enhancing appreciation for Tolkien's world.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1610045590i/7332.jpg"
  },
  {
    title: "The Giver",
    author: "Lois Lowry",
    genre: "Dystopian",
    mood: ["thought-provoking", "melancholic"],
    link: "https://www.goodreads.com/book/show/3636.The_Giver",
    description: "A novel about a seemingly perfect society where one boy is chosen to receive the memories of the past.",
    howItHelps: "Encourages reflection on individuality, freedom, and the value of emotions in society.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWSpyimLdYpq-4JTTcBr_DsGkI11gS9JRYwQ&s"
  },
  {
    title: "The Martian",
    author: "Andy Weir",
    genre: "Science Fiction",
    mood: ["suspenseful", "humorous"],
    link: "https://www.goodreads.com/book/show/18007564-the-martian",
    description: "A science fiction novel about an astronaut stranded on Mars and his struggle to survive.",
    howItHelps: "Combines scientific realism with humor and resilience, offering an inspiring survival story.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1413706054i/18007564.jpg"
  },
  {
    title: "The Kite Runner",
    author: "Khaled Hosseini",
    genre: "Fiction",
    mood: ["emotional", "reflective"],
    link: "https://www.goodreads.com/book/show/77203.The_Kite_Runner",
    description: "A novel about a boy's troubled relationship with his friend and the impact of their actions on their lives.",
    howItHelps: "Explores themes of redemption, forgiveness, and the enduring power of friendship.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1579036753i/77203.jpg"
  },
  {
    title: "One Hundred Years of Solitude",
    author: "Gabriel García Márquez",
    genre: "Magical Realism",
    mood: ["enigmatic", "lyrical"],
    link: "https://www.goodreads.com/book/show/3202.One_Hundred_Years_of_Solitude",
    description: "A multi-generational tale of the Buendía family in the fictional town of Macondo.",
    howItHelps: "Blends reality with fantasy to explore themes of time, family, and the human condition.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1354829590i/7597.jpg"
  },
  {
    title: "The Chronicles of Narnia",
    author: "C.S. Lewis",
    genre: "Fantasy",
    mood: ["adventurous", "magical"],
    link: "https://www.goodreads.com/book/show/11127.The_Chronicles_of_Narnia",
    description: "A series of seven fantasy novels set in the magical land of Narnia.",
    howItHelps: "Offers a rich and imaginative world with themes of courage, faith, and adventure.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8vg_DqcuniVrH3UxVhvSIT7EJ4OiVBvBLjA&s"
  },
  {
    title: "The Sun Also Rises",
    author: "Ernest Hemingway",
    genre: "Classic",
    mood: ["nostalgic", "disillusioned"],
    link: "https://www.goodreads.com/book/show/8512.The_Sun_Also_Rises",
    description: "A novel about a group of expatriates in post-World War I Europe searching for meaning and fulfillment.",
    howItHelps: "Explores themes of disillusionment and the search for purpose in a changing world.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiSAxGRjFLigqJEKltn7LIniJJ1Cm_5Z3Ucw&s"
  },
  {
    title: "The Da Vinci Code",
    author: "Dan Brown",
    genre: "Thriller",
    mood: ["exciting", "mystical"],
    link: "https://www.goodreads.com/book/show/968.The_Da_Vinci_Code",
    description: "A thriller that follows a symbologist and a cryptologist as they investigate a murder with deep historical implications.",
    howItHelps: "Provides a fast-paced and intriguing mystery with a blend of history, art, and religion.",
    coverImage: "https://m.media-amazon.com/images/I/811nqCf7o1L._SL1500_.jpg"
  },
  {
    title: "Educated",
    author: "Tara Westover",
    genre: "Memoir",
    mood: ["inspiring", "heart-wrenching"],
    link: "https://www.goodreads.com/book/show/35133922-educated",
    description: "A memoir about a woman who grows up in a strict and abusive household in rural Idaho and eventually escapes to learn about the wider world.",
    howItHelps: "Provides a powerful story of self-education and overcoming adversity.",
    coverImage: "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1506026635i/35133922.jpg"
  },
  {
    title: "Gone Girl",
    author: "Gillian Flynn",
    genre: "Thriller",
    mood: ["suspenseful", "twisted"],
    link: "https://www.goodreads.com/book/show/19288043-gone-girl",
    description: "A thriller about a man whose wife goes missing, revealing dark secrets about their marriage.",
    howItHelps: "Offers a gripping and complex narrative with unexpected twists and psychological depth.",
    coverImage: "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1554086139l/19288043.jpg"
  },
  {
    title: "A Man Called Ove",
    author: "Fredrik Backman",
    genre: "Fiction",
    mood: ["heartwarming", "humorous"],
    link: "https://www.goodreads.com/book/show/34809113-a-man-called-ove",
    description: "A story about a grumpy yet lovable man whose life changes when new neighbors move in.",
    howItHelps: "Provides a touching and humorous exploration of human connection and the impact of kindness.",
    coverImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNfqb_NIhpQUOpSC1CyawJ3-nCUjgT6I_zWA&s"
  },
];

const moodSuggestions = {
  "sad": ["uplifted", "hopeful", "comforted", "inspired", "heartwarming"],
  "anxious": ["calm", "peaceful", "mindful"],
  "stressed": ["relaxed", "calm", "focused"],
  "unmotivated": ["inspired", "motivated", "disciplined", "tired"],
  "lonely": ["understood", "connected", "comforted"],
  "angry": ["calm", "understanding", "peaceful"],
  "confused": ["clear", "focused", "wise"],
  "overwhelmed": ["calm", "organized", "focused"],
  "insecure": ["confident", "empowered", "accepting"],
  "bored": ["curious", "inspired", "motivated"],
  "depressed": ["hopeful", "uplifted", "comforted"],
  "thought-provoking": ["reflective", "insightful", "philosophical", "melancholic"],
  "empathetic": ["understood", "connected", "heartwarming"],
  "dark": ["suspenseful", "disturbing", "terrifying"],
  "cautionary": ["critical", "thought-provoking"],
  "romantic": ["heartwarming", "inspirational"],
  "satirical": ["humorous", "critical"],
  "reflective": ["introspective", "thoughtful"],
  "alienated": ["isolated", "lonely"],
  "tragic": ["heartbreaking", "somber"],
  "opulent": ["grand", "luxurious"],
  "adventurous": ["whimsical", "exciting"],
  "epic": ["immersive", "mythic"],
  "philosophical": ["reflective", "introspective"],
  "bleak": ["despairing", "somber"],
  "hopeful": ["uplifted", "inspiring"],
  "passionate": ["intense", "emotional"],
  "resilient": ["empowered", "determined", "tired"],
  "dark": ["introspective", "bleak"],
  "introspective": ["reflective", "thoughtful"],
  "humorous": ["quirky", "light-hearted"],
  "quirky": ["humorous", "offbeat"],
  "suspenseful": ["thrilling", "gripping"],
  "intriguing": ["fascinating", "mysterious"],
  "dramatic": ["emotional", "intense"],
  "heartwarming": ["inspiring", "uplifting"],
  "inspirational": ["motivating", "encouraging"],
  "pragmatic": ["realistic", "refreshing"],
  "refreshing": ["novel", "uplifting"],
  "exciting": ["thrilling", "adventurous"],
  "mystical": ["enchanting", "captivating"],
  "enigmatic": ["mysterious", "captivating"],
  "lyrical": ["poetic", "elegant"],
  "nostalgic": ["reflective", "sentimental"],
  "disillusioned": ["cynical", "skeptical"],
  "empowered": ["confident", "motivated"],
  "twisted": ["dark", "intriguing"],
  "heart-wrenching": ["emotional", "powerful"],
};


const BookRecommender = () => {
  const [mood, setMood] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const toggleSideNav = () => setIsSideNavOpen(!isSideNavOpen);

  const getRecommendations = () => {
    const lowerMood = mood.toLowerCase().trim();
    const targetMoods = moodSuggestions[lowerMood] || [lowerMood];
    
    const recommendedBooks = bookList.filter(book => 
      book.mood.some(bookMood => targetMoods.includes(bookMood))
    );
  
    if (recommendedBooks.length === 0) {
      setRecommendations([{
        isEmpty: true // Add a flag for empty result
      }]);
    } else {
      setRecommendations(recommendedBooks);
    }
  };
  

  const handleSearch = (e) => {
    e.preventDefault();
    if (mood.trim()) {
      getRecommendations();
    }
  };


  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className={"`  transform  ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'}  w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav `"}>
        <div className="p-6">
          <nav>
            <ul className="space-y-2">
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
              </button></li>
            </ul>
          </nav>
        </div>
        
      </div>

      {/* Main content */}
      <main className="flex-1 p-6 overflow-hidden container mx-auto px-4 w-[900px] overflow-y-auto md:h-[560px]">
        <div className="max-w-4xl mx-auto">
          <header className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold">Mood-Based Book Recommender</h1>
            
          </header>

          <form onSubmit={handleSearch} className="mb-8">
            <div className="flex gap-2">
              <input
                type="text"
                value={mood}
                onChange={(e) => setMood(e.target.value)}
                placeholder="How are you feeling?"
                className="flex-1 md:px-4 md:py-2 px-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
              />
              <button type="submit" className="px-4 py-2 bg-[#59844F] text-white rounded-md hover:bg-[#59844F] focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:ring-offset-2">
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                Get Recommendations
              </button>
            </div>
          </form>

                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-1">
              {recommendations.length > 0 && recommendations.map((book) => (
                !book.isEmpty ? (
            <div key={book.title} className="bg-white p-6 rounded-lg shadow-md md:flex">
              <img src={book.coverImage} alt={`Cover of ${book.title}`} className="md:w-32 md:h-48 rounded-md mr-6 flex justify-center" />
              <div>
                <h3 className="md:text-xl font-semibold my-2 text-[10px]">{book.title}</h3>
                <p className="text-gray-600 mb-2">{book.author}</p>
                <p className="text-sm text-gray-500 mb-1">Genre: {book.genre}</p>
                <p className="text-sm text-gray-500 mb-3">Mood: {book.mood.join(', ')}</p>
                <p className="text-gray-700 mb-3">{book.description}</p>
                <p className="text-gray-700 mb-4"><strong>How it helps:</strong> {book.howItHelps}</p>
                <div className="mt-4">
            <a href={book.link} target="_blank" rel="noopener noreferrer" className="text-[#59844F] hover:underline">
              <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
              View on Goodreads
            </a>
          </div>
        </div>
      </div>
    ) : (
      <div className='flex justify-center text-center col-span-full'>
        <div className="text-center py-8 ">
          <p className="text-xl text-gray-600 mb-4 text-center">We couldn't find books for that mood.</p>
          <p className="text-lg text-gray-500">
            Help us improve! <Link to="/contact" className="text-[#59844F] hover:underline">Contact us</Link> to suggest books for this mood.
          </p>
        </div>
      </div>
    )
  ))}
</div>

        </div>
      </main>
    </div>
  );
};

export default BookRecommender;