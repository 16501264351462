import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTimes, faSmile, faMeh, faFrown, faBed, faBolt, faMoon, 
  faUtensils, faHeartbeat, faBrain, faSpinner, faCheckCircle    
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../components/AuthContext ';

const MoodAssessment = ({ isOpen, onClose }) => {

  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [responses, setResponses] = useState({
    happiness: 5,
    anxiety: '',
    energy: '',
    sleepQuality: 5,
    appetite: 5,
    physicalHealth: 5,
    concentration: 5,
    socialConnections: 5,
    stressLevel: 5
  });

  const handleChange = (name, value) => {
    setResponses((prev) => ({ ...prev, [name]: value }));
  };

  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');
  const { token } = useAuth();

  const handleSubmit = async () => {    
    setIsLoading(true);
    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/mood-assessment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          happiness: responses.happiness,
          anxiety: responses.anxiety,
          energy: responses.energy,
          sleep_quality: responses.sleepQuality,
          appetite: responses.appetite,
          physical_health: responses.physicalHealth,
          concentration: responses.concentration,
          social_connections: responses.socialConnections,
          stress_level: responses.stressLevel,
        }),
      });
  
      if (response.ok) {
        console.log('Mood assessment saved successfully');
        setShowWelcome(true);
        setTimeout(() => {
          setShowWelcome(false);
          onClose();
        }, 2000); // Close after 3 seconds
      } else {
        console.error('Failed to save mood assessment');
      }
    } catch (error) {
      console.error('Error submitting mood assessment:', error);
    }
    setIsLoading(false);
  };


  const isStepValid = () => {
    const currentStepFields = {
      1: ['happiness', 'anxiety', 'energy'],
      2: ['sleepQuality', 'appetite', 'physicalHealth'],
      3: ['concentration', 'socialConnections', 'stressLevel']
    }[step];

    return currentStepFields.every(field => responses[field] !== '');
  };

  const nextStep = () => {
    if (step < 3) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  if (!isOpen) return null;

  const getSliderColor = (value) => {
    if (value <= 3) return 'bg-gradient-to-r from-red-500 to-red-300';
    if (value <= 7) return 'bg-gradient-to-r from-blue-500 to-blue-300';
    return 'bg-gradient-to-r from-green-500 to-green-300';
  };

  const renderQuestion = (label, name, icon, type = 'slider') => (
    <div className="space-y-2">
      <label className="text-sm font-medium">{label}</label>
      {type === 'slider' ? (
        <div className="relative pt-1">
          <input
            type="range"
            min="1"
            max="10"
            value={responses[name]}
            onChange={(e) => handleChange(name, parseInt(e.target.value))}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            style={{
              background: `linear-gradient(to right, 
                #EF4444 0%, #EF4444 30%, 
                #3B82F6 30%, #3B82F6 70%, 
                #10B981 70%, #10B981 100%)`
            }}
          />
          <div className="flex justify-between mt-2">
            <span className="text-xs text-gray-600">1</span>
            <span className="text-xs font-bold text-gray-700">{responses[name]}</span>
            <span className="text-xs text-gray-600">10</span>
          </div>
        </div>
      ) : (
        <select 
          value={responses[name]} 
          onChange={(e) => handleChange(name, e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">Select option</option>
          <option value="Never">Never</option>
          <option value="Sometimes">Sometimes</option>
          <option value="Often">Often</option>
          <option value="Always">Always</option>
        </select>
      )}
    </div>
  );

  if (showWelcome) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
          <div className="text-center">
            <FontAwesomeIcon icon={faCheckCircle} className="text-6xl text-green-500 mb-4" />
            <h2 className="text-2xl font-bold mb-2">Thank You!</h2>
            <p className="text-gray-600">Your mood assessment has been recorded.</p>
            <p className="text-gray-600 mt-2">We appreciate your input and hope you have a great day!</p>
          </div>
        </div>
      </div>
    );
  }

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-bold text-center mb-4">SereniMind Assessment</h2>
        <p className="text-center text-gray-600 mb-6">Step {step} of 3: Let's understand your well-being</p>
        
        <form className="space-y-6">
          {step === 1 && (
            <>
              {renderQuestion("How happy do you feel today?", "happiness", faSmile)}
              {renderQuestion("How often have you felt anxious this past week?", "anxiety", faBrain, "select")}
              <div className="space-y-2">
                <label className="text-sm font-medium">How do you feel right now?</label>
                <div className="flex justify-around">
                  <button
                    type="button"
                    onClick={() => handleChange('energy', 'Energized')}
                    className={`flex flex-col items-center p-2 rounded-md ${responses.energy === 'Energized' ? 'bg-green-100' : ''}`}
                  >
                    <FontAwesomeIcon icon={faBolt} className="text-2xl text-yellow-500 mb-1" />
                    <span>Energized</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChange('energy', 'Fatigued')}
                    className={`flex flex-col items-center p-2 rounded-md ${responses.energy === 'Fatigued' ? 'bg-blue-100' : ''}`}
                  >
                    <FontAwesomeIcon icon={faMoon} className="text-2xl text-blue-500 mb-1" />
                    <span>Fatigued</span>
                  </button>
                </div>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              {renderQuestion("Rate your sleep quality over the last few days", "sleepQuality", faBed)}
              {renderQuestion("How would you rate your appetite lately?", "appetite", faUtensils)}
              {renderQuestion("How would you rate your overall physical health?", "physicalHealth", faHeartbeat)}
            </>
          )}

          {step === 3 && (
            <>
              {renderQuestion("How well can you concentrate on tasks?", "concentration", faBrain)}
              {renderQuestion("How satisfied are you with your social connections?", "socialConnections", faSmile)}
              {renderQuestion("How would you rate your current stress level?", "stressLevel", faBrain)}
            </>
          )}

          <div className="flex justify-between">
            {step > 1 && (
              <button
                type="button"
                onClick={prevStep}
                className="py-2 px-4 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
              >
                Previous
              </button>
            )}
            {step < 3 ? (
              <button
                type="button"
                onClick={nextStep}
                disabled={!isStepValid()}
                className={`py-2 px-4 rounded-md text-white font-medium ${
                  isStepValid() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
                }`}
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                onClick={handleSubmit}
                disabled={!isStepValid() || isLoading}
                className={`py-2 px-4 rounded-md text-white font-medium ${
                  isStepValid() && !isLoading ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-300 cursor-not-allowed'
                }`}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Submit'
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MoodAssessment;