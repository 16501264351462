import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faCog, faSave, faArrowRight, faGear, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axiosInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userSettings, setUserSettings] = useState({
    emailNotifications: true,
    pushNotifications: true,
    smsNotifications: false,
    profileVisibility: '',
    searchVisibility: '',
    currentTheme: '',
  });

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  const toggleActivities = () => {
    setIsActivitiesOpen(!isActivitiesOpen);
  };

  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchUserSettings = async () => {
    try {
      const response = await axiosInstance.get('https://serenimindbackend.onrender.com/api/settings/');
      setUserSettings(response.data);
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const handleSaveSettings = async () => {
    try {
      await axiosInstance.post('https://serenimindbackend.onrender.com/api/settings/', userSettings);
      console.log('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving user settings:', error);
    }
  };

  const toggleEmailNotifications = () => {
    setUserSettings({ ...userSettings, emailNotifications: !userSettings.emailNotifications });
  };

  const togglePushNotifications = () => {
    setUserSettings({ ...userSettings, pushNotifications: !userSettings.pushNotifications });
  };

  const toggleSmsNotifications = () => {
    setUserSettings({ ...userSettings, smsNotifications: !userSettings.smsNotifications });
  };

  const toggleProfileVisibility = () => {
    setUserSettings({ ...userSettings, profileVisibility: userSettings.profileVisibility === 'public' ? 'private' : '' });
  };

  const toggleSearchVisibility = () => {
    setUserSettings({ ...userSettings, searchVisibility: userSettings.searchVisibility === 'public' ? 'private' : '' });
  };

  const toggleTheme = () => {
    setUserSettings({ ...userSettings, currentTheme: userSettings.currentTheme === 'light' ? 'dark' : '' });
  };

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      await axiosInstance.delete('https://serenimindbackend.onrender.com/api/delete-account/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Assuming you store the token in localStorage
        },
      });
      console.log('Account deleted successfully!');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      setLoading(false);
      navigate('/signup');
      window.location.reload();
      // Handle success: Redirect user or show confirmation message
    } catch (error) {
      console.error('Error deleting account:', error);
      setLoading(false);
      // Handle error: Display error message to the user
    }
  };

  return (
    <div className="flex">
      {/* Side Navigation Bar */}
      

      <div className="px-10 pt-6 shadow-xl smallscreensidenav overflow-y-auto md:h-[560px]">
        <ul className="text-lg space-y-11">
          <li><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></li>
          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
          <li className='relative'>
            <div className="flex items-center font-semibold cursor-pointer" onClick={toggleActivities}>
              <span className="mr-1">🎮</span>
              <span>Activity Lounge</span>
              <FontAwesomeIcon icon={faArrowRight} size="sm" className={`ml-1 text-gray-400 ${isActivitiesOpen ? 'transform rotate-90' : ''}`} />
            </div>
            {isActivitiesOpen && (
              <ul className="space-y-4">
                <li><Link to="/exercises" className="flex items-center pt-3 pl-6 font-semibold">🏋️‍♂️ Exercises</Link></li>
                <li><Link to="/games" className="flex items-center pl-6 font-semibold">🎮 Games</Link></li>
                <li><Link to="/movies" className="flex items-center pl-6 font-semibold">🎬 Movies</Link></li>
                <li><Link to="/sounds" className="flex items-center pl-6 font-semibold">🔊 Sounds</Link></li>
                <li><Link to="/books" className="flex items-center pl-6 font-semibold">📚 Books</Link></li>
              </ul>
            )}
          </li>
          <li><Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link></li>
          <li><Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link></li>
          <li><Link to="/community" className="flex items-center font-semibold">👥 Community</Link></li>
          <li className='pt-8 pb-4'>
            <Link to="/settings" className="flex items-center">
              <FontAwesomeIcon icon={faGear} size="2x" />
            </Link>
          </li>
        </ul>
      </div>

      <div className="container mx-auto mt-8 w-[70%]">
        <h1 className="mb-4 text-3xl font-bold">Settings</h1>

        {/* Profile Settings */}
        <div className="p-4 mb-4 border border-gray-300 rounded-md">
          <h2 className="mb-2 text-xl font-semibold">Notification Settings</h2>
          <div className="flex items-center mb-2">
            <p>Email Notifications:</p>
            <button onClick={toggleEmailNotifications} className="ml-2">
              <FontAwesomeIcon icon={userSettings.emailNotifications ? faToggleOn : faToggleOff} />
            </button>
          </div>
          <div className="flex items-center mb-2">
            <p>Push Notifications:</p>
            <button onClick={togglePushNotifications} className="ml-2">
              <FontAwesomeIcon icon={userSettings.pushNotifications ? faToggleOn : faToggleOff} />
            </button>
          </div>
          <div className="flex items-center mb-2">
            <p>SMS Notifications:</p>
            <button onClick={toggleSmsNotifications} className="ml-2">
              <FontAwesomeIcon icon={userSettings.smsNotifications ? faToggleOn : faToggleOff} />
            </button>
          </div>
        </div>

        <div className="p-4 mb-4 border border-gray-300 rounded-md">
          <h2 className="mb-2 text-xl font-semibold">Privacy Settings</h2>
          <div className="flex items-center mb-2">
            <p>Profile Visibility:</p>
            <button onClick={toggleProfileVisibility} className="ml-2">
              <FontAwesomeIcon icon={userSettings.profileVisibility === 'public' ? faToggleOn : faToggleOff} />
            </button>
          </div>
          <div className="flex items-center mb-2">
            <p>Search Visibility:</p>
            <button onClick={toggleSearchVisibility} className="ml-2">
              <FontAwesomeIcon icon={userSettings.searchVisibility === 'public' ? faToggleOn : faToggleOff} />
            </button>
          </div>
        </div>

        <div className="p-4 mb-4 border border-gray-300 rounded-md">
          <h2 className="mb-2 text-xl font-semibold">Theme Settings</h2>
          <div className="flex items-center mb-2">
            <p>Current Theme:</p>
            <button onClick={toggleTheme} className="ml-2">
              <FontAwesomeIcon icon={faCog} />
            </button>
            <span className="ml-2">{userSettings.currentTheme}</span>
          </div>
        </div>

        {/* Save and Delete Buttons */}
        <div className="flex justify-end">
          <button onClick={handleSaveSettings} className="px-4 py-2 text-white bg-green-600 rounded-md">
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save Settings
          </button>
          <button onClick={handleDeleteAccount} className="px-4 py-2 ml-2 text-white bg-red-600 rounded-md">
            {loading ? 'Deleting...' : 'Delete Account'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
