import React from 'react'

const FooterLogged = () => {
  return (
    <div>
        <div className="text-center text-gray-500 md:mt-[0px] mt-4">
            <h1>Copyright © 2024 Timingotech. All rights reserved.</h1>
        </div>
    </div>
  )
}

export default FooterLogged