import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Healthcareai from '../images/Healthcare-ai.jpg';
import Healthcareai2 from '../images/Healthcare-ai2.jpg';
import Community from '../images/Community.webp';
import Puzzle from '../images/Puzzle.png';
import Addidas from '../images/Addidas.png'
import Nike from '../images/Nike.png'
import Microsoft from '../images/Microsoft.jpeg'
import Ibm from '../images/Ibm.png'
import Google from '../images/Google.png'
import Ngo from '../images/Ngo.png'
import Nestle from '../images/Nestle.png'
import Hp from '../images/Hp.png'
import Mentalhealth from '../images/Mentalhealth1234.png'
import Home1 from '../images/home1.png'
import Home2 from '../images/Home2.png'
import AIhome from '../images/AIPic.webp'

const Home = () => { 
  const [currentSlide, setCurrentSlide] = useState(0);
  const [issues, setIssues] = useState(['Mind']);
  const [currentIssueIndex, setCurrentIssueIndex] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [images, setImages] = useState([ Mentalhealth]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sectionRef = useRef(null);
  const thirdSectionRef = useRef(null);
  const [openIndex, setOpenIndex] = useState(6);

  const Step = ({ number, title, description }) => (
    <div className="flex items-start mb-4">
      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-green-700 text-white flex items-center justify-center mr-3">
        {number}
      </div>
      <div>
        <h3 className="font-semibold text-lg">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );

  const testimonials = [
    [
      {
        name: 'Diadem',
        rating: 4,
        text: '"I was skeptical at first, but the AI chatbot is surprisingly helpful. It offers practical advice and exercises that really make a difference."'
      },
      {
        name: 'Afolabi',
        rating: 5,
        text: '"Having access to licensed therapists through the app has been a lifesaver. The professionals are top-notch, and the booking process is seamless."'
      },
      {
        name: 'Yetunde O.',
        rating: 5,
        text: '"Serenimind offers great personalized guidance. Whenever I feel overwhelmed, I just chat with the AI, and it helps me calm down and find solutions."'
      }
    ],
    [
      {
        name: 'Joel',
        rating: 5,
        text: '"Serenimind is incredible! It\'s like having a personal therapist available 24/7. The mindfulness exercises have been a game changer for me."'
      },
      {
        name: 'Isaac',
        rating: 5,
        text: '"The variety of resources available on the app is impressive. From articles to guided meditations, there\'s something for everyone. It\'s helped me improve my mental wellness significantly."'
      },
      {
        name: 'Semilore',
        rating: 5,
        text: '"Booking an appointment with a certified therapist was so easy and convenient. The virtual sessions are perfect for my busy schedule."'
      }
    ]
  ];

  const TestimonialCard = ({ name, rating, text }) => (
    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
      <div className="flex items-center mb-2">
        {[...Array(5)].map((_, i) => (
          <svg
            key={i}
            className={`w-5 h-5 ${i < rating ? 'text-[#59844F]' : 'text-gray-300'}`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        ))}
      </div>
      <p className="text-gray-600 mb-4">{text}</p>
      <p className="font-semibold text-gray-800">{name}</p>
    </div>
  );

  const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIssueIndex((prevIndex) => (prevIndex + 1) % issues.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [issues]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images]);

  const formMessage = useRef();
  const formNewsletter = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmittingMessage(true);
    const formData = new FormData(formMessage.current);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/send-email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      if (response.ok) {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          window.location.reload();
        }, 3000);
      } else {
        alert('Failed to send email. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send email. Please try again later.');
    } finally {
      setIsSubmittingMessage(false);
    }
  };

  const subscribeNewsletter = async (e) => {
    e.preventDefault();
    setIsSubscribing(true);
    const formData = new FormData(formNewsletter.current);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/subscribe-newsletter/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        window.location.reload();
      } else {
        alert('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to subscribe. Please try again later.');
    } finally {
      setIsSubscribing(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      },
      {
        threshold: 0.2
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible-pop-down');
          observer.unobserve(entry.target); // Optional: Stop observing after it becomes visible
        }
      },
      {
        threshold: 0.1
      }
    );

    if (thirdSectionRef.current) {
      observer.observe(thirdSectionRef.current);
    }

    return () => {
      if (thirdSectionRef.current) {
        observer.unobserve(thirdSectionRef.current);
      }
    };
  }, []);

  return (
    <div>
        {/* First Page in Home */}
        <div className="landing bg-[] md:pb-[100px] flex justify-center px-4">
  <div className="landing-left md:pt-[60px] pt-[10px] imagehome slide-left text-center flex flex-col items-center ">
    <h1 className="mb-8 text-2xl font-bold md:text-4xl">
      Welcome to Sereni
      <span className="text-[#59844F] animate-bounce">
        {issues[currentIssueIndex]}
      </span>:<br></br>
      <span className="mb-8 text-2xl font-bold md:text-4xl">Your Path to Mental Wellness</span>
    </h1>
    <p className="text-[#8E8E93] md:px-[450px] text-lg">
      Empowering Your Journey with AI Support, Private Journaling, Professional Guidance, and a Supportive Community
    </p>
    <div className="md:space-x-[50px] space-x-[5px]">
    <Link to="/signup">
      <button className="bg-[#59844F] text-white mt-8 px-8 py-2 rounded-lg font-bold">
        Get Started
        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
      </button> </Link>
      <Link to="/book-professional">
      <button className="bg-[] text-[#59844F] border-[#59844F] border-2 mt-8 px-7 py-[6px] rounded-lg font-bold">
        Book an appointment
      </button>
      </Link>
    </div>
    <img src={Home1} className="w-[700px] mx-auto mt-8"></img>
  </div>
      </div>

      {/* Second Page in Home */}
      <section ref={sectionRef} className="support-section bg-[#E5E5EA] md:py-20 py-8 md:flex justify-center items-center md:gap-8 px-4">
  <div className='md:max-w-lg'>
    <h2 className='md:text-3xl font-bold mb-6 text-2xl'>Experience Instant Support with <span className='text-[#59844F]'>SereniAI</span></h2>
    <p className='mb-8'>Your confidential virtual assistant, SereniAI offers instant support, answers mental health questions, and guides you through mindfulness exercises for a balanced, fulfilling life.</p>
    <Link to="/signup" className="inline-flex items-center bg-[#59844F] text-white px-8 py-3 rounded-lg font-bold hover:bg-[#002500] transition-colors">
      Get Started
      <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
    </Link>
  </div>
  <div className='md:mt-0 mt-8 smallsize'>
    <img src={AIhome} alt="SereniAI Assistant" className='w-[300px] h-[300px] object-cover rounded-lg'/>
  </div>
      </section>
      
      {/* Third page in Home */}
      <div className="max-w-6xl mx-auto px-4 py-12 flex flex-col md:flex-row">
      <div className="md:w-1/2 pr-8">
        <h2 className="md:text-3xl font-bold mb-6 text-2xl">Follow our 4 easy steps</h2>
        <Step
          number={1}
          title="Sign In or Create an Account"
          description="Log in to your existing SereniMind account or create a new one if you haven't registered yet."
        />
        <Step
          number={2}
          title="Navigate to the Appointments Section"
          description='Once logged in, go to the "Appointments" section located in the "Features" dashboard.'
        />
        <Step
          number={3}
          title="Choose a Therapist"
          description="Browse through our list of certified therapists, counselors, and psychologists."
        />
        <Step
          number={4}
          title="Schedule Your Appointment"
          description="Select your preferred therapist, choose a date and time that works for you, and confirm your appointment based on your convenience."
        />
      </div>
      <div className="md:w-1/2 mt-8 md:mt-0">
        <h2 className="md:text-3xl font-bold mb-4 text-2xl">Book an Appointment with a Certified Therapist</h2>
        <p className="text-gray-600 mb-6">
          Get professional support from our network of licensed therapists, counselors, and psychologists. Schedule virtual or in-person sessions at your convenience to receive personalized care tailored to your mental health needs.
        </p>
        <Link to="/book-professional">
        <button className="bg-white text-[#59844F] border-2 border-[#59844F] font-semibold py-2 px-4 rounded hover:bg-[#59844F] hover:text-white transition duration-300">
        Book an appointment
        </button></Link>
      </div>
    </div>

        {/* Fourth page in home */}
      <div ref={thirdSectionRef} className="third-section mt-[10px]">
        <h1 className='md:text-3xl font-bold text-center text-2xl'>Frequently Asked Questions</h1>
        <div className="landing pb-[10px] md:px-12 mx-4">
          <p className="text-center text-gray-600 mb-8">Everything you need to know about SereniMind and more</p>
          <div className="max-w-2xl mx-auto">
            {[
              { question: "What is mind wellness?", 
                answer: "Mind wellness focuses on maintaining mental and emotional health through practices that promote cognitive functioning, emotional stability, and overall psychological well-being." },
              { question: "What can the SereniAI help me with?", 
                answer: "The SereniAI can provide immediate support, answer questions related to mental health, offer stress-relief techniques, and guide you through mindfulness exercises." },
              { question: "Is the SereniAI confidential?", 
                answer: "Yes, interactions with the AI chatbot are confidential and designed to protect your privacy." },
              { question: "What is the purpose of the private journal?", 
                answer: "The private journal allows you to document your thoughts, feelings, and experiences, which can help you reflect on your mental wellness journey and track your progress over time." },
              { question: "Are virtual appointments available?", 
                answer: "Yes, we offer virtual appointments to provide you with convenient access to mental health support from the comfort of your home." },
              { question: "How can I join the community forum?", 
                answer: "You can join the community forum by creating an account on our website. Once registered, you can participate in discussions, post questions, and connect with other members." },
              {
                question: "What should I do if I'm in a mental health crisis?",
                answer: "If you are in a mental health crisis, please seek immediate help from a professional. Contact emergency services, visit the nearest hospital, or reach out to a crisis hotline."
              }
            ].map((item, index) => (
              <div key={index} className="border-b border-gray-200 py-4">
                <button
                  className="flex justify-between items-center w-full text-left"
                  onClick={() => setOpenIndex(openIndex === index ? -1 : index)}
                >
                  <span className="text-lg font-medium">{item.question}</span>
                  <span className="text-2xl">{openIndex === index ? '−' : '+'}</span>
                </button>
                {openIndex === index && <p className="mt-2 text-gray-600">{item.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </div>

    {/* Fifth Page in Home */}
      <div className="max-w-6xl mx-auto px-4 py-5">
            <h2 className="md:text-3xl font-bold text-center mb-2 text-2xl">Don't just take our word for it</h2>
            <p className="text-gray-600 text-center mb-8">Look at what our users have to say about our services</p>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {testimonials[currentSlide].map((testimonial, index) => (
                <TestimonialCard key={index} {...testimonial} />
              ))}
            </div>
            
            <div className="flex justify-center mt-8">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-3 h-3 rounded-full mx-1 ${
                    currentSlide === index ? 'bg-[#59844F]' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
      </div>

      {/* Sixth Page in Home */}
      <div className="landing bg-[] md:pb-[100px] flex justify-center bg-[#e7e7e9] py-5 px-4">
        <div className="landing-left md:pt-[60px] pt-[10px] imagehome slide-left text-center flex flex-col items-center">
          <h1 className="mb-5 text-2xl font-bold md:text-4xl">
            Begin Your Wellness Journey Today
          </h1>
          <p className="text-[#8E8E93] md:w-[450px] text-lg">
          Take the first step towards a healthier mind and a happier life with our comprehensive wellness tools and support.
          </p>
          <div className="md:space-x-[50px] space-x-[5px]">
          <Link to="/signup">
      <button className="bg-[#59844F] text-white mt-8 px-8 py-2 rounded-lg font-bold">
        Get Started
        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
      </button></Link>
      <Link to="/book-professional">
      <button className="bg-[] text-[#59844F] border-[#59844F] border-2 mt-8 px-7 py-[6px] rounded-lg font-bold">
        Book an appointment
      </button></Link>
    </div>
        </div>
      </div>

    <a href="https://wa.me/2347078634362?text=Hello%2C%20I%20have%20a%20question%20concerning%20your%20service." className="fixed bottom-6 right-6 bg-[#25D366] text-white p-4 rounded-full shadow-lg hover:bg-[#128C7E] transition-colors duration-300">
      <FontAwesomeIcon icon={faWhatsapp} className="text-3xl" />
    </a>
    </div>
  )
}

export default Home;
