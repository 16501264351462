import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle, AlertTriangle } from 'lucide-react';

const Status = () => {
  const [services, setServices] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating API call to fetch status data
    const fetchStatus = async () => {
      setLoading(true);
      try {
        // Replace this with actual API call when available
        await new Promise(resolve => setTimeout(resolve, 1000));
        setServices([
          { name: 'Website', status: 'operational' },
          { name: 'API', status: 'operational' },
          { name: 'Database', status: 'operational' },
          { name: 'Authentication', status: 'operational' },
          { name: 'SereniAI', status: 'operational' },
        ]);
        setIncidents([
          {
            id: 1,
            title: 'SereniAI',
            description: 'We are experiencing issues with SereniAI. Our team is investigating.',
            status: 'investigating',
            updatedAt: '2024-09-20T10:30:00Z'
          }
        ]);
      } catch (error) {
        console.error('Error fetching status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'operational':
        return <CheckCircle className="text-green-500" />;
      case 'degraded':
        return <AlertTriangle className="text-yellow-500" />;
      case 'down':
        return <XCircle className="text-red-500" />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">SereniMind System Status</h1>
      
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Services</h2>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <ul className="divide-y divide-gray-200">
            {services.map((service) => (
              <li key={service.name} className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">{service.name}</p>
                  <div className="flex items-center">
                    {getStatusIcon(service.status)}
                    <span className="ml-2 text-sm text-gray-500 capitalize">{service.status}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div>
        <h2 className="text-2xl font-semibold mb-4">Active Incidents</h2>
        {incidents.length === 0 ? (
          <p className="text-green-600 font-semibold">No active incidents</p>
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            {incidents.map((incident) => (
              <div key={incident.id} className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{incident.title}</h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>{incident.description}</p>
                </div>
                <div className="mt-3 text-sm">
                  <span className="font-semibold">Status: </span>
                  <span className="capitalize">{incident.status}</span>
                </div>
                <div className="mt-1 text-sm text-gray-500 ">
                <span>Last updated: 9/22/2024, 12:20:28 AM</span> <br/>
                  <span>Last updated: {new Date(incident.updatedAt).toLocaleString()}</span> 
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Status;