import React ,{useState} from 'react';
import { Dumbbell, Gamepad2, Clapperboard, Volume2, BookOpen } from 'lucide-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faShare, faEdit, faGear, faArrowRight, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

const ActivityLounge = () => {

    const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
    const [isSideNavOpen, setIsSideNavOpen] = useState(false);
    const toggleSideNav = () => {
        setIsSideNavOpen(!isSideNavOpen);
      };
    const toggleActivities = () => {
      setIsActivitiesOpen(!isActivitiesOpen);
    };
  const activities = [
    {
      title: 'EXERCISES',
      description: 'The exercise feature will provide guided workouts, personalized activity suggestions, and progress tracking to boost mental wellness.',
      color: 'bg-purple-600',
      icon: Dumbbell,
      link: '/exercises', // Ensure link is a valid route
    },
    {
      title: 'GAMES',
      description: 'The games feature will offer interactive activities designed to reduce stress and improve focus.',
      color: 'bg-blue-500',
      icon: Gamepad2,
      link: '/games', // Added link
    },
    {
      title: 'MOVIES',
      description: 'The movies feature will suggest uplifting or calming films to help users relax and unwind.',
      color: 'bg-pink-500',
      icon: Clapperboard,
      link: '/movies', // Added link
    },
    {
      title: 'SOUND',
      description: 'The sounds feature will offer soothing audio, including nature sounds, white noise, and calming music.',
      color: 'bg-purple-900',
      icon: Volume2,
      link: '/sounds', // Added link
    },
    {
      title: 'BOOKS',
      description: 'The books feature will recommend calming and inspiring reads to help users relax and de-stress.',
      color: 'bg-orange-500',
      icon: BookOpen,
      link: '/books', // Added link
    },
  ];

  const ActivityCard = ({ title, description, color, icon: Icon, link }) => (
    <div className={`p-6 ${color} text-white flex flex-col items-center text-center`}>
      <Icon className="w-12 h-12 mb-4" />
      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      <p className="mb-4">{description}</p>
      <Link to={link}>
        <button className="mt-auto px-4 py-2 rounded-full bg-white text-black hover:bg-opacity-90 transition-colors">
          Find out more →
        </button>
      </Link>
    </div>
  );

  return (
    <div className='flex min-h-screen bg-gray-100'>
             
    <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>

        <div className="container mx-auto px-4 w-[900px] overflow-y-auto md:h-[560px] my-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {activities.map((activity, index) => (
          <ActivityCard key={index} {...activity} />
        ))}
      </div>
    </div>
    </div>
  );
};

export default ActivityLounge;
