import React, { useState } from "react";
import { Link } from "react-router-dom"; // Assuming React Router is used for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faGear, faArrowRight, faTimes, faBars, faSave, faShare, faClock, faSearch } from '@fortawesome/free-solid-svg-icons';
import BlogPost from "../blogpost/BlogPost"; // Import the BlogPost component
import { Bookmark } from 'lucide-react';

const Blog = ({ blogs }) => {
  const [search, setSearch] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState(blogs.slice(4, 15));
  const [TopBlogs, setTopBlogs] = useState(blogs.slice(0, 3));
  const [recommendedBlogs, setRecommendedBlogs] = useState(blogs.slice(3, 6)); // New state for recommendations
  const [HealthyLifeBlogs, setHealthyLifeBlogs] = useState(blogs.slice(6, 15)); // New state for recommendations
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filtered = blogs.filter(blog => blog.category.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredBlogs(filtered);
  };

  return (
    <div className='flex min-h-screen bg-gray-100'>
             
    <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>
      <div className="container mx-auto px-4 w-[900px] overflow-y-auto md:h-[560px]">
        <div className="container py-8">
          <div className="flex items-center justify-center mb-4">
            <input
              type="text"
              placeholder="Search by category..."
              value={search}
              onChange={handleSearch}
              className="px-4 py-2 mr-2 border border-gray-300 rounded-md"
            />
            <button className="flex items-center px-4 py-2 text-white bg-[#59844F] rounded-md">
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
              Search
            </button>
          </div>

          {/* Trending Blogs Section */}
          <div className="">
            <h1 className="text-3xl font-bold underline my-9 text-center">Our Trending Blogs</h1>
          </div>
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 ">
            {TopBlogs.length > 0 ? (
              TopBlogs.map(blog => (
                <div key={blog.id} className="p-4 bg-white rounded-lg shadow-md hover:bg-[#a8c9af]">
                  <Link to={`/blog/${blog.id}`} className="block">
                    <img src={blog.image} alt="Blog Image" className=" rounded-lg h-[200px] w-full" />
                  </Link>
                  <div className="p-6">
                <h2 className="text-[20px] font-bold mb-2 text-center">{blog.title}</h2>
                <p className="text-gray-600 text-sm text-center mb-4">
                {blog.content.substring(0, 100)}...
                </p>
                <p className="text-gray-500 text-xs mb-4 text-center">{blog.date}</p>
                <div className="flex justify-center items-center ">
                <Link to={`/blog/${blog.id}`} className="block">
                  <button className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded hover:bg-gray-100 transition-colors duration-200">
                    Read More
                  </button>
                  </Link>
                  <Bookmark className="text-gray-400 hover:text-gray-600 cursor-pointer" size={30} />
                </div>
              </div>
                  {/* Additional details */}
                  <div className="flex justify-between mt-4">
                    
                  </div>
                  <div className="flex justify-between mt-4">
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500">{blog.author}</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faClock} /> {blog.readingTime}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No trending blogs found</p>
            )}
          </div>

          {/* Recommendations Section */}
          <div>
            <h1 className="text-3xl font-bold underline my-9 text-center">Recommended Blogs</h1>
          </div>
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {recommendedBlogs.length > 0 ? (
              recommendedBlogs.map(blog => (
                <div key={blog.id} className="p-4 bg-white rounded-lg shadow-md hover:bg-[#a8c9af]">
                  <Link to={`/blog/${blog.id}`} className="block">
                    <img src={blog.image} alt="Blog Image" className=" rounded-lg h-[200px] w-full" />
                  </Link>
                  <div className="p-6">
                <h2 className="text-[20px] font-bold mb-2 text-center">{blog.title}</h2>
                <p className="text-gray-600 text-sm text-center mb-4">
                {blog.content.substring(0, 100)}...
                </p>
                <p className="text-gray-500 text-xs mb-4 text-center">{blog.date}</p>
                <div className="flex justify-center items-center ">
                <Link to={`/blog/${blog.id}`} className="block">
                  <button className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded hover:bg-gray-100 transition-colors duration-200">
                    Read More
                  </button>
                  </Link>
                  <Bookmark className="text-gray-400 hover:text-gray-600 cursor-pointer" size={30} />
                </div>
              </div>
                  {/* Additional details */}
                  <div className="flex justify-between mt-4">
                    
                  </div>
                  <div className="flex justify-between mt-4">
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500">{blog.author}</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faClock} /> {blog.readingTime}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No recommended blogs available</p>
            )}
          </div>

          {/* Healthy Life Section */}
          <div>
            <h1 className="text-3xl font-bold underline my-9 text-center">Healthy Life Blogs</h1>
          </div>
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {HealthyLifeBlogs.length > 0 ? (
              HealthyLifeBlogs.map(blog => (
                <div key={blog.id} className="p-4 bg-white rounded-lg shadow-md hover:bg-[#a8c9af]">
                  <Link to={`/blog/${blog.id}`} className="block">
                    <img src={blog.image} alt="Blog Image" className=" rounded-lg h-[200px] w-full" />
                  </Link>
                  <div className="p-6">
                <h2 className="text-[20px] font-bold mb-2 text-center">{blog.title}</h2>
                <p className="text-gray-600 text-sm text-center mb-4">
                {blog.content.substring(0, 100)}...
                </p>
                <p className="text-gray-500 text-xs mb-4 text-center">{blog.date}</p>
                <div className="flex justify-center items-center ">
                <Link to={`/blog/${blog.id}`} className="block">
                  <button className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded hover:bg-gray-100 transition-colors duration-200">
                    Read More
                  </button>
                  </Link>
                  <Bookmark className="text-gray-400 hover:text-gray-600 cursor-pointer" size={30} />
                </div>
              </div>
                  {/* Additional details */}
                  <div className="flex justify-between mt-4">
                    
                  </div>
                  <div className="flex justify-between mt-4">
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500">{blog.author}</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faClock} /> {blog.readingTime}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No recommended blogs available</p>
            )}
          </div>
        </div>

        <Link to="/createblog" className="fixed px-4 py-2 text-white bg-[#59844F] rounded-full shadow-md bottom-4 right-4 create-btn">
          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Create Blog Now
        </Link>
      </div>
    </div>
  );
};

export default Blog;
