import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { 
  faImage, faSave, faTrash, faShare, faArrowRight,
  faGear, faTimes, faBars, faBold, faItalic, faUnderline,
  faListUl, faListOl, faQuoteRight, faLink, faSpinner
} from '@fortawesome/free-solid-svg-icons';

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStyles, setActiveStyles] = useState({
    bold: false,
    italic: false,
    underline: false,
    blockquote: false,
    orderedList: false,
    unorderedList: false,
  });

  const editorRef = useRef(null);

  useEffect(() => {
    const handleSelectionChange = () => {
      if (document.activeElement === editorRef.current) {
        setActiveStyles({
          bold: document.queryCommandState('bold'),
          italic: document.queryCommandState('italic'),
          underline: document.queryCommandState('underline'),
          blockquote: document.queryCommandValue('formatBlock') === 'blockquote',
          orderedList: document.queryCommandState('insertOrderedList'),
          unorderedList: document.queryCommandState('insertUnorderedList'),
        });
      }
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  const toggleActivities = () => {
    setIsActivitiesOpen(!isActivitiesOpen);
  };

  const handleSaveDraft = () => {
    setIsDraft(true);
  };

  const handlePublish = () => {
    setIsLoading(true);
    
    const formData = new FormData();
    formData.append('title', title);
    formData.append('fullname', fullName);
    formData.append('email', email);
    formData.append('content', content);
    formData.append('image', image);

    fetch('https://serenimindbackend.onrender.com/api/create-blog/', {
      method: 'POST',
      body: formData,
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);
      setIsLoading(false);
      setTitle('');
      setContent('');
      setImage(null);
      setFullName('');
      setEmail('');
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false);
    });
  };

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleEditorAction = (action) => {
    editorRef.current.focus();
    switch (action) {
      case 'bold':
      case 'italic':
      case 'underline':
        document.execCommand(action, false, null);
        break;
      case 'insertUnorderedList':
        document.execCommand('insertUnorderedList', false, null);
        break;
      case 'insertOrderedList':
        document.execCommand('insertOrderedList', false, null);
        break;
      case 'formatBlock':
        document.execCommand('formatBlock', false, '<blockquote>');
        break;
      case 'createLink':
        const url = prompt('Enter the URL:');
        if (url && isValidURL(url)) {
          document.execCommand('createLink', false, url);
        } else {
          alert('Invalid URL');
        }
        break;
      default:
        break;
    }
    setActiveStyles(prev => ({
      ...prev,
      [action]: !prev[action]
    }));
  };

  const handleEditorChange = (event) => {
    setContent(event.target.innerHTML);
  };

  return (
    <div className='flex min-h-screen bg-gray-100'>
      <div className={`transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
        <div className="p-6">
          <nav>
            <ul className="space-y-2">
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
                <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
                <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
                <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
                <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
                <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
              </button></li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="container md:w-[900px] md:mx-auto mx-2 mt-8">
        <h1 className="mb-4 md:text-3xl font-bold text-xl text-center">Create a New Blog Post</h1>

        <div className="mb-4">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-4 py-2 text-2xl font-bold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
            placeholder="Enter your title here..."
            required
          />
        </div>

        <div className="mb-4 bg-white rounded-lg shadow">
          <div className="flex flex-wrap gap-2 p-2 border-b">
            <button onClick={() => handleEditorAction('bold')} className={`p-2 rounded ${activeStyles.bold ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>
              <FontAwesomeIcon icon={faBold} />
            </button>
            <button onClick={() => handleEditorAction('italic')} className={`p-2 rounded ${activeStyles.italic ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>
              <FontAwesomeIcon icon={faItalic} />
            </button>
            <button onClick={() => handleEditorAction('underline')} className={`p-2 rounded ${activeStyles.underline ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>
              <FontAwesomeIcon icon={faUnderline} />
            </button>
            <button onClick={() => handleEditorAction('insertUnorderedList')} className={`p-2 rounded ${activeStyles.unorderedList ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>
              <FontAwesomeIcon icon={faListUl} />
            </button>
            <button onClick={() => handleEditorAction('insertOrderedList')} className={`p-2 rounded ${activeStyles.orderedList ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>
              <FontAwesomeIcon icon={faListOl} />
            </button>
            <button onClick={() => handleEditorAction('formatBlock')} className={`p-2 rounded ${activeStyles.blockquote ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>
              <FontAwesomeIcon icon={faQuoteRight} />
            </button>
            <button onClick={() => handleEditorAction('createLink')} className="p-2 hover:bg-gray-100 rounded">
              <FontAwesomeIcon icon={faLink} />
            </button>
          </div>
          <div
            ref={editorRef}
            contentEditable
            className="w-full px-4 py-2 h-64 focus:outline-none overflow-auto"
            dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>

        <div className="mb-4">
          <label htmlFor="image" className="block mb-2 font-semibold">
            <FontAwesomeIcon icon={faImage} className="mr-2" />
            Featured Image:
          </label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="w-full p-2 border border-gray-300 rounded"
          />
          {image && <img src={URL.createObjectURL(image)} alt="Preview" className="mt-2 max-w-xs rounded" />}
        </div>

        <div className="mb-4">
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="w-full px-4 py-2 mb-2 border border-gray-300 rounded"
            placeholder="Your Full Name"
            required
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded"
            placeholder="Your Email"
            required
          />
        </div>
        
        {showSuccessMessage && (
          <div className="p-4 mb-4 text-green-700 bg-green-100 border border-green-400 rounded">
            Your blog has been sent. We will get back to you soon.
          </div>
        )}

        <div className="flex justify-end">
          <button onClick={handleSaveDraft} className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600" disabled={isLoading}>
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save Draft
          </button>
          <button onClick={handlePublish} className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
            {isLoading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                Publishing...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faShare} className="mr-2" />
                Publish
              </>
            )}
          </button>
        </div>

        {isDraft && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-8 bg-white rounded-md">
              <h2 className="mb-4 text-xl font-semibold">Your blog post has been saved as a draft!</h2>
              <button onClick={() => setIsDraft(false)} className="px-4 py-2 text-white bg-blue-500 rounded-md">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateBlog;
