import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faGear, faMicrophone, faImage, faSmile, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const SereniAi = () => {
  const [messages, setMessages] = useState([
    { text: "Hello! I'm SereniAI, your personal mental health assistant. How can I help you today?", isBot: true }
]);
const [inputText, setInputText] = useState("");
const [conversationId, setConversationId] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const chatContainerRef = useRef(null);

useEffect(() => {
    if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
}, [messages]);

const handleSendMessage = async (message) => {
    setMessages(prev => [...prev, { text: message, isBot: false }]);
    setIsLoading(true);
    
    try {
        const response = await fetch('https://serenimindbackend.onrender.com/api/chat/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message, conversation_id: conversationId }),
        });
        
        const data = await response.json();
        
        setMessages(prev => [...prev, { text: data.response, isBot: true }]);
        setConversationId(data.conversation_id);
    } catch (error) {
        console.error('Error:', error);
        setMessages(prev => [...prev, { text: "Sorry, I'm having trouble connecting right now. Please try again later.", isBot: true, isError: true }]);
    } finally {
        setIsLoading(false);
    }
};

const handleInputChange = (e) => {
    setInputText(e.target.value);
};

const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        sendMessage();
    }
};

const sendMessage = () => {
    if (inputText.trim()) {
        handleSendMessage(inputText);
        setInputText("");
    }
};

    return (
        <div className="flex min-h-screen bg-gray-100">
            {/* Side Navigation */}
          <div className={"`  transform  ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'}  w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav `"}>
        <div className="p-6">
          <nav>
            <ul className="space-y-2">
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
              </button></li>
            </ul>
          </nav>
        </div>
        
      </div>
      <div className="flex-1 flex flex-col mx-auto px-4 md:w-[900px] md:overflow-y-auto md:h-[540px]">
                {/* Chat Messages */}
                <div ref={chatContainerRef} className="flex-1 p-4 overflow-y-auto">
                    {messages.map((message, index) => (
                        <div key={index} className={`flex ${message.isBot ? 'justify-start' : 'justify-end'} mb-4`}>
                            <div className={`max-w-xs lg:max-w-md xl:max-w-lg px-4 py-2 rounded-lg ${
                                message.isBot 
                                    ? 'bg-white text-gray-800 shadow' 
                                    : 'bg-green-500 text-white'
                            } ${message.isError ? 'bg-red-100 text-red-800' : ''}`}>
                                <p className="text-sm">{message.text}</p>
                            </div>
                        </div>
                    ))}
                    {isLoading && (
                        <div className="flex justify-start mb-4">
                            <div className="bg-white text-gray-800 shadow max-w-xs lg:max-w-md xl:max-w-lg px-4 py-2 rounded-lg">
                                <FontAwesomeIcon icon={faSpinner} spin className="text-green-500 mr-2" />
                                <span className="text-sm">SereniAI is thinking...</span>
                            </div>
                        </div>
                    )}
                </div>

                {/* Input Area */}
                <div className="bg-white border-t border-gray-200 p-4">
                    <div className="flex items-center">
                        <textarea
                            value={inputText}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Type a message..."
                            className="flex-1 border border-gray-300 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 resize-none overflow-hidden"
                            rows="1"
                        />
                        <button 
                            onClick={sendMessage}
                            className="ml-2 bg-green-500 text-white rounded-full p-2 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                            disabled={isLoading}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                    <p className="text-xs text-gray-500 mt-2 text-center">
                        SereniAI can make mistakes. Please provide 
                        <Link to="/feedback" className="text-green-500 hover:underline"> feedback</Link>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SereniAi;