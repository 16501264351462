import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

const Exercise = () => {
  const [mood, setMood] = useState('');
  const [exercises, setExercises] = useState([]);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const exerciseList = {
    happy: [
      { name: 'Dancing', type: 'Cardio', reason: 'Dancing is a great way to express and amplify happiness.', gif: 'https://media.giphy.com/media/RX7N03MEUafW8/giphy.gif?cid=790b7611rjlcbi6ge292q7w5d3pnjw00zde5ackuofr8pcwj&ep=v1_gifs_search&rid=giphy.gif&ct=g' },
      { name: 'Cycling', type: 'Endurance', reason: 'Cycling can boost your mood and energy levels.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Group Fitness Class', type: 'Social', reason: 'Sharing your positive energy in a group setting can be fun and motivating.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' }
    ],
    sad: [
      { name: 'Yoga', type: 'Mind-Body', reason: 'Yoga can help lift your mood and increase relaxation.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Walking in Nature', type: 'Low-Intensity', reason: 'Being in nature can help alleviate feelings of sadness.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Swimming', type: 'Low-Impact', reason: 'The rhythmic nature of swimming can be soothing and mood-lifting.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' }
    ],
    energetic: [
      { name: 'High-Intensity Interval Training (HIIT)', type: 'High-Intensity', reason: 'HIIT is perfect for channeling high energy levels.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Rock Climbing', type: 'Strength and Coordination', reason: 'Rock climbing is an exciting way to use your energy.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' },
      { name: 'Martial Arts', type: 'Skill-Based', reason: 'Martial arts provide a disciplined outlet for high energy.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' }
    ],
    stressed: [
      { name: 'Tai Chi', type: 'Mind-body', reason: 'Tai Chi promotes relaxation and reduces stress.', gif: 'https://media.giphy.com/media/3o7TKoHNJTWu7RVPV6/giphy.gif' },
      { name: 'Pilates', type: 'Core strength', reason: 'Pilates improves focus and reduces tension.', gif: 'https://media.giphy.com/media/3ohhwxWxUAj2oGtOSI/giphy.gif' },
      { name: 'Hiking', type: 'Outdoor', reason: 'Hiking in nature reduces cortisol levels and calms the mind.', gif: 'https://media.giphy.com/media/3o7TKMf5HQQlZvv9Cg/giphy.gif' }
    ],
    excited: [
      { name: 'Zumba', type: 'Aerobic', reason: 'Zumba is a fun and high-energy workout that boosts mood.', gif: 'https://media.giphy.com/media/3o7TKyPpWvFrpwua2I/giphy.gif' },
      { name: 'Jumping Jacks', type: 'Cardio', reason: 'Jumping jacks increase heart rate and release endorphins.', gif: 'https://media.giphy.com/media/3o7TKvtCj7IcbUHg3u/giphy.gif' },
      { name: 'Cycling', type: 'Endurance', reason: 'Cycling outdoors or indoors improves overall mood and stamina.', gif: 'https://media.giphy.com/media/3o7TKrEzvLbsVAud8I/giphy.gif' }
    ],
    relaxed: [
      { name: 'Meditation', type: 'Mindfulness', reason: 'Meditation calms the mind and reduces stress levels.', gif: 'https://media.giphy.com/media/3o7TKoJ3j2XRHCGK6A/giphy.gif' },
      { name: 'Tai Chi', type: 'Mind-body', reason: 'Tai Chi promotes relaxation and mental clarity.', gif: 'https://media.giphy.com/media/3o7TKoHNJTWu7RVPV6/giphy.gif' },
      { name: 'Gentle Stretching', type: 'Flexibility', reason: 'Gentle stretching exercises relieve muscle tension and induce relaxation.', gif: 'https://media.giphy.com/media/3o7TKpDnhWEbmLxdQs/giphy.gif' }
    ],
    energized: [
      { 
        name: 'HIIT Workouts', 
        type: 'High-intensity', 
        reason: 'HIIT workouts boost energy levels and improve mood.', 
        gif: 'https://media.giphy.com/media/xT5LMzuKkPeMxTAFhK/giphy.gif' 
      },
      { 
        name: 'Kickboxing', 
        type: 'Strength and Cardio', 
        reason: 'Kickboxing releases pent-up energy and improves overall mood.', 
        gif: 'https://media.giphy.com/media/3ohjV4Rhs51jTr7ld6/giphy.gif' 
      },
      { 
        name: 'Plyometric Exercises', 
        type: 'Explosive Power', 
        reason: 'Plyometrics increase adrenaline and enhance energy levels.', 
        gif: 'https://media.giphy.com/media/26FPtjAb9wBmJfQB6/giphy.gif' 
      }
    ],
    motivated: [
      { 
        name: 'Circuit Training', 
        type: 'Full-body', 
        reason: 'Circuit training provides variety and motivation in workouts.', 
        gif: 'https://media.giphy.com/media/d2Z1eG8YpM0EQw19O/giphy.gif' 
      },
      { 
        name: 'Group Fitness Classes', 
        type: 'Community', 
        reason: 'Group classes create a motivating and supportive environment.', 
        gif: 'https://media.giphy.com/media/3oz8xFw6HfwXqMQ9c4/giphy.gif' 
      },
      { 
        name: 'Outdoor Running', 
        type: 'Cardio', 
        reason: 'Running outdoors boosts motivation and overall well-being.', 
        gif: 'https://media.giphy.com/media/3oKIPnAiaMCws8nOsE/giphy.gif' 
      }
    ],
    refreshed: [
      { 
        name: 'Swimming', 
        type: 'Full-body', 
        reason: 'Swimming refreshes the body and mind while providing a full workout.', 
        gif: 'https://media.giphy.com/media/3o7TKMn2p97KU2yN8A/giphy.gif' 
      },
      { 
        name: 'Pilates', 
        type: 'Core strength', 
        reason: 'Pilates improves posture and revitalizes the body.', 
        gif: 'https://media.giphy.com/media/3o7TKwdFhLkPl8EZ0Q/giphy.gif' 
      },
      { 
        name: 'Cycling', 
        type: 'Endurance', 
        reason: 'Cycling outdoors or indoors provides a refreshing workout experience.', 
        gif: 'https://media.giphy.com/media/l378r9DWcAjhGLt6g/giphy.gif' 
      }
    ],
    focused: [
      { 
        name: 'Yoga', 
        type: 'Mind-body', 
        reason: 'Yoga enhances focus, concentration, and mental clarity.', 
        gif: 'https://media.giphy.com/media/3oKIPxAKZo2GXr8swo/giphy.gif' 
      },
      { 
        name: 'Strength Training', 
        type: 'Muscle Building', 
        reason: 'Strength training boosts confidence and mental focus.', 
        gif: 'https://media.giphy.com/media/xT5LMoS8t3dFllL1O4/giphy.gif' 
      },
      { 
        name: 'Interval Training', 
        type: 'Cardio and Strength', 
        reason: 'Interval training sharpens focus and improves overall fitness.', 
        gif: 'https://media.giphy.com/media/l378oE6sUAY8IMteI/giphy.gif' 
      }
    ],
    calm: [
      { 
        name: 'Deep Breathing Exercises', 
        type: 'Relaxation', 
        reason: 'Deep breathing calms the nervous system and reduces anxiety.', 
        gif: 'https://media.giphy.com/media/3ohhwG4cGEnl8Ul7nS/giphy.gif' 
      },
      { 
        name: 'Nature Walks', 
        type: 'Outdoor', 
        reason: 'Walking in nature promotes calmness and mental clarity.', 
        gif: 'https://media.giphy.com/media/3o7TKx4mgbD99pT3Nu/giphy.gif' 
      },
      { 
        name: 'Mindfulness Meditation', 
        type: 'Stress Reduction', 
        reason: 'Mindfulness meditation induces a sense of calm and inner peace.', 
        gif: 'https://media.giphy.com/media/xT9Igsmfi8xwcp3ZlC/giphy.gif' 
      }
    ],
    sad: [
      { name: 'Running', type: 'Aerobic', reason: 'Running increases dopamine and serotonin levels.', gif: 'https://media.giphy.com/media/3o7ZegjWUrtZurx63m/giphy.gif' },
      { name: 'Swimming', type: 'Cardio', reason: 'Swimming provides a full-body workout and reduces stress.', gif: 'https://media.giphy.com/media/l0HlCRZjYqgM3aGvS/giphy.gif' },
      { name: 'Weightlifting', type: 'Strength', reason: 'Weightlifting boosts mood by releasing endorphins.', gif: 'https://media.giphy.com/media/7kn27lnYSAE9O/giphy.gif' }
    ],
    stressed: [
      { name: 'Tai Chi', type: 'Mind-body', reason: 'Tai Chi promotes relaxation and reduces stress.', gif: 'https://media.giphy.com/media/3o7TKoHNJTWu7RVPV6/giphy.gif' },
      { name: 'Pilates', type: 'Core strength', reason: 'Pilates improves focus and reduces tension.', gif: 'https://media.giphy.com/media/3ohhwxWxUAj2oGtOSI/giphy.gif' },
      { name: 'Hiking', type: 'Outdoor', reason: 'Hiking in nature reduces cortisol levels and calms the mind.', gif: 'https://media.giphy.com/media/3o7TKMf5HQQlZvv9Cg/giphy.gif' }
    ],
    enthusiastic: [
      { name: 'Dance Fitness', type: 'Aerobic', reason: 'Dance fitness classes boost enthusiasm and energy levels.', gif: 'https://media.giphy.com/media/3o7TKyPpWvFrpwua2I/giphy.gif' },
      { name: 'Boxing Workouts', type: 'Cardio and Strength', reason: 'Boxing workouts increase enthusiasm and provide a full-body challenge.', gif: 'https://media.giphy.com/media/3o7TKFe8xHMzUoNqg0/giphy.gif' },
      { name: 'Hiking', type: 'Outdoor', reason: 'Hiking in scenic locations enhances enthusiasm and positivity.', gif: 'https://media.giphy.com/media/3o7TKMf5HQQlZvv9Cg/giphy.gif' }
    ],
    invigorated: [
      { name: 'Circuit Training', type: 'Full-body', reason: 'Circuit training invigorates the body and mind with varied exercises.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Yoga Flow', type: 'Flexibility and Strength', reason: 'Yoga flows re-energize the body and improve overall well-being.', gif: 'https://media.giphy.com/media/3o7TKoHNJTWu7RVPV6/giphy.gif' },
      { name: 'Swimming', type: 'Endurance', reason: 'Swimming laps or aqua aerobics invigorate the body and boost mood.', gif: 'https://media.giphy.com/media/l0HlCRZjYqgM3aGvS/giphy.gif' }
    ],
    peaceful: [
      { name: 'Pilates', type: 'Core strength', reason: 'Pilates promotes a sense of peace and tranquility while strengthening the core.', gif: 'https://media.giphy.com/media/3ohhwxWxUAj2oGtOSI/giphy.gif' },
      { name: 'Meditation', type: 'Mindfulness', reason: 'Meditation sessions bring inner peace and calmness to the mind.', gif: 'https://media.giphy.com/media/3o7TKoJ3j2XRHCGK6A/giphy.gif' },
      { name: 'Gentle Yoga', type: 'Relaxation', reason: 'Gentle yoga poses induce a peaceful state of mind and body.', gif: 'https://media.giphy.com/media/3o7TKpDnhWEbmLxdQs/giphy.gif' }
    ],
    productive: [
      { name: 'Interval Training', type: 'Cardio and Strength', reason: 'Interval training boosts productivity and energy levels.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Functional Training', type: 'Full-body', reason: 'Functional exercises improve daily functionality and productivity.', gif: 'https://media.giphy.com/media/3o7TKuylrX8kT7XhVS/giphy.gif' },
      { name: 'Tabata Workouts', type: 'High-intensity', reason: 'Tabata workouts enhance focus and efficiency in workouts.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' }
    ],
    creative: [
      { name: 'Dance Choreography', type: 'Artistic', reason: 'Creating dance routines stimulates creativity and expression.', gif: 'https://media.giphy.com/media/3o7TKyPpWvFrpwua2I/giphy.gif' },
      { name: 'Artistic Yoga Flow', type: 'Mind-body', reason: 'Artistic yoga flows inspire creativity and mindfulness.', gif: 'https://media.giphy.com/media/3o7TKoHNJTWu7RVPV6/giphy.gif' },
      { name: 'Bodyweight Flow', type: 'Flowing Movements', reason: 'Bodyweight flows encourage creative movement patterns.', gif: 'https://media.giphy.com/media/3o7TKuylrX8kT7XhVS/giphy.gif' }
    ],
    adventurous: [
      { name: 'Obstacle Course Training', type: 'Adventure', reason: 'Obstacle courses challenge and excite adventurous spirits.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' },
      { name: 'Rock Climbing', type: 'Strength and Agility', reason: 'Rock climbing offers an adventurous physical and mental challenge.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Trail Running', type: 'Outdoor', reason: 'Trail running adds an adventurous element to cardio workouts.', gif: 'https://media.giphy.com/media/3o7TKMf5HQQlZvv9Cg/giphy.gif' }
    ],
    euphoric: [
      { name: 'Dance Party Workout', type: 'Aerobic', reason: 'Dance parties with upbeat music create euphoric workout experiences.', gif: 'https://media.giphy.com/media/3o7TKyPpWvFrpwua2I/giphy.gif' },
      { name: 'Extreme Sports Training', type: 'Adrenaline Rush', reason: 'Extreme sports training induces euphoria and excitement.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' },
      { name: 'High-Intensity Dance Cardio', type: 'Endorphin Boost', reason: 'High-intensity dance cardio releases euphoric endorphins.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' }
    ],
    confident: [
      { name: 'Powerlifting', type: 'Strength', reason: 'Powerlifting builds physical strength and boosts confidence levels.', gif: 'https://media.giphy.com/media/3o7TKFe8xHMzUoNqg0/giphy.gif' },
      { name: 'Assertive Yoga Flow', type: 'Empowerment', reason: 'Assertive yoga flows enhance confidence and self-assurance.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' },
      { name: 'Self-Defense Classes', type: 'Empowerment', reason: 'Self-defense classes increase confidence and personal safety.', gif: 'https://media.giphy.com/media/3o7TKuylrX8kT7XhVS/giphy.gif' }
    ],
    grateful: [
      { name: 'Gratitude Meditation', type: 'Mindfulness', reason: 'Gratitude meditations foster a sense of gratitude and well-being.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Community Workouts', type: 'Connection', reason: 'Working out with others fosters gratitude and social connection.', gif: 'https://media.giphy.com/media/3o7TKSha51ATTx9KzC/giphy.gif' },
      { name: 'Charity Run/Walk', type: 'Giving Back', reason: 'Participating in charity events promotes gratitude and altruism.', gif: 'https://media.giphy.com/media/3o7TKKxPt2MaK4W6Ck/giphy.gif' }
    ],
    motivated: [
      { name: 'Goal-Oriented Training', type: 'Focus', reason: 'Goal-oriented training plans keep motivation levels high.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' },
      { name: 'Competition Preparation', type: 'Drive', reason: 'Preparing for competitions fuels motivation and determination.', gif: 'https://media.giphy.com/media/3o7TKSha51ATTx9KzC/giphy.gif' },
      { name: 'Visualization Techniques', type: 'Mindset', reason: 'Visualization practices enhance motivation and goal achievement.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' }
    ],
    reflective: [
      { name: 'Slow Flow Yoga', type: 'Mindfulness', reason: 'Slow flow yoga sessions encourage reflection and introspection.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Journaling and Exercise', type: 'Mind-Body Connection', reason: 'Combining journaling with exercise fosters reflective thinking.', gif: 'https://media.giphy.com/media/3o7TKyHkFRxfQ5A7tK/giphy.gif' },
      { name: 'Walking Meditation', type: 'Mindfulness', reason: 'Walking meditations promote introspection and mindfulness.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    hopeful: [
      { name: 'Vision Board Workouts', type: 'Goal Setting', reason: 'Vision board workouts inspire hope and goal visualization.', gif: 'https://media.giphy.com/media/3o7TKKxPt2MaK4W6Ck/giphy.gif' },
      { name: 'Hopeful Affirmations', type: 'Mindset', reason: 'Using hopeful affirmations during workouts enhances positivity.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Community Support Groups', type: 'Connection', reason: 'Joining support groups fosters hope and encouragement.', gif: 'https://media.giphy.com/media/3o7TKSha51ATTx9KzC/giphy.gif' }
    ],
    grounded: [
      { name: 'Earthing Practices', type: 'Nature Connection', reason: 'Earthing exercises ground the body and mind in nature.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Mindful Walking', type: 'Grounding', reason: 'Mindful walking exercises promote a sense of grounding and presence.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' },
      { name: 'Qi Gong', type: 'Energy Flow', reason: 'Qi Gong practices balance energy and ground the practitioner.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' }
    ],
    confused: [
      { name: 'Mind Mapping', type: 'Visualization', reason: 'Mind mapping helps organize thoughts and reduce confusion.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' },
      { name: 'Stretching Breaks', type: 'Relaxation', reason: 'Taking stretching breaks relieves tension and clears the mind.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' },
      { name: 'Breathing Exercises', type: 'Clarity', reason: 'Deep breathing exercises improve focus and reduce mental fog.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' }
    ],
    inspired: [
      { name: 'Creative Writing', type: 'Artistic Expression', reason: 'Creative writing channels inspiration into written form.', gif: 'https://media.giphy.com/media/3o7TKyHkFRxfQ5A7tK/giphy.gif' },
      { name: 'Outdoor Sketching', type: 'Nature Connection', reason: 'Sketching outdoors ignites creativity and inspiration.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Inspirational Reading', type: 'Mind Expansion', reason: 'Reading inspiring books stimulates creative thinking.', gif: 'https://media.giphy.com/media/3o7TKKxPt2MaK4W6Ck/giphy.gif' }
    ],
    restless: [
      { name: 'High-Energy Cardio', type: 'Endorphin Boost', reason: 'High-energy cardio workouts release pent-up energy.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Dance Fusion', type: 'Expressive Movement', reason: 'Dance fusion classes channel restless energy into movement.', gif: 'https://media.giphy.com/media/3o7TKyPpWvFrpwua2I/giphy.gif' },
      { name: 'Power Yoga', type: 'Strength and Flexibility', reason: 'Power yoga sequences energize and calm restlessness.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' }
    ],
    playful: [
      { name: 'Interactive Dance Games', type: 'Fun and Fitness', reason: 'Interactive dance games combine fun and physical activity.', gif: 'https://media.giphy.com/media/3o7TKyPpWvFrpwua2I/giphy.gif' },
      { name: 'Outdoor Playgrounds', type: 'Childlike Joy', reason: 'Visiting outdoor playgrounds brings out playfulness and joy.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Animal Flow Workouts', type: 'Dynamic Movement', reason: 'Animal flow exercises mimic playful animal movements.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' }
    ],
    skeptical: [
      { name: 'Educational Workshops', type: 'Learning', reason: 'Attending educational workshops addresses skepticism with knowledge.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' },
      { name: 'Scientific Fitness Programs', type: 'Evidence-Based Approach', reason: 'Following science-backed fitness programs reduces skepticism.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' },
      { name: 'Expert Consultations', type: 'Professional Advice', reason: 'Seeking advice from experts addresses skepticism and confusion.', gif: 'https://media.giphy.com/media/3o7TKFe8xHMzUoNqg0/giphy.gif' }
    ],
    overwhelmed: [
      { name: 'Mindful Breathing Sessions', type: 'Stress Relief', reason: 'Mindful breathing reduces overwhelm and promotes relaxation.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Task Prioritization Techniques', type: 'Organization', reason: 'Prioritizing tasks reduces overwhelm and improves productivity.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' },
      { name: 'Digital Detox Activities', type: 'Mindful Disconnect', reason: 'Engaging in digital detox activities reduces mental overload.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    content: [
      { name: 'Gratitude Journaling', type: 'Mindfulness', reason: 'Gratitude journaling cultivates contentment and appreciation.', gif: 'https://media.giphy.com/media/3o7TKyHkFRxfQ5A7tK/giphy.gif' },
      { name: 'Nature Immersion', type: 'Outdoor Connection', reason: 'Immersing in nature enhances contentment and peace of mind.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Self-Care Practices', type: 'Well-Being', reason: 'Regular self-care routines promote contentment and inner balance.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' }
    ],
    determined: [
      { name: 'Challenge Workouts', type: 'Persistence', reason: 'Challenge workouts test determination and build resilience.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' },
      { name: 'Mental Toughness Training', type: 'Mindset Strengthening', reason: 'Training for mental toughness boosts determination levels.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' },
      { name: 'Personal Development Courses', type: 'Growth Mindset', reason: 'Taking personal development courses fuels determination and growth.', gif: 'https://media.giphy.com/media/3o7TKKxPt2MaK4W6Ck/giphy.gif' }
    ],
    curious: [
      { name: 'New Fitness Classes', type: 'Exploration', reason: 'Exploring new fitness classes satisfies curiosity and adds variety.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' },
      { name: 'Mind-Body Connection Workshops', type: 'Awareness', reason: 'Attending mind-body connection workshops sparks curiosity and self-discovery.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Adventure Challenges', type: 'Exploration', reason: 'Participating in adventure challenges satisfies the curiosity for new experiences.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' }
    ],
    anxious: [
      { name: 'Deep Breathing Exercises', type: 'Relaxation', reason: 'Deep breathing promotes calmness and reduces anxiety.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Progressive Muscle Relaxation', type: 'Stress Reduction', reason: 'PMR helps release tension and eases anxious feelings.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' },
      { name: 'Mindfulness Meditation', type: 'Present Moment Awareness', reason: 'Mindfulness meditation cultivates awareness and reduces anxiety.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    worried: [
      { name: 'Deep Breathing Exercises', type: 'Relaxation', reason: 'Deep breathing promotes calmness and reduces anxiety.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Progressive Muscle Relaxation', type: 'Stress Reduction', reason: 'PMR helps release tension and eases anxious feelings.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' },
      { name: 'Mindfulness Meditation', type: 'Present Moment Awareness', reason: 'Mindfulness meditation cultivates awareness and reduces anxiety.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    scared: [
      { name: 'Deep Breathing Exercises', type: 'Relaxation', reason: 'Deep breathing promotes calmness and reduces anxiety.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Progressive Muscle Relaxation', type: 'Stress Reduction', reason: 'PMR helps release tension and eases anxious feelings.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' },
      { name: 'Mindfulness Meditation', type: 'Present Moment Awareness', reason: 'Mindfulness meditation cultivates awareness and reduces anxiety.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    depressed: [
      { name: 'Nature Walks', type: 'Mood Boost', reason: 'Being in nature boosts serotonin levels and uplifts mood.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Yoga for Depression', type: 'Mind-Body Connection', reason: 'Yoga poses and breathwork alleviate symptoms of depression.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Positive Affirmations', type: 'Self-Empowerment', reason: 'Repeating positive affirmations improves self-esteem and mood.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' }
    ],
    stressed: [
      { name: 'Progressive Relaxation Techniques', type: 'Stress Relief', reason: 'Progressive relaxation reduces muscle tension and stress levels.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' },
      { name: 'Tai Chi for Stress Management', type: 'Mind-Body Harmony', reason: 'Tai Chi movements promote relaxation and inner peace.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Journaling', type: 'Emotional Release', reason: 'Writing down thoughts and feelings helps process stress and emotions.', gif: 'https://media.giphy.com/media/3o7TKyHkFRxfQ5A7tK/giphy.gif' }
    ],
    lonely: [
      { name: 'Group Fitness Classes', type: 'Social Interaction', reason: 'Joining group classes fosters connections and combats loneliness.', gif: 'https://media.giphy.com/media/3o7TKSha51ATTx9KzC/giphy.gif' },
      { name: 'Volunteering Activities', type: 'Community Engagement', reason: 'Volunteering provides a sense of purpose and social connection.', gif: 'https://media.giphy.com/media/3o7TKKxPt2MaK4W6Ck/giphy.gif' },
      { name: 'Online Support Groups', type: 'Peer Support', reason: 'Connecting with others in online support groups reduces feelings of loneliness.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' }
    ],
    tired: [
      { name: 'Restorative Yoga', type: 'Stress Reduction', reason: 'Restorative yoga helps recharge the body and alleviate burnout.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Time Management Strategies', type: 'Work-Life Balance', reason: 'Implementing time management techniques reduces burnout and stress.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' },
      { name: 'Digital Detox', type: 'Mindful Disconnect', reason: 'Taking breaks from digital devices promotes mental rejuvenation and reduces burnout.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    motivated: [
      { name: 'Goal Setting and Planning', type: 'Focus', reason: 'Setting clear goals and planning actions boosts motivation.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' },
      { name: 'Visualization Exercises', type: 'Positive Outlook', reason: 'Visualizing success and positive outcomes enhances motivation.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' },
      { name: 'Physical Activity Challenges', type: 'Energy Boost', reason: 'Participating in fitness challenges increases motivation and energy levels.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' }
    ],
    restless: [
      { name: 'Physical Workouts', type: 'Energy Release', reason: 'Engaging in physical activities releases restless energy and improves mood.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Creative Projects', type: 'Mind Distraction', reason: 'Channeling restlessness into creative projects provides focus and satisfaction.', gif: 'https://media.giphy.com/media/3o7TKyHkFRxfQ5A7tK/giphy.gif' },
      { name: 'Mindfulness Practices', type: 'Inner Calm', reason: 'Mindfulness techniques calm the mind and reduce restlessness.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    selfesteem: [
      { name: 'Positive Self-Talk', type: 'Self-Compassion', reason: 'Replacing negative self-talk with positive affirmations boosts self-esteem.', gif: 'https://media.giphy.com/media/3o7TKMt1VVNkHV2PaE/giphy.gif' },
      { name: 'Strength Training', type: 'Self-Empowerment', reason: 'Strength training builds physical strength and confidence.', gif: 'https://media.giphy.com/media/3o7TKFe8xHMzUoNqg0/giphy.gif' },
      { name: 'Therapeutic Art Sessions', type: 'Creative Expression', reason: 'Engaging in art therapy enhances self-expression and self-worth.', gif: 'https://media.giphy.com/media/3o7TKyHkFRxfQ5A7tK/giphy.gif' }
    ],
    syndrome: [
      { name: 'Cognitive Behavioral Therapy (CBT)', type: 'Thought Restructuring', reason: 'CBT techniques challenge negative beliefs and reduce imposter syndrome.', gif: 'https://media.giphy.com/media/3o7TKT6MYGIgy4U93i/giphy.gif' },
      { name: 'Skill Development Workshops', type: 'Competence Building', reason: 'Acquiring new skills and knowledge boosts confidence and reduces imposter feelings.', gif: 'https://media.giphy.com/media/3o7TKKxPt2MaK4W6Ck/giphy.gif' },
      { name: 'Mentorship Programs', type: 'Support Network', reason: 'Connecting with mentors provides guidance and validation, reducing imposter feelings.', gif: 'https://media.giphy.com/media/3o7TKFe8xHMzUoNqg0/giphy.gif' }
    ],
    angry: [
      { name: 'Boxing', type: 'Cardio', reason: 'Boxing is a powerful way to release built-up anger and stress.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' },
      { name: 'Kickboxing', type: 'Strength and Cardio', reason: 'Kickboxing provides a vigorous workout that channels anger into physical exertion.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Sprinting', type: 'High-Intensity', reason: 'Sprinting can help release adrenaline and reduce anger.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' }
    ],
    bored: [
      { name: 'Dance Classes', type: 'Aerobic', reason: 'Dance classes introduce new routines and keep workouts exciting.', gif: 'https://media.giphy.com/media/3o7TKyPpWvFrpwua2I/giphy.gif' },
      { name: 'Adventure Sports', type: 'Exploration', reason: 'Trying out adventure sports can combat boredom and add thrill to workouts.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' },
      { name: 'Obstacle Courses', type: 'Challenge', reason: 'Obstacle courses offer variety and challenge to prevent boredom.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' }
    ],
    fearful: [
      { name: 'Yoga', type: 'Mind-Body', reason: 'Yoga helps calm the mind and reduces fear through deep breathing and relaxation.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Meditation', type: 'Mindfulness', reason: 'Meditation helps manage fear by promoting mindfulness and present moment awareness.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' },
      { name: 'Walking', type: 'Low-Intensity', reason: 'Walking, especially in nature, can help reduce feelings of fear.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' }
    ],
    jealous: [
      { name: 'Running', type: 'Cardio', reason: 'Running can help clear the mind and reduce feelings of jealousy.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' },
      { name: 'Strength Training', type: 'Muscle Building', reason: 'Strength training boosts self-esteem and can help overcome jealousy.', gif: 'https://media.giphy.com/media/3o7TKFe8xHMzUoNqg0/giphy.gif' },
      { name: 'Group Fitness Classes', type: 'Community', reason: 'Joining group classes can foster social connections and diminish jealousy.', gif: 'https://media.giphy.com/media/3o7TKSha51ATTx9KzC/giphy.gif' }
    ],
    guilty: [
      { name: 'Swimming', type: 'Cardio', reason: 'Swimming can help soothe the mind and alleviate feelings of guilt.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Yoga', type: 'Mind-Body', reason: 'Yoga promotes forgiveness and self-compassion, helping to reduce guilt.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Cycling', type: 'Endurance', reason: 'Cycling provides a rhythmic and calming workout to process feelings of guilt.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' }
    ],
    embarrassed: [
      { name: 'Pilates', type: 'Core Strength', reason: 'Pilates builds confidence through controlled movements and body awareness.', gif: 'https://media.giphy.com/media/3o7TKSjRrfIPjeiVyE/giphy.gif' },
      { name: 'Walking', type: 'Low-Intensity', reason: 'Walking allows for introspection and can help process feelings of embarrassment.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' },
      { name: 'Yoga', type: 'Mind-Body', reason: 'Yoga helps build self-acceptance and reduce feelings of embarrassment.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' }
    ],
    disappointed: [
      { name: 'Jogging', type: 'Cardio', reason: 'Jogging helps lift the mood and overcome feelings of disappointment.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' },
      { name: 'Strength Training', type: 'Muscle Building', reason: 'Strength training boosts mood and confidence, helping to counter disappointment.', gif: 'https://media.giphy.com/media/3o7TKFe8xHMzUoNqg0/giphy.gif' },
      { name: 'Mindfulness Meditation', type: 'Relaxation', reason: 'Mindfulness meditation promotes acceptance and can alleviate disappointment.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' }
    ],
    ashamed: [
      { name: 'Yoga', type: 'Mind-Body', reason: 'Yoga promotes self-compassion and acceptance, helping to reduce shame.', gif: 'https://media.giphy.com/media/3o7TKyxl5FCj2bzfWM/giphy.gif' },
      { name: 'Meditation', type: 'Mindfulness', reason: 'Meditation helps process and release feelings of shame.', gif: 'https://media.giphy.com/media/3o7TKNcbfKa8f2Mxkk/giphy.gif' },
      { name: 'Walking', type: 'Low-Intensity', reason: 'Walking allows for reflection and can help reduce feelings of shame.', gif: 'https://media.giphy.com/media/3o7TKP9ln2Dr6ze6f6/giphy.gif' }
    ],
    frustrated: [
      { name: 'High-Intensity Interval Training (HIIT)', type: 'High-Intensity', reason: 'HIIT workouts channel frustration into intense physical activity.', gif: 'https://media.giphy.com/media/3o7TKRnEgVAWNWlOgg/giphy.gif' },
      { name: 'Kickboxing', type: 'Cardio and Strength', reason: 'Kickboxing is an excellent way to release frustration and pent-up energy.', gif: 'https://media.giphy.com/media/3o7TKuAfCHifvPdcxG/giphy.gif' },
      { name: 'Running', type: 'Endurance', reason: 'Running helps clear the mind and reduce feelings of frustration.', gif: 'https://media.giphy.com/media/3o7TKKxsoVEWJ5wyQg/giphy.gif' }
    ],
    // ... other moods from the original code ...
  };

  useEffect(() => {
    if (mood) {
      const recommendedExercises = exerciseList[mood.toLowerCase()] || [];
      setExercises(recommendedExercises);
    }
  }, [mood]);

  const defaultExercises = [
    { name: 'Stretching', type: 'Flexibility', reason: 'Improves flexibility and reduces muscle tension.', gif: 'https://media.giphy.com/media/xcSmKl0p7IEnkkxbgp/giphy.gif?cid=790b7611fov1k1krab431m9ekoer0x7v6szh7vk1u5kkxyzq&ep=v1_gifs_search&rid=giphy.gif&ct=g' },
    { name: 'Brisk Walking', type: 'Cardio', reason: 'Easy to start and great for overall health.', gif: 'https://media.giphy.com/media/madDsd98Bt7X6fwAJ7/giphy.gif?cid=790b7611jb8l49ohvchsd8sp9ztn6krnj1iptemnh752htet&ep=v1_gifs_search&rid=giphy.gif&ct=g' },
    { name: 'Bodyweight Exercises', type: 'Strength', reason: 'No equipment needed, can be done anywhere.', gif: 'https://media.giphy.com/media/3NwOzakbqzOrsfMKBC/giphy.gif?cid=790b7611nyeybt3flu5f6ams27aqgpxoea5vz53lj9u13ij6&ep=v1_gifs_search&rid=giphy.gif&ct=g' },
  ];

  return (
    <div className='flex min-h-screen bg-gray-100'>
             
             <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
        <div className="p-6">
          <nav>
            <ul className="space-y-2">
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
              </button></li>
              <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
              <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
              </button></li>
            </ul>
          </nav>
        </div>
        
      </div>
      
      {/* Main content */}
      <div className="container mx-auto max-w-[900px] overflow-y-auto md:h-[560px] px-4 py-6">
        <h1 className="mb-4 text-3xl font-bold">Exercise Recommendations</h1>
        
        <div className="mb-8">
          <input
            type="text"
            value={mood}
            onChange={(e) => setMood(e.target.value)}
            placeholder="How are you feeling today?"
            className="w-full px-4 py-2 text-lg border-2 border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition duration-300"
          />
        </div>

        {exercises.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {exercises.map((exercise, index) => (
              <ExerciseCard key={index} exercise={exercise} />
            ))}
          </div>
        ) : mood ? (
          <div className="text-center py-8">
            <p className="text-xl text-gray-600 mb-4">We couldn't find exercises for that mood.</p>
            <p className="text-lg text-gray-500">
              Help us improve! <Link to="/contact" className="text-blue-500 hover:underline">Contact us</Link> to suggest exercises for this mood.
            </p>
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Popular Exercises</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {defaultExercises.map((exercise, index) => (
                <ExerciseCard key={index} exercise={exercise} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ExerciseCard = ({ exercise }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden transition duration-300 transform hover:scale-105">
    <img src={exercise.gif} alt={exercise.name} className="w-full h-48 object-cover" />
    <div className="p-6">
      <h3 className="text-xl font-semibold text-gray-800 mb-2">{exercise.name}</h3>
      <p className="text-sm text-gray-600 mb-4">Type: {exercise.type}</p>
      <p className="text-gray-700">{exercise.reason}</p>
    </div>
  </div>
);

export default Exercise;