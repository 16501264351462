import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaUserMd, FaRobot, FaUsers, FaBook, FaGamepad, FaBlog, FaQuoteRight } from 'react-icons/fa';

const FeatureCard = ({ icon, title, description, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2"
    >
      <div className="flex items-center mb-4">
        {icon}
        <h2 className="ml-4 text-2xl font-semibold text-gray-800">{title}</h2>
      </div>
      <p className="mb-6 text-lg text-gray-600">{description}</p>
      <Link to='/login'>
        <button className="bg-[#59844F] text-white py-2 px-6 rounded-full font-bold hover:bg-[#2c3a20] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#59844F]">
          Explore
        </button>
      </Link>
    </motion.div>
  );
};

const Features = () => {
  const features = [
    {
      icon: <FaUserMd className="text-4xl text-[#0A8754]" />,
      title: 'Professional Support',
      description: 'Get personalized guidance from experienced mental health professionals. From individual counseling to career coaching, we\'ve got you covered.',
    },
    {
      icon: <FaRobot className="text-4xl text-[#0A8754]" />,
      title: 'SereniAI Companion',
      description: 'Meet your AI-powered confidant, offering tailored support and a safe space for open conversations, just like a trusted friend.',
    },
    {
      icon: <FaUsers className="text-4xl text-[#0A8754]" />,
      title: 'Supportive Community',
      description: 'Connect with like-minded individuals, share experiences, and grow together in our nurturing community.',
    },
    {
      icon: <FaBook className="text-4xl text-[#0A8754]" />,
      title: 'Personal Journal',
      description: 'Embark on a journey of self-discovery with our personalized journal, designed to help you reflect, set goals, and track your progress.',
    },
    {
      icon: <FaGamepad className="text-4xl text-[#0A8754]" />,
      title: 'Mood-Boosting Activities',
      description: 'Engage in a variety of games, sounds, movies, and exercises crafted to uplift your spirits and enrich your well-being.',
    },
    {
      icon: <FaBlog className="text-4xl text-[#0A8754]" />,
      title: 'Inspiring Blog Posts',
      description: 'Read and create uplifting blogs that keep you engaged and motivated, helping you stay focused on positivity.',
    },
    {
      icon: <FaQuoteRight className="text-4xl text-[#0A8754]" />,
      title: 'Daily Inspiration',
      description: 'Start each day with motivational quotes and reminders to keep you energized and focused on your personal growth.',
    },
  ];

  return (
    <div className="bg-gradient-to-b from-[#f0f4f0] to-[#e6efe6] py-8">
      <div className="container mx-auto px-4">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-12 text-4xl font-bold text-center text-gray-800"
        >
          Our Key <span className='text-[#0A8754]'>Features</span>
        </motion.h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;