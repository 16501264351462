import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faWind, faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from "../components/AuthContext ";
import axiosInstance from '../components/axiosInstance';
import SereniMindlogoMain from '../images/SereniMindLogoMain.png';

const NavbarLogged = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [isBreathingOpen, setIsBreathingOpen] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const [breathingPhase, setBreathingPhase] = useState('inhale');
  const [progress, setProgress] = useState(0);
  const [cyclesCompleted, setCyclesCompleted] = useState(0);
  const { authAxios, logoutUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get('https://serenimindbackend.onrender.com/api/user-info-dashboard/', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { username } = response.data;
        setUsername(username);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    let timer;
    if (isBreathingOpen && !showIntroduction) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            if (breathingPhase === 'inhale') {
              setBreathingPhase('hold');
            } else if (breathingPhase === 'hold') {
              setBreathingPhase('exhale');
            } else {
              setBreathingPhase('inhale');
              setCyclesCompleted((prev) => prev + 1);
            }
            return 0;
          }
          return prevProgress + (100 / 60); // 60 updates per 6 seconds
        });
      }, 100); // Update every 100ms for smoother animation
    }
    return () => clearInterval(timer);
  }, [isBreathingOpen, breathingPhase, showIntroduction]);

  const handleLogout = async () => {
    try {
      localStorage.clear();
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  const toggleBreathingExercise = () => {
    setIsBreathingOpen(!isBreathingOpen);
    if (!isBreathingOpen) {
      setShowIntroduction(true);
      setBreathingPhase('inhale');
      setProgress(0);
      setCyclesCompleted(0);
    }
  };

  const startExercise = () => {
    setShowIntroduction(false);
  };

  const getCircleSize = () => {
    const baseSize = 150; // base size in pixels
    const maxExpansion = 100; // maximum expansion in pixels
    
    switch (breathingPhase) {
      case 'inhale':
        return baseSize + (maxExpansion * progress / 100);
      case 'hold':
        return baseSize + maxExpansion;
      case 'exhale':
        return baseSize + maxExpansion - (maxExpansion * progress / 100);
      default:
        return baseSize;
    }
  };


  return (
    <>
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <button
                onClick={toggleSideNav}
                className="inline-flex items-center justify-center p-2 rounded-md text-[#000000] text-2xl hover:text-gray-500 md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
              >
                <span className="sr-only">Open side menu</span>
                <FontAwesomeIcon icon={faBars} className="h-6 w-6" />
              </button>
              <Link to="/dashboardmain" className="ml-4 flex-shrink-0 smallscreensidenav">
                <img src={SereniMindlogoMain} alt="SereniMind Logo" className="w-16 md:w-20" />
              </Link>
            </div>
            <p className="text-green-700 font-semibold text-center flex justify-center items-center md:hidden">Hi, {username}</p>
            <div className="flex justify-center items-center">
              <p className="text-green-700 font-semibold smallscreensidenav">Hi, {username}</p>
              <button
                onClick={toggleBreathingExercise}
                className="ml-4 p-2 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <FontAwesomeIcon icon={faWind} className="h-6 w-6" />
                <span className="sr-only">Breathing Exercise</span>
              </button>
              <button
                onClick={handleLogout}
                className="ml-4 p-2 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <span className="sr-only">Logout</span>
                <FontAwesomeIcon icon={faSignOutAlt} className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Side Navigation */}
      <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-white shadow-xl transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className="px-10 pt-6 h-full overflow-y-auto">
          <button onClick={toggleSideNav} className="absolute top-4 right-4 text-[#000000] hover:text-gray-700">
            <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
          </button>
          <ul className="text-[14px] space-y-11">
            <Link to="/dashboardmain" className="ml-4 flex-shrink-0" onClick={toggleSideNav}>
              <img src={SereniMindlogoMain} alt="SereniMind Logo" className="w-16" />
            </Link>
            <li><Link to="/sereniai" className="flex items-center font-semibold" onClick={toggleSideNav}>🤖 SereniAI</Link></li>
            <li><Link to="/book-professionals" className="flex items-center font-semibold" onClick={toggleSideNav}>📚 Book our Professionals</Link></li>
            <li><Link to="/activity-lounge" className="flex items-center font-semibold" onClick={toggleSideNav}>🎮 Activity Lounge</Link></li>
            <li><Link to="/blog" className="flex items-center font-semibold" onClick={toggleSideNav}>📝 Blog</Link></li>
            <li><Link to="/journal" className="flex items-center font-semibold" onClick={toggleSideNav}>✍🏼 Your Journal</Link></li>
            <li><Link to="/community" className="flex items-center font-semibold" onClick={toggleSideNav}>👥 Community</Link></li>
            <li className='pt-8 pb-4'>
              <Link to="/settings" className="flex items-center" onClick={toggleSideNav}>
                <FontAwesomeIcon icon={faCog} className="h-6 w-6" />
              </Link>
            </li>
          </ul>
        </div>
      </div>

     {/* Breathing Exercise Modal */}
     {isBreathingOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
            {showIntroduction ? (
              <div>
                <h2 className="text-2xl font-bold mb-4 text-green-700">Breathing Exercise</h2>
                <p className="mb-4">This exercise is designed to help you relax and reduce anxiety. It uses a 6-6-6 breathing technique:</p>
                <ul className="list-disc list-inside mb-4">
                  <li>Inhale slowly for 6 seconds</li>
                  <li>Hold your breath for 6 seconds</li>
                  <li>Exhale slowly for 6 seconds</li>
                </ul>
                <p className="mb-4">Follow the expanding and contracting circle to guide your breathing.</p>
                <div className='space-y-4'>
                <button
                  onClick={startExercise}
                  className="w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                >
                  Start Exercise
                </button>
                <button
                    onClick={toggleBreathingExercise}
                    className="w-full px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                  >
                    Close
                  </button>
                  </div>
              </div>
            ) : (
              <>
                <h2 className="text-2xl font-bold mb-4 text-green-700">Breathing Exercise</h2>
                <div className="flex flex-col items-center justify-center">
                  <div 
                    className="rounded-full flex items-center justify-center text-white text-2xl font-bold transition-all duration-100 ease-in-out"
                    style={{
                      width: `${getCircleSize()}px`,
                      height: `${getCircleSize()}px`,
                      backgroundColor: '#38A169'
                    }}
                  >
                    {breathingPhase.charAt(0).toUpperCase() + breathingPhase.slice(1)}
                  </div>
                  <p className="mt-4 text-xl text-green-700">
                    {Math.ceil((100 - progress) / (100 / 6))}
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    {breathingPhase === 'inhale'
                      ? 'Breathe in slowly'
                      : breathingPhase === 'hold'
                      ? 'Hold your breath'
                      : 'Exhale slowly'}
                  </p>
                  <p className="mt-4 text-sm font-semibold text-green-600">
                    Cycles completed: {cyclesCompleted}
                  </p>
                </div>
                <div className="mt-6 flex justify-between">
                  <button
                    onClick={toggleBreathingExercise}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                  >
                    Close
                  </button>
                  <button
                    onClick={() => {
                      setBreathingPhase('inhale');
                      setProgress(0);
                      setCyclesCompleted(0);
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                  >
                    Restart
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}



      {/* Overlay to close side nav when clicking outside */}
      {isSideNavOpen && (
        <div 
          className="fixed inset-0 z-40 bg-black bg-opacity-50"
          onClick={toggleSideNav}
        ></div>
      )}
    </>
  );
}

export default NavbarLogged;