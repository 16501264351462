import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedRefreshToken = localStorage.getItem("refreshToken");

    if (storedToken && storedRefreshToken) {
      setToken(storedToken);
      setRefreshToken(storedRefreshToken);
    }

    setLoading(false);
  }, []);

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setToken(null);
    setRefreshToken(null);
  };

  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(
        "https://serenimindbackend.onrender.com/api/token/refresh/",
        { refresh: refreshToken },
        { headers: { "Content-Type": "application/json" } }
      );

      const newAccessToken = response.data.access;
      setToken(newAccessToken);
      localStorage.setItem("token", newAccessToken);
      console.log("Token refreshed!");
    } catch (error) {
      console.error("Error refreshing access token:", error);
      logoutUser();
      console.log("User logged out.");
    }
  };

  useEffect(() => {
    if (token) {
      console.log("Token set:", token);
    } else {
      console.log("Token cleared");
    }
  }, [token]);

  const isAuthenticated = () => {
    return token !== null;
  };

  return (
    <AuthContext.Provider
      value={{ token, setToken, refreshToken, setRefreshToken, logoutUser, loading, isAuthenticated, refreshAccessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth };
