import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faShare, faEdit, faGear, faArrowRight, faTimes, faLightbulb, faPaintBrush, faBars,faRemove, faCheck, faPlus, faPalette, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../components/axiosInstance';
import { ClipLoader } from 'react-spinners'; // Install this with 'npm install react-spinners'
import jsPDF from 'jspdf';

const Journal = () => {
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);
  const [entries, setEntries] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [background_color, setbackground_color] = useState('#ffffff');
  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');
  const [user_id, setUserId] = useState('');
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const [deleteJournal, setdeleteJournal] = useState(false)
  
  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };
const toggleActivities = () => {
  setIsActivitiesOpen(!isActivitiesOpen);
};
  const getCurrentDateTime = () => {
    const date = new Date();
    return date.toLocaleString();
  };

  const getUserId = async () => {
    try {
      const response = await axiosInstance.get(`https://serenimindbackend.onrender.com/api/user-info-dashboard/`);
      const { user_id } = response.data;
  
      setUserId(user_id); // Assuming you want to set the user_id state here
      return user_id; // Return the user_id value
    } catch (error) {
      console.error('Error fetching user ID:', error);
      return null; // Return null or handle the error appropriately
    }
  };

  useEffect(() => {
    // Fetch journal entries from the backend when the component mounts
    fetchJournalEntries();
  }, []);

  const fetchJournalEntries = async () => {
    try {
      const response = await axiosInstance.get('https://serenimindbackend.onrender.com/api/journal-entries/');
      setEntries(response.data);
    } catch (error) {
      console.error('Error fetching journal entries:', error);
      // Handle token expiration and refresh if needed
      if (error.response?.status === 401) {
        // Refresh the access token
        const refreshResponse = await axiosInstance.post('https://serenimindbackend.onrender.com/api/token/refresh/', { refresh: refreshToken });
        const newAccessToken = refreshResponse.data.access;
        localStorage.setItem('access_token', newAccessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        // Retry the request with the new access token
        fetchJournalEntries();
      }
    }
  };

  const handleSaveEntry = async () => {
    setLoading(true); // Set loading to true when saving starts
  
    const userId = await getUserId();
  
    const entryData = {
      title,
      content,
      timestamp: getCurrentDateTime(),
      background_color,
      user: userId,
    };
  
    try {
      let response;
      if (editIndex !== -1) {
        // Update existing entry
        response = await axiosInstance.put(`https://serenimindbackend.onrender.com/api/journal-entries/${entries[editIndex].id}/`, entryData);
        const updatedEntries = [...entries];
        updatedEntries[editIndex] = response.data;
        setEntries(updatedEntries);
      } else {
        // Create new entry
        response = await axiosInstance.post('https://serenimindbackend.onrender.com/api/journal-entries/', entryData);
        setEntries([...entries, response.data]);
      }
      // Reset form after successful save
      setTitle('');
      setContent('');
      setbackground_color('#ffffff');
      setEditIndex(-1);
    } catch (error) {
      console.error('Error saving journal entry:', error);
      
      if (error.response?.status === 401) {
        // Refresh the access token
        try {
          const refreshResponse = await axiosInstance.post('https://serenimindbackend.onrender.com/api/token/refresh/', { refresh: refreshToken });
          const newAccessToken = refreshResponse.data.access;
          localStorage.setItem('access_token', newAccessToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          // Retry the request with the new access token
          await handleSaveEntry();
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
        }
      }
    } finally {
      setIsFormExpanded(false);
      setLoading(false); // Always stop loading after the operation
    }
  };
  
  const resetForm = () => {
    setTitle('');
    setContent('');
    setbackground_color('#ffffff');
    setEditIndex(-1);
    setIsFormExpanded(false);
  };

  const handleEditEntry = (index) => {
    setIsFormExpanded(true);
    const entry = entries[index];
    setTitle(entry.title);
    setContent(entry.content);
    setbackground_color(entry.background_color); // Set background color from existing entry
    setEditIndex(index);
    
  };

  const handleDeleteEntry = async (id) => {
    setLoading(true);
    try {
      const access_token = localStorage.getItem('accessToken');

      const response = await axiosInstance.delete(`https://serenimindbackend.onrender.com/api/journal-entries/${id}/`, {
        headers: {
          'Authorization': `Token ${access_token}`
        }        
      });
  
      if (response.status === 204) {
        const updatedEntries = entries.filter(entry => entry.id !== id);
        setEntries(updatedEntries);
      } else {
        throw new Error('Failed to delete journal entry');
      }
    } catch (error) {
      console.error('Error deleting journal entry:', error);
      if (error.response?.status === 401) {
        
        const errorData = error.response?.data;
        if (errorData?.error === 'User authentication required.') {
          console.error('User authentication required. Redirecting to login page...');
          // Redirect the user to the login page or perform appropriate actions
        } else {
          console.error('Token error:', errorData?.error);
          // Handle other types of 401 errors, such as token expiration and refresh
          const refreshResponse = await axiosInstance.post('https://serenimindbackend.onrender.com/api/token/refresh/', { refresh: refreshToken });
          const newAccessToken = refreshResponse.data.access;
          localStorage.setItem('access_token', newAccessToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          // Retry the request with the new access token
          handleDeleteEntry(id);
        }
      } else {
        // Handle other types of errors
        console.error('Other error:', error.message);
      }
    }finally {
      setLoading(false); // Always stop loading after the operation
    }
  };
  
  
  const handleShareEntry = (index) => {
    const entry = entries[index];
    const doc = new jsPDF();

    // Set the title
    doc.setFontSize(16);
    doc.text(entry.title, 10, 10);

    // Set the content with text wrapping
    doc.setFontSize(12);
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxWidth = pageWidth - margin * 2; // Calculate the maximum width for text
    doc.text(entry.content, margin, 20, { maxWidth: maxWidth });

    // Download the generated PDF
    doc.save(`${entry.title}.pdf`);
};

const WelcomeModal = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
      <h2 className="text-2xl font-bold mb-4">Welcome to Your Journal!</h2>
      <p className="mb-4">Here's how to get started:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Click "Take a note..." to start writing</li>
        <li>Use the color picker to set your entry's mood</li>
        <li>Save your thoughts and reflections</li>
        <li>Edit or share your entries anytime</li>
      </ul>
      <button
        onClick={() => setShowWelcomeModal(false)}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
      >
        Get Started
      </button>
    </div>
  </div>
);


  return (
    <div className='flex min-h-screen bg-gray-100'>
             
    <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>

      {/* Main Content */}
      <div className="container mx-auto p-4 max-w-6xl w-[900px] overflow-y-auto md:h-[560px]">
      {showWelcomeModal && <WelcomeModal />}
      <div className="mb-8">
        <div 
          className={`bg-white rounded-lg shadow-md transition-all duration-300 ease-in-out ${isFormExpanded ? 'p-6' : 'p-4'}`}
          style={{ minHeight: isFormExpanded ? '200px' : '60px' }}
        >
          {!isFormExpanded ? (
            <div 
              className="flex items-center cursor-pointer"
              onClick={() => setIsFormExpanded(true)}
            >
              <FontAwesomeIcon icon={faPlus} className="text-gray-400 mr-4" />
              <span className="text-gray-500">Take a note...</span>
            </div>
          ) : (
            <>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
                className="w-full p-2 mb-2 text-lg font-semibold focus:outline-none"
                required
              />
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Take a note..."
                className="w-full p-2 mb-4 resize-none focus:outline-none"
                rows="3"
                required
              />
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <button className="text-gray-500 hover:text-gray-700 transition duration-200">
                    <FontAwesomeIcon icon={faPalette} />
                  </button>
                  <input
                    type="color"
                    value={background_color}
                    onChange={(e) => setbackground_color(e.target.value)}
                    className="w-6 h-6 border-3 cursor-pointer"
                  />
                </div>
                <div className="space-x-2">
                  <button 
                    onClick={resetForm}
                    className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md transition duration-200"
                  >
                    Close
                  </button>
                  <button 
                    onClick={handleSaveEntry}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
                  >
                      {loading ? 'Saving...' : editIndex !== -1 ? 'Update' : 'Save'}
                  </button>
                  {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-green bg-opacity-75 z-50">
          <ClipLoader size={150} color={"#59844F"} />
        </div>
      )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {entries.length === 0 ? (
          <div className="text-center py-10">
            <FontAwesomeIcon icon={faLightbulb} className="text-yellow-400 text-5xl mb-4" />
            <h3 className="text-xl font-semibold mb-2">Your journal is empty</h3>
            <p className="text-gray-600">Start by adding your first entry above!</p>
          </div>
        ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {entries.map((entry, index) => (
          <div
            key={entry.id}
            className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg"
            style={{ backgroundColor: entry.background_color }}
          >
            <div>

            </div>
            <div className="bg-black bg-opacity-5 p-2 flex justify-end space-x-4 opacity-100">
              <button 
                onClick={() => handleEditEntry(index)}
                className="text-gray-600 hover:text-blue-500 transition duration-200"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button 
                onClick={() => handleShareEntry(index)}
                className="text-gray-600 hover:text-green-500 transition duration-200"
            >
                <FontAwesomeIcon icon={faShare} />
            </button>
              <button 
                onClick={() => handleDeleteEntry(entry.id)}
                className="text-gray-600 hover:text-red-500 transition duration-200"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{entry.title}</h3>
              <p className="text-gray-700 mb-4 whitespace-pre-wrap">{entry.content}</p>
              <p className="text-xs text-gray-500">
                {new Date(entry.timestamp).toLocaleString()}
              </p>
            </div>
          </div>
        ))}
      </div>
        )}
    </div>
    </div>
  );
};

export default Journal;
