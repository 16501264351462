import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import NotFoundImage from '../images/Sponguethink.png'

const NoPageFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
      <img src={NotFoundImage} alt="404 Not Found" className="w-64 h-64 mb-8" />

      <h1 className="mb-4 text-4xl font-bold text-red-500">Oops! Page Not Found</h1>

      <p className="mb-8 text-lg text-gray-700">The page you are looking for might have been removed or doesn't exist.</p>

      <Link to="/" className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700">
        Go Home
      </Link>
    </div>
  );
};

export default NoPageFound;
