import React from 'react';

// Sample data for communities
const communitiesData = [
    { 
      id: 1, 
      name: 'Anxiety Allies', 
      description: 'Join a supportive community of individuals navigating the complexities of anxiety disorders together.',
      categories: ['nervous', 'worried', 'stress', 'fear', 'calm', 'mental health', 'support','anxiety']
    },
    { 
      id: 2, 
      name: 'Bipolar Junction', 
      description: 'Connect with others experiencing bipolar disorder and share insights, coping strategies, and encouragement.',
      categories: ['mood swings', 'energetic', 'depressed', 'manic', 'balance', 'mental health', 'support', 'angry', 'annoyed', 'pissed']
    },
    { 
      id: 3, 
      name: 'OCD Oasis', 
      description: 'Find solace and understanding in a community dedicated to supporting those living with Obsessive-Compulsive Disorder.',
      categories: ['obsessive', 'compulsive', 'rituals', 'intrusive thoughts', 'order', 'mental health', 'support']
    },
    { 
      id: 4, 
      name: 'Schizophrenia Sanctuary', 
      description: 'Engage with peers and professionals in a safe space designed for individuals affected by schizophrenia.',
      categories: ['hallucinations', 'delusions', 'disorganized', 'catatonic', 'paranoid', 'mental health', 'support']
    },
    { 
      id: 5, 
      name: 'Eating Disorder Empowerment', 
      description: 'Foster recovery and empowerment in a community committed to healing from eating disorders of all types.',
      categories: ['anorexia', 'bulimia', 'binge eating', 'body image', 'recovery', 'mental health', 'support']
    },
    { 
      id: 6, 
      name: 'Addiction Recovery Alliance', 
      description: 'Discover camaraderie and guidance on the journey to sobriety and long-term recovery from addiction.',
      categories: ['substance abuse', 'recovery', 'sober', 'relapse', 'support', 'mental health', 'counseling']
    },
    { 
      id: 7, 
      name: 'Self-Harm Support Circle', 
      description: 'Break the cycle of isolation and shame by connecting with others who understand and empathize with struggles related to self-harm.',
      categories: ['cutting', 'burning', 'self-injury', 'coping', 'support', 'mental health', 'therapy']
    },
    { 
      id: 8, 
      name: 'Body Positivity Collective', 
      description: 'Embrace body diversity and challenge societal norms in a community dedicated to promoting self-love and acceptance.',
      categories: ['body image', 'self-love', 'acceptance', 'empowerment', 'beauty', 'mental health', 'support']
    },
    { 
      id: 9, 
      name: 'LGBTQ+ Pride Place', 
      description: 'Celebrate diversity and resilience within the LGBTQ+ community while finding solidarity and acceptance.',
      categories: ['pride', 'community', 'diversity', 'acceptance', 'identity', 'mental health', 'support', 'lgbtq']
    },
    { 
      id: 10, 
      name: 'Chronic Illness Warriors', 
      description: 'Unite with fellow warriors facing the challenges of chronic illness, sharing experiences and coping strategies.',
      categories: ['chronic pain', 'invisible illness', 'coping', 'support', 'warriors', 'mental health', 'community', 'sick']
    },
    { 
      id: 11, 
      name: 'Grief Support Network', 
      description: 'Receive comfort and guidance from those who have experienced loss, navigating the journey of grief together.',
      categories: ['loss', 'bereavement', 'mourning', 'support', 'healing', 'mental health', 'community', 'sad']
    },
    { 
      id: 12, 
      name: 'Parenting with Mental Health', 
      description: 'Share the joys and struggles of parenting while managing mental health challenges in a supportive environment.',
      categories: ['parenting', 'children', 'family', 'support', 'mental health', 'community', 'children','father',' mother']
    },
    { 
      id: 13, 
      name: 'Student Mental Health Hub', 
      description: 'Connect with fellow students to discuss the unique stressors and mental health concerns of academic life.',
      categories: ['stress', 'exams', 'school', 'support', 'mental health', 'community', 'college', 'student', 'depression', 'sad', 'ptsd','stressed','frustrated']
    },
    { 
      id: 14, 
      name: 'Mindfulness Masterminds', 
      description: 'Cultivate mindfulness and inner peace through shared meditation practices and mindfulness techniques.',
      categories: ['mindfulness', 'meditation', 'relaxation', 'peace', 'calm', 'mental health', 'community']
    },
    { 
      id: 15, 
      name: 'Creative Expression Corner', 
      description: 'Explore the healing power of creativity and self-expression in a space dedicated to artistic exploration and inspiration.',
      categories: ['art', 'creative', 'expression', 'creativity', 'inspiration', 'mental health', 'community']
    },
    { 
      id: 16, 
      name: 'Trauma Recovery Tribe', 
      description: 'Heal from past trauma and build resilience alongside fellow survivors in a supportive and understanding community.',
      categories: ['trauma', 'healing', 'recovery', 'resilience', 'support', 'mental health', 'community', 'ptsd']
    },
    { 
      id: 17, 
      name: 'Neurodiversity Nook', 
      description: 'Embrace and celebrate the diversity of neurological experiences, fostering understanding and acceptance.',
      categories: ['neurodiversity', 'diversity', 'autism', 'asperger', 'ADHD', 'mental health', 'community']
    },
    { 
      id: 18, 
      name: 'Veteran Valor Village', 
      description: 'Honor and support veterans navigating the challenges of post-military life, providing camaraderie and resources.',
      categories: ['veterans', 'military', 'PTSD', 'support', 'mental health', 'community', 'counseling']
    },
    { 
      id: 19, 
      name: 'Caregiver Compassion Collective', 
      description: 'Find understanding and support as a caregiver, sharing experiences and strategies for self-care and support.',
      categories: ['caregiving', 'support', 'mental health', 'community', 'family', 'self-care', 'care']
    },
    { 
      id: 20, 
      name: 'Mind-Body Wellness Wonderland', 
      description: 'Explore holistic approaches to wellness, integrating mental, emotional, and physical well-being in a nurturing community environment.',
      categories: ['wellness', 'holistic', 'health', 'mind-body', 'balance', 'mental health', 'community']
    },
    { 
        id: 21, 
        name: 'Laugh Therapy League', 
        description: 'Join our community of laughter enthusiasts and discover the healing power of humor in managing stress and promoting mental well-being.',
        categories: ['laughter yoga', 'comedy', 'humor', 'joy', 'stress relief', 'mental health', 'support', 'well-being', 'fun', 'happiness', 'positive vibes', 'laughter', 'community', 'coping', 'therapy']
      },
      { 
        id: 22, 
        name: 'Happy Dance Haven', 
        description: 'Dance your way to happiness and connect with others who understand the therapeutic benefits of movement and self-expression.',
        categories: ['dance', 'joy', 'happiness', 'movement', 'expression', 'mental health', 'support', 'well-being', 'community', 'fun', 'self-care', 'therapy', 'fitness', 'positivity', 'coping']
      },
      { 
        id: 23, 
        name: 'Artistic Expression Empire', 
        description: 'Unleash your creativity and find solace in a community that celebrates artistic expression as a tool for healing and self-discovery.',
        categories: ['art', 'creativity', 'expression', 'therapy', 'healing', 'mental health', 'support', 'well-being', 'community', 'fun', 'self-discovery', 'inspiration', 'coping', 'crafts', 'imagination']
      },
      { 
        id: 24, 
        name: 'Nature Nurturers Network', 
        description: 'Connect with fellow nature lovers and explore the restorative power of spending time outdoors for your mental health and well-being.',
        categories: ['nature', 'outdoors', 'hiking', 'environment', 'wellness', 'mental health', 'support', 'well-being', 'community', 'fun', 'relaxation', 'adventure', 'self-care', 'exploration', 'coping']
      },
      { 
        id: 25, 
        name: 'Mindful Meditation Mecca', 
        description: 'Embark on a journey of self-discovery and inner peace through guided meditation practices and mindfulness techniques.',
        categories: ['meditation', 'mindfulness', 'calm', 'peace', 'relaxation', 'mental health', 'support', 'well-being', 'community', 'fun', 'self-care', 'therapy', 'stress relief', 'spirituality', 'coping']
      },
      { 
        id: 26, 
        name: 'Adventure Seekers Sanctuary', 
        description: 'Join a community of thrill-seekers and outdoor enthusiasts who understand the importance of adrenaline and adventure for mental wellness.',
        categories: ['adventure', 'travel', 'exploration', 'outdoors', 'excitement', 'mental health', 'support', 'well-being', 'community', 'fun', 'adrenaline', 'challenge', 'coping', 'nature', 'experience']
      },
      { 
        id: 27, 
        name: 'Music Mavens Oasis', 
        description: 'Discover the therapeutic power of music and connect with fellow music lovers in a supportive and harmonious community.',
        categories: ['music', 'therapy', 'healing', 'mental health', 'support', 'well-being', 'community', 'fun', 'self-expression', 'relaxation', 'stress relief', 'joy', 'coping', 'connection', 'melody']
      },
      { 
        id: 28, 
        name: 'Gamers Guild Gathering', 
        description: 'Join our virtual world and bond with fellow gamers who understand the positive impact gaming can have on mental health and social connection.',
        categories: ['gaming', 'video games', 'online', 'community', 'fun', 'social', 'mental health', 'support', 'well-being', 'friendship', 'coping', 'adventure', 'strategy', 'escape', 'challenge']
      },
      { 
        id: 29, 
        name: 'Pet Pals Paradise', 
        description: 'Connect with fellow pet enthusiasts and share the joys of pet ownership while exploring the therapeutic benefits of animal companionship.',
        categories: ['pets', 'animals', 'dogs', 'cats', 'companionship', 'mental health', 'support', 'well-being', 'community', 'fun', 'love', 'caring', 'friendship', 'coping', 'happiness']
      },
      { 
        id: 30, 
        name: 'Yoga Yurt Yoga', 
        description: 'Find balance and serenity in a community dedicated to the practice of yoga, mindfulness, and holistic wellness.',
        categories: ['yoga', 'mindfulness', 'balance', 'serenity', 'mental health', 'support', 'well-being', 'community', 'fun', 'self-care', 'therapy', 'peace', 'stretching', 'meditation', 'coping']
      }
  ];
  
const CommunityDataPage = () => {
  // You can fetch communities data from an API here if needed
  return communitiesData;
};

export default CommunityDataPage;
