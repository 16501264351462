import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Verification = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const firstName = queryParams.get('first_name') || '';
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const inputRefs = useRef([]);

  // Fetch and store CSRF token when the component mounts
  useEffect(() => {
    const fetchCSRFToken = async () => {
      try {
        const response = await axios.get('https://serenimindbackend.onrender.com/api/csrf/');
        axios.defaults.headers.common['X-CSRFToken'] = response.data.csrf_token;
        console.log('CSRF token set:', response.data.csrf_token);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCSRFToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://serenimindbackend.onrender.com/api/verification/', {
        email,
        verificationCode: code.join(''),
      });

      if (response.data.message === 'Verification successful') {
        // Redirect to login page
        navigate('/login');
      } else {
        setError('Invalid verification code. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const sendVerificationEmail = async () => {
    try {
      const response = await axios.post('https://serenimindbackend.onrender.com/api/send-verification-email/', {
        email,
        firstName,
        verificationCode: code.join(''), // assuming code is the verification code
      });

      console.log('Verification email sent successfully', response.data);
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  };

  const handleChange = (e, index) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);

    // Move focus to next input if the current input has a value
    if (e.target.value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-md shadow-md">
        <h2 className="mb-6 text-2xl font-semibold">Verification</h2>
        {error && <div className="mb-4 text-red-500">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="code" className="block mb-2 text-sm font-bold text-gray-700">
              Enter the 6-digit verification code sent to your email
            </label>
            <div className="flex">
              {code.map((num, index) => (
                <input
                  key={index}
                  type="text"
                  id={`code-${index}`}
                  className="w-full p-2 mr-2 border rounded-md"
                  placeholder="0"
                  maxLength="1"
                  value={num}
                  onChange={(e) => handleChange(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  required
                />
              ))}
            </div>
          </div>
          <button
            type="submit"
            className="w-full p-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
          >
            Verify
          </button>
        </form>
        <button
          onClick={sendVerificationEmail}
          className="w-full p-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
        >
          Resend Verification Email
        </button>
      </div>
    </div>
  );
};

export default Verification;
