import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SereniMindlogoMain from '../images/SereniMindLogoMain.png';

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const isNowMobile = window.innerWidth < 768;
      setIsMobile(isNowMobile);
      if (!isNowMobile) setShowMenu(false); // Close menu when switching to desktop
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <nav className="px-4 py-4 md:px-[200px] bg-white shadow-md relative">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={SereniMindlogoMain} alt="SereniMind Logo" className="w-16 md:w-20" />
        </Link>
        
        <button className="text-[#4D4D4D] focus:outline-none md:hidden" onClick={toggleMenu}>
          <FontAwesomeIcon icon={showMenu ? faTimes : faBars} size="lg" />
        </button>

        <div
          className={`${
            isMobile ? (showMenu ? 'flex' : 'hidden') : 'flex'
          } flex-col md:flex-row items-start md:items-center absolute md:relative 
          top-full left-0 right-0 md:top-0 bg-white md:bg-transparent p-4 md:p-0 
          shadow-md md:shadow-none space-y-4 md:space-y-0 md:space-x-6 w-full md:w-auto z-50`}
        >
          {['/', '/features', '/blogs', '/contact', '/login'].map((path, idx) => (
            <NavItem key={idx} to={path} onClick={() => setShowMenu(false)}>
              {path === '/' ? 'Home' : path.slice(1).charAt(0).toUpperCase() + path.slice(2)}
            </NavItem>
          ))}
          <Link
            to="/signup"
            onClick={() => setShowMenu(false)}
            className="md:hidden bg-[#59844F] text-white px-4 py-2 rounded-full flex items-center justify-center w-full"
          >
            Get Started
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </Link>
        </div>

        <Link
          to="/signup"
          className="hidden md:flex bg-[#59844F] text-white px-4 py-2 rounded-full items-center"
        >
          Get Started
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Link>
      </div>
    </nav>
  );
};

const NavItem = ({ to, children, onClick }) => (
  <NavLink
    to={to}
    exact
    onClick={onClick}
    className="relative inline-block w-full md:w-auto transition-all duration-200 hover:text-[#59844F]
               hover:after:block hover:after:absolute hover:after:left-0 hover:after:w-full 
               hover:after:h-0.5 hover:after:bg-[#59844F] hover:after:content-['']"
    activeClassName="active"
  >
    {children}
  </NavLink>
);

export default NavBar;
