import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Search, Settings, X } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

const gamesList = [
  {
    id: 1,
    title: "Calm Waters",
    image: "https://i0.wp.com/rgmechanics.com/wp-content/uploads/2017/09/Calm-Waters-Free-Download-Full-PC-Game.jpg?fit=750%2C350&ssl=1",
    description: "A relaxing puzzle game perfect for unwinding.",
    mood: ["relaxed", "calm"],
    playLink: "https://example.com/calm-waters",
  },
  {
    id: 2,
    title: "Adrenaline Rush",
    image: "https://evoplay.games/wp-content/uploads/2024/02/adrenalinerush_1280x720.jpg",
    description: "An exciting racing game to boost your energy.",
    mood: ["excited", "energetic"],
    playLink: "https://example.com/adrenaline-rush",
  },
  {
    id: 3,
    title: "Mindful Maze",
    image: "https://m.media-amazon.com/images/I/51e-BbX-vmL._SR600%2C315_PIWhiteStrip%2CBottomLeft%2C0%2C35_SCLZZZZZZZ_FMpng_BG255%2C255%2C255.jpg",
    description: "A peaceful labyrinth game for meditation and focus.",
    mood: ["focused", "mindful"],
    playLink: "https://example.com/mindful-maze",
  },
  {
    id: 4,
    title: "Joyful Jumper",
    image: "https://www.emulatorpc.com/wp-content/uploads/2022/10/happy-jump-pc-full-version.jpg",
    description: "A cheerful platformer to lift your spirits.",
    mood: ["happy", "joyful"],
    playLink: "https://example.com/joyful-jumper",
  },
  {
    id: 5,
    title: "Serenity Valley",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiwAgwT7KmCCwTJg97kLCxA-jsQi8MYuldJg&s",
    description: "A peaceful farming simulation for tranquil moments.",
    mood: ["peaceful", "serene"],
    playLink: "https://example.com/serenity-valley",
  },
];

const Games = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const filteredGames = gamesList.filter(game =>
    game.mood.some(m => m.toLowerCase().includes(searchQuery.toLowerCase())) ||
    game.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='flex min-h-screen bg-gray-100'>
             
    <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden container mx-auto px-4 w-[900px] overflow-y-auto md:h-[560px]">
        
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="mb-8">
            <div className="max-w-xl mx-auto">
              <div className="flex items-center border-b border-gray-300 py-2">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="flex-1 appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  placeholder="Search games by mood or title..."
                />
                <Search className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>

          {filteredGames.length > 0 ? (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {filteredGames.map((game) => (
                <div key={game.id} className="bg-white overflow-hidden shadow-sm rounded-lg">
                  <img src={game.image} alt={game.title} className="w-full h-48 object-cover" />
                  <div className="p-6">
                    <h3 className="text-lg font-medium text-gray-900">{game.title}</h3>
                    <p className="mt-2 text-sm text-gray-500">{game.description}</p>
                    <div className="mt-4">
                      <a
                        href={game.playLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#59844F] hover:bg-[#4f7547] focus:outline-none"
                      >
                        Play Now
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8">
            <p className="text-xl text-gray-600 mb-4">We couldn't find games for that mood.</p>
            <p className="text-lg text-gray-500">
              Help us improve! <Link to="/contact" className="text-blue-500 hover:underline">Contact us</Link> to suggest games for this mood.
            </p>
          </div>
          )}
        </div>
      </main>
      </div>
    </div>
  );
};

export default Games;