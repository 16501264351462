import React from 'react';
import { Link } from 'react-router-dom';

const PasswordResetComplete = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-md shadow-md">
        <h2 className="mb-4 text-4xl font-bold text-center">Password Reset Complete</h2>
        <p className="mb-4 text-gray-700">Your password has been reset successfully.</p>
        <Link
          to="/login"
          className="bg-[# 0A8754] text-white py-2 px-4 rounded hover:bg-green-600"
        >
          Login
        </Link>
      </div>
    </div>
  );
};

export default PasswordResetComplete;