import React, { useState } from 'react';
import { ChevronDown, ChevronUp, X } from 'lucide-react';

const TermsAndConditions = ({ onClose }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (sectionId) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  const sections = [
    {
      id: 'acceptance',
      title: '1. Acceptance of Terms',
      content: 'By accessing and using SereniMind, you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using SereniMind\'s particular services, you shall be subject to any posted guidelines or rules applicable to such services, which may be posted and modified from time to time.'
    },
    {
      id: 'description',
      title: '2. Description of Service',
      content: 'SereniMind provides users with access to a rich collection of resources, including various communications tools, forums, and personalized content (collectively, "Service"). The Service is provided "as is." We assume no responsibility for the timeliness, deletion, mis-delivery, or failure to store any user communications or personalization settings.'
    },
    {
      id: 'conduct',
      title: '3. User Conduct',
      content: 'You are solely responsible for the content of your transmissions through the Service. Your use of the Service is subject to all applicable local, state, national and international laws and regulations. You agree: (1) to comply with US law regarding the transmission of technical data exported from the United States through the Service; (2) not to use the Service for illegal purposes; (3) not to interfere with or disrupt networks connected to the Service; (4) to comply with all regulations, policies and procedures of networks connected to the Service.'
    },
    {
      id: 'privacy',
      title: '4. Privacy Policy',
      content: 'Your privacy is important to us. Our Privacy Policy is hereby incorporated into these Terms by reference. Please read the privacy policy carefully for information relating to our collection, use, and disclosure of your personal information.'
    },
    {
      id: 'modifications',
      title: '5. Modifications to Service',
      content: 'SereniMind reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that SereniMind shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.'
    }
  ];

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Terms and Conditions
                </h3>
                <div className="mt-2 max-h-96 overflow-y-auto">
                  {sections.map((section) => (
                    <div key={section.id} className="mb-4 border-b border-gray-200 pb-4">
                      <button
                        onClick={() => toggleSection(section.id)}
                        className="flex justify-between items-center w-full text-left font-semibold text-sm text-gray-800 hover:text-gray-600 focus:outline-none"
                      >
                        {section.title}
                        {expandedSections[section.id] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                      </button>
                      {expandedSections[section.id] && (
                        <p className="mt-2 text-sm text-gray-600">{section.content}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#59844F] text-base font-medium text-white hover:bg-[#2c3a20] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#59844F] sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;