import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faPhone, faEnvelope, faPenSquare, faArrowRight, faLanguage, faGear, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link,useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'; 
import 'react-phone-input-2/lib/style.css'; 
import { PaystackButton } from 'react-paystack'; // Import PaystackButton

const BookServices = () => {
    const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
    const [isSideNavOpen, setIsSideNavOpen] = useState(false);
    const navigate = useNavigate();

    const toggleSideNav = () => {
      setIsSideNavOpen(!isSideNavOpen);
    };  
    
    const [formData, setFormData] = useState({
    date: '',
    time: '',
    reason: '',
    phoneNumber: '',
    email: '',
    language: '',
    plan: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const toggleActivities = () => {
    setIsActivitiesOpen(!isActivitiesOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      phoneNumber: value
    });
  };

  const handlePlanChange = (e) => {
    setFormData({
      ...formData,
      plan: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('https://serenimindbackend.onrender.com/api/submit-form/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        localStorage.getItem('access_token')
        setTimeout(() => {
          navigate('/dashboardmain');
        }, 2000);
        localStorage.getItem('access_token')

      } else {
        // Handle error
        console.error('Failed to submit form');
      }
    } catch (error) {
      // Handle error
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlanAmount = () => {
    switch (formData.plan) {
      case 'free trial':
        return 0;
      case 'One-off plan for $10':
        return 10000; // Amount in kobo (10 USD)
      case 'Three-times for $25':
        return 25000; // Amount in kobo (25 USD)
      case 'Seven-times for $50':
        return 50000; // Amount in kobo (50 USD)
      default:
        return 0;
    }
  };

  const config = {
    email: formData.email,
    amount: getPlanAmount() * 100, // Convert to kobo
    publicKey: 'ypk_test_a7190862b89d8656b52a586c50ee3d5e89488fdf', // Replace with your Paystack public key
    metadata: {
      custom_fields: [
        {
          display_name: "Phone Number",
          variable_name: "phone_number",
          value: formData.phoneNumber,
        }
      ]
    }
  };

  const handlePaystackSuccess = async (reference) => {
    console.log(reference);
    await handleSubmit(new Event('submit'));
  };

  const handlePaystackClose = () => {
    console.log('Payment closed');
  };

  const componentProps = {
    ...config,
    text: formData.plan === 'free' ? 'Submit' : 'Proceed to Payment',
    onSuccess: handlePaystackSuccess,
    onClose: handlePaystackClose,
  };


  const today = new Date().toISOString().split('T')[0]; // Define today's date
  
  return (
    <div className='flex min-h-screen bg-gray-100'>
             
    <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>
            {/*Booking of a Mental Health Professional */}
            <div className="container mx-auto px-4 w-[900px] overflow-y-auto md:h-[560px] my-5">
      <h2 className="mb-4 text-2xl font-semibold text-center text-gray-800">Book a Mental Health Professional</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Date and Time */}
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="w-full">
            <label className="block mb-1 text-sm font-medium text-gray-700">Preferred Date</label>
            <input
              type="date"
              name="date"
              required
              min={today}
              value={formData.date}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
            />
          </div>
          <div className="w-full mt-4 md:mt-0">
            <label className="block mb-1 text-sm font-medium text-gray-700">Preferred Time</label>
            <input
              type="time"
              name="time"
              required
              value={formData.time}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
            />
          </div>
        </div>

        {/* Phone Number */}
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">Phone Number</label>
          <PhoneInput
            country={'us'}
            value={formData.phoneNumber}
            onChange={handlePhoneChange}
            inputClass="!w-full !py-2 !pl-14 !pr-4 !border !rounded-md !focus:outline-none !focus:ring-2 !focus:ring-[#59844F]"
            required
          />
        </div>

        {/* Email */}
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="you@example.com"
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
          />
        </div>

        {/* Language */}
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">Preferred Language</label>
          <input
            type="text"
            name="language"
            value={formData.language}
            onChange={handleInputChange}
            placeholder="e.g., English"
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
          />
        </div>

        {/* Reason for Booking */}
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">Reason for Booking</label>
          <textarea
            name="reason"
            value={formData.reason}
            onChange={handleInputChange}
            rows="4"
            placeholder="Briefly describe your reason for booking..."
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
          ></textarea>
        </div>

        {/* Plan Selection */}
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">Choose a Plan</label>
          <select
            name="plan"
            value={formData.plan}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F]"
          >
            <option value="One-Time Free session">One-Time Free session</option>
            <option value="NGN 20,000 for 1 satisfactory session">NGN 20,000 for 1 satisfactory session</option>
            <option value="NGN 55,000 for 3 satisfactory sessions">NGN 55,000 for 3 satisfactory sessions</option>
            <option value="NGN 130,000 for 7 satisfactory sessions">NGN 130,000 for 7 satisfactory sessions</option>
          </select>
        </div>

        {/* Anonymous Option */}
        <div className="flex items-center">
          <input
            type="checkbox"
            name="anonymous"
            checked={formData.anonymous}
            onChange={handleInputChange}
            className="w-4 h-4 text-green-600 border-gray-300 rounded focus:ring-green-500"
          />
          <label className="ml-2 text-sm text-gray-700">I would like to remain anonymous</label>
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className={`w-full px-4 py-2 font-medium text-white bg-[#59844F] rounded-md hover:bg-[#59844F] focus:outline-none focus:ring-2 focus:ring-[#59844F] ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isLoading}
          >
            {isLoading ? 'Booking...' : 'Submit'}
          </button>
        </div>
      </form>

      {/* Success Message */}
      {showSuccessMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-6 bg-white rounded-md shadow-md">
            <p className="mb-4 text-lg font-medium text-gray-800">
              Your booking has been sent. We will get back to you soon.
            </p>
            <button
              onClick={() => setShowSuccessMessage(false)}
              className="px-4 py-2 font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
      <a href="https://wa.me/2347078634362?text=Hello%2C%20I%20have%20a%20question%20concerning%20your%20service." className="fixed bottom-6 right-6 bg-[#25D366] text-white p-4 rounded-full shadow-lg hover:bg-[#128C7E] transition-colors duration-300">
      <FontAwesomeIcon icon={faWhatsapp} className="text-3xl" />
    </a>
    </div>
  );
};

export default BookServices;