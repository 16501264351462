// Import necessary modules
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://serenimindbackend.onrender.com/api/',
  timeout: 1000000, // Adjust timeout as needed
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage or context
    const token = localStorage.getItem('token'); // Example: localStorage
    if (token) {
      console.log('Token found:', token); // Debug log
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.log('No token found'); // Debug log
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error); // Debug log
    return Promise.reject(error);
  }
);

export default axiosInstance;
