import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlay, faPause, faBars, faTimes, faInfoCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const moodSounds = [
  { id: 1, name: 'Ocean Waves', category: 'Nature', duration: '10:00', url: 'https://www.youtube.com/watch?v=bn9F19Hi1Lk', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm_Nhi6h0NrtT3nrmdppWeHSCrQS44fRqyNQ&s', moods: ['Calm', 'Relaxed', 'Peaceful'] },
  { id: 2, name: 'Upbeat Pop', category: 'Music', duration: '3:30', url: 'https://example.com/sounds/upbeat-pop.mp3', image: 'https://i.scdn.co/image/ab67616d0000b2731b320e834f75e594c488fbbe', moods: ['Happy', 'Energetic', 'Excited'] },
  { id: 3, name: 'Gentle Rain', category: 'Nature', duration: '9:15', url: 'https://example.com/sounds/gentle-rain.mp3', image: 'https://source.boomplaymusic.com/group10/M00/03/29/396bd1ba81204c94b5742a04761528df_320_320.jpg', moods: ['Calm', 'Focused', 'Introspective'] },
  { id: 4, name: 'Inspiring Orchestra', category: 'Music', duration: '5:45', url: 'https://example.com/sounds/inspiring-orchestra.mp3', image: 'https://ledgernote.com/wp-content/uploads/2021/02/los-angeles-philharmonic.jpg.webp', moods: ['Motivated', 'Inspired', 'Uplifted'] },
  { id: 5, name: 'Crackling Fireplace', category: 'Ambient', duration: '7:45', url: 'https://example.com/sounds/fireplace.mp3', image: 'https://i.ytimg.com/vi/UgHKb_7884o/maxresdefault.jpg', moods: ['Stress', 'Overwhelmed', 'Anxiety'] },
  { id: 6, name: 'Tropical Birds', category: 'Nature', duration: '8:30', url: 'https://example.com/sounds/tropical-birds.mp3', image: 'https://i.ytimg.com/vi/hedeOWP4KXs/maxresdefault.jpg', moods: ['Cheerful', 'Lively', 'Adventurous'] },
  { id: 7, name: 'Zen Garden', category: 'Meditation', duration: '15:00', url: 'https://example.com/sounds/zen-garden.mp3', image: 'https://i.ytimg.com/vi/LquDhy_17xo/maxresdefault.jpg', moods: ['Stress', 'Overwhelmed', 'Anxiety'] },
  { id: 8, name: 'City Cafe', category: 'Ambient', duration: '12:00', url: 'https://example.com/sounds/city-cafe.mp3', image: 'https://source.boomplaymusic.com/group10/M00/05/03/8c6a053f6e464aff8712960c1b5e91e7_320_320.jpg', moods: ['Social', 'Busy', 'Productive'] },
  { id: 9, name: 'Mountain Stream', category: 'Nature', duration: '11:30', url: 'https://example.com/sounds/mountain-stream.mp3', image: 'https://i.ytimg.com/vi/Gfy0fnHrYVo/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AHUBoAC4AOKAgwIABABGEsgZSgXMA8=&rs=AOn4CLDt_1TfmUsErHRroELdYtI5iUnSkA', moods: ['Refreshed', 'Clear-minded', 'Calm'] },
  { id: 10, name: 'Motivational Speech', category: 'Speech', duration: '6:15', url: 'https://example.com/sounds/motivational-speech.mp3', image: 'https://i.ytimg.com/vi/mqYcwvwYerw/maxresdefault.jpg', moods: ['Inspired', 'Determined', 'Confident'] },
  { id: 11, name: 'Purring Cat', category: 'Animals', duration: '3:30', url: 'https://example.com/sounds/purring-cat.mp3', image: 'https://i.scdn.co/image/ab67616d0000b2735958fc1dacb4a905c376ce75', moods: ['Content', 'Comforted', 'Relaxed'] },
  { id: 12, name: 'Summer Beach Party', category: 'Ambient', duration: '5:00', url: 'https://example.com/sounds/beach-party.mp3', image: 'https://i.ytimg.com/vi/T365EQPfQUk/maxresdefault.jpg', moods: ['Excited', 'Joyful', 'Energetic'] },
  { id: 13, name: 'Thunderstorm', category: 'Nature', duration: '10:00', url: 'https://example.com/sounds/thunderstorm.mp3', image: 'https://i.ytimg.com/vi/SwLSE7wNQ3s/maxresdefault.jpg', moods: ['Dramatic', 'Intense', 'Focused'] },
  { id: 14, name: 'Piano Sonata', category: 'Music', duration: '7:20', url: 'https://example.com/sounds/piano-sonata.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm5DV4r_VrmMlrE3rxpm9qJPrTm1xI7H0_nw&s', moods: ['Reflective', 'Emotional', 'Calm'] },
  { id: 15, name: 'Morning Birdsong', category: 'Nature', duration: '6:45', url: 'https://example.com/sounds/morning-birds.mp3', image: 'https://i.ytimg.com/vi/F1LAxZQbAlU/maxresdefault.jpg', moods: ['Refreshed', 'Optimistic', 'Awake'] },
  { id: 16, name: 'Meditation Bells', category: 'Meditation', duration: '5:30', url: 'https://example.com/sounds/meditation-bells.mp3', image: 'https://i.ytimg.com/vi/_vgG9Oq6xHw/maxresdefault.jpg', moods: ['Focused', 'Centered', 'Calm'] },
  { id: 17, name: 'Uplifting Acoustic', category: 'Music', duration: '4:15', url: 'https://example.com/sounds/uplifting-acoustic.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsI0IaquUTAKhUR2HGJ4PyONPkau6PktKspw&s', moods: ['Happy', 'Optimistic', 'Energized'] },
  { id: 18, name: 'Campfire Stories', category: 'Ambient', duration: '20:00', url: 'https://example.com/sounds/campfire-stories.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ039V6xlqa0xpLhgOFlwjn3a5tQNToghZDmA&s', moods: ['Nostalgic', 'Cozy', 'Entertained'] },
  { id: 19, name: 'Whale Songs', category: 'Nature', duration: '15:30', url: 'https://example.com/sounds/whale-songs.mp3', image: 'https://i.ytimg.com/vi/xo2bVbDtiX8/maxresdefault.jpg', moods: ['Awed', 'Peaceful', 'Introspective'] },
  { id: 20, name: 'Busy Office', category: 'Ambient', duration: '30:00', url: 'https://example.com/sounds/busy-office.mp3', image: 'https://i.ytimg.com/vi/jS7UIks7wv8/maxresdefault.jpg', moods: ['Productive', 'Focused', 'Energetic'] },
  { id: 22, name: 'Babbling Brook', category: 'Nature', duration: '12:30', url: 'https://example.com/sounds/babbling-brook.mp3', image: 'https://i.ytimg.com/vi/grs95NjzdJs/maxresdefault.jpg', moods: ['Refreshed', 'Calm', 'Centered'] },
  { id: 23, name: 'Inspiring Podcast', category: 'Speech', duration: '25:00', url: 'https://example.com/sounds/inspiring-podcast.mp3', image: 'https://images.squarespace-cdn.com/content/v1/5856bc666a49634cd55b0ba9/1621365529358-FHK61GJAOHDWQ90SVCHL/uplifting-podcasts+%281%29.jpeg', moods: ['Motivated', 'Informed', 'Empowered'] },
  { id: 24, name: 'Gentle Wind Chimes', category: 'Ambient', duration: '6:00', url: 'https://example.com/sounds/wind-chimes.mp3', image: 'https://i.ytimg.com/vi/X7t--zPZdic/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGEsgSyhlMA8=&rs=AOn4CLBQYbZ6fzMHlE2jUPXts2adlb4ejQ', moods: ['Anxiety', 'Depression', 'Overwhelmed'] },
  { id: 25, name: 'Energetic Workout Mix', category: 'Music', duration: '45:00', url: 'https://example.com/sounds/workout-mix.mp3', image: 'https://i.ytimg.com/vi/vIgb1ZAK09U/maxresdefault.jpg', moods: ['Energized', 'Motivated', 'Powerful'] },
  { id: 28, name: 'Laughter Therapy', category: 'Therapy', duration: '10:00', url: 'https://example.com/sounds/laughter-therapy.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtROwXZiEujLKPH_r3dKeH71uXoKod3NiAIA&s', moods: ['Joyful', 'Amused', 'Lighthearted'] },
  { id: 30, name: 'Jungle Ambience', category: 'Nature', duration: '14:00', url: 'https://example.com/sounds/jungle-ambience.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8Fu-vzzQZUugqFhjsF0NElN-KK6zNqcqZfg&s', moods: ['Adventurous', 'Curious', 'Alive'] },
  { id: 31, name: 'Spa Relaxation', category: 'Ambient', duration: '60:00', url: 'https://example.com/sounds/spa-relaxation.mp3', image: 'https://i.ytimg.com/vi/3x5SZI-Lc3U/maxresdefault.jpg', moods: ['Pampered', 'Relaxed', 'Rejuvenated'] },
  { id: 32, name: 'Positive Affirmations', category: 'Speech', duration: '15:00', url: 'https://example.com/sounds/positive-affirmations.mp3', image: 'https://i.ytimg.com/vi/yo1pJ_D-H3M/maxresdefault.jpg', moods: ['Confident', 'Empowered', 'Positive'] },
  { id: 34, name: 'Seagulls and Waves', category: 'Nature', duration: '11:00', url: 'https://example.com/sounds/seagulls-waves.mp3', image: 'https://i1.sndcdn.com/artworks-qLhzyyTW7kQwIQVR-59yrzg-t500x500.jpg', moods: ['Peaceful', 'Free', 'Nostalgic'] },
  { id: 35, name: 'Tribal Drums', category: 'Music', duration: '8:45', url: 'https://example.com/sounds/tribal-drums.mp3', image: 'https://www.dl-sounds.com/wp-content/uploads/edd/2024/01/Tribal-Percussion-Music.jpg', moods: ['Energized', 'Primal', 'Connected'] },
  { id: 36, name: 'Calm Breathing Exercise', category: 'Therapy', duration: '10:30', url: 'https://example.com/sounds/breathing-exercise.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrN6GoixzF5rVlXjNZ2xo-UA-l3AaYzxYJnA&s', moods: ['Calm', 'Centered', 'Relaxed'] },
  { id: 37, name: 'Space Ambient', category: 'Ambient', duration: '30:00', url: 'https://example.com/sounds/space-ambient.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUwtDIR9Ih_ZoifR0VaG5aqzPb9A8BIkMvsg&s', moods: ['Awed', 'Introspective', 'Expansive'] },
  { id: 38, name: 'Coffee Shop Jazz', category: 'Music', duration: '45:00', url: 'https://example.com/sounds/coffee-shop-jazz.mp3', image: 'https://i.ytimg.com/vi/c0_ejQQcrwI/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLAARKIzbFJHxVRx9ij75gd641d42Q', moods: ['Relaxed', 'Sophisticated', 'Focused'] },
  { id: 39, name: 'Garden Ambience', category: 'Nature', duration: '20:00', url: 'https://example.com/sounds/garden-ambience.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRJwZaPNcnjPUK7cXvAd48xZZR0c9EX75Qgw&s', moods: ['Peaceful', 'Grounded', 'Refreshed'] },
  { id: 40, name: 'Inspirational Symphony', category: 'Music', duration: '12:00', url: 'https://example.com/sounds/inspirational-symphony.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfEXujFcThaszwnOeRM03Jl9ez0sqeZHnVCw&s', moods: ['Inspired', 'Emotional', 'Uplifted'] },
  { id: 41, name: 'Binaural Beats', category: 'Therapy', duration: '30:00', url: 'https://example.com/sounds/binaural-beats.mp3', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh6Ol0NgUdUFejBpGfICZezPnZRbKcbV-6xg&s', moods: ['Focused', 'Relaxed', 'Balanced'] },
  { id: 43, name: 'Cheerful Ukulele', category: 'Music', duration: '3:45', url: 'https://example.com/sounds/cheerful-ukulele.mp3', image: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgGHoF_59q2WFGPiDIrb_fd-dUN3I99yo91O6elNpa6s2nbn_orTDXl-HmdSYCvlk7J4AII_df_U-75w3kuJkfReOmHAAQSak4U4PoCkpnGOtObpZ0BdUV3MY-r0XUHODdOYeHXdor063Q/', moods: ['Happy', 'Carefree', 'Optimistic'] },
  { id: 44, name: 'Zen Monastery', category: 'Ambient', duration: '25:00', url: 'https://example.com/sounds/zen-monastery.mp3', image: 'https://example.com/images/zen-monastery.jpg', moods: ['Peaceful', 'Spiritual', 'Mindful'] },
  { id: 45, name: 'Melancholy Rain', category: 'Ambient', duration: '6:30', url: 'https://example.com/sounds/melancholy_rain.mp3', image: 'https://example.com/images/melancholy_rain.jpg', moods: ['Sad', 'Reflective', 'Gloomy'] },
  { id: 46, name: 'Lonely Night', category: 'Ambient', duration: '5:45', url: 'https://example.com/sounds/lonely_night.mp3', image: 'https://example.com/images/lonely_night.jpg', moods: ['Lonely', 'Somber', 'Quiet'] },
  { id: 47, name: 'Fading Memories', category: 'Instrumental', duration: '4:55', url: 'https://example.com/sounds/fading_memories.mp3', image: 'https://example.com/images/fading_memories.jpg', moods: ['Nostalgic', 'Bittersweet', 'Wistful'] },
  { id: 48, name: 'Autumn Leaves', category: 'Nature', duration: '7:20', url: 'https://example.com/sounds/autumn_leaves.mp3', image: 'https://example.com/images/autumn_leaves.jpg', moods: ['Melancholic', 'Thoughtful', 'Introspective'] },
  { id: 49, name: 'Empty Room', category: 'Ambient', duration: '6:10', url: 'https://example.com/sounds/empty_room.mp3', image: 'https://example.com/images/empty_room.jpg', moods: ['Desolate', 'Abandoned', 'Hollow'] },
  { id: 50, name: 'Lost Love', category: 'Instrumental', duration: '5:30', url: 'https://example.com/sounds/lost_love.mp3', image: 'https://example.com/images/lost_love.jpg', moods: ['Heartbroken', 'Sorrowful', 'Yearning'] },
  { id: 51, name: 'Solitude', category: 'Ambient', duration: '8:15', url: 'https://example.com/sounds/solitude.mp3', image: 'https://example.com/images/solitude.jpg', moods: ['Isolated', 'Pensive', 'Withdrawn'] },
  { id: 52, name: 'Rainy Window', category: 'Nature', duration: '6:40', url: 'https://example.com/sounds/rainy_window.mp3', image: 'https://example.com/images/rainy_window.jpg', moods: ['Gloomy', 'Contemplative', 'Melancholy'] },
  { id: 53, name: 'Echoes of the Past', category: 'Instrumental', duration: '7:05', url: 'https://example.com/sounds/echoes_past.mp3', image: 'https://example.com/images/echoes_past.jpg', moods: ['Regretful', 'Nostalgic', 'Somber'] },
  { id: 54, name: 'Twilight Sorrow', category: 'Ambient', duration: '5:50', url: 'https://example.com/sounds/twilight_sorrow.mp3', image: 'https://example.com/images/twilight_sorrow.jpg', moods: ['Mournful', 'Despondent', 'Forlorn'] },
  { id: 55, name: 'Broken Dreams', category: 'Instrumental', duration: '6:20', url: 'https://example.com/sounds/broken_dreams.mp3', image: 'https://example.com/images/broken_dreams.jpg', moods: ['Disappointed', 'Disheartened', 'Dejected'] },
  { id: 56, name: 'Gray Skies', category: 'Nature', duration: '7:30', url: 'https://example.com/sounds/gray_skies.mp3', image: 'https://example.com/images/gray_skies.jpg', moods: ['Gloomy', 'Downcast', 'Dreary'] },
  { id: 57, name: 'Whispers of Regret', category: 'Ambient', duration: '6:15', url: 'https://example.com/sounds/whispers_regret.mp3', image: 'https://example.com/images/whispers_regret.jpg', moods: ['Remorseful', 'Rueful', 'Wistful'] },
  { id: 58, name: 'Forgotten Melody', category: 'Instrumental', duration: '5:40', url: 'https://example.com/sounds/forgotten_melody.mp3', image: 'https://example.com/images/forgotten_melody.jpg', moods: ['Melancholic', 'Bittersweet', 'Nostalgic'] },
  { id: 59, name: 'Shadows of Loneliness', category: 'Ambient', duration: '7:10', url: 'https://example.com/sounds/shadows_loneliness.mp3', image: 'https://example.com/images/shadows_loneliness.jpg', moods: ['Lonely', 'Isolated', 'Desolate'] },
  { id: 60, name: 'Tears in the Rain', category: 'Nature', duration: '6:35', url: 'https://example.com/sounds/tears_rain.mp3', image: 'https://example.com/images/tears_rain.jpg', moods: ['Sorrowful', 'Weeping', 'Melancholy'] },
  { id: 61, name: 'Faded Photographs', category: 'Ambient', duration: '5:55', url: 'https://example.com/sounds/faded_photographs.mp3', image: 'https://example.com/images/faded_photographs.jpg', moods: ['Nostalgic', 'Wistful', 'Bittersweet'] },
  { id: 62, name: 'Last Goodbye', category: 'Instrumental', duration: '6:25', url: 'https://example.com/sounds/last_goodbye.mp3', image: 'https://example.com/images/last_goodbye.jpg', moods: ['Heartbroken', 'Mournful', 'Grieving'] },
  { id: 63, name: 'Withered Flowers', category: 'Nature', duration: '7:15', url: 'https://example.com/sounds/withered_flowers.mp3', image: 'https://example.com/images/withered_flowers.jpg', moods: ['Melancholic', 'Forlorn', 'Despondent'] },
  { id: 64, name: 'Silent Tears', category: 'Ambient', duration: '6:05', url: 'https://example.com/sounds/silent_tears.mp3', image: 'https://example.com/images/silent_tears.jpg', moods: ['Sorrowful', 'Heartache', 'Grief'] },
  { id: 65, name: 'Abandoned House', category: 'Ambient', duration: '7:40', url: 'https://example.com/sounds/abandoned_house.mp3', image: 'https://example.com/images/abandoned_house.jpg', moods: ['Desolate', 'Lonely', 'Eerie'] },
  { id: 66, name: 'Distant Memory', category: 'Instrumental', duration: '5:50', url: 'https://example.com/sounds/distant_memory.mp3', image: 'https://example.com/images/distant_memory.jpg', moods: ['Nostalgic', 'Bittersweet', 'Melancholic'] },
  { id: 67, name: 'Wilted Rose', category: 'Nature', duration: '6:30', url: 'https://example.com/sounds/wilted_rose.mp3', image: 'https://example.com/images/wilted_rose.jpg', moods: ['Sorrowful', 'Fading', 'Melancholy'] },
  { id: 68, name: 'Lonely Path', category: 'Ambient', duration: '7:20', url: 'https://example.com/sounds/lonely_path.mp3', image: 'https://example.com/images/lonely_path.jpg', moods: ['Isolated', 'Solitary', 'Forlorn'] },
  { id: 69, name: 'Unspoken Words', category: 'Instrumental', duration: '6:15', url: 'https://example.com/sounds/unspoken_words.mp3', image: 'https://example.com/images/unspoken_words.jpg', moods: ['Regretful', 'Wistful', 'Pensive'] },
  { id: 70, name: 'Cloudy Thoughts', category: 'Ambient', duration: '5:45', url: 'https://example.com/sounds/cloudy_thoughts.mp3', image: 'https://example.com/images/cloudy_thoughts.jpg', moods: ['Gloomy', 'Introspective', 'Melancholic'] },
  { id: 71, name: 'Lost in Time', category: 'Instrumental', duration: '7:05', url: 'https://example.com/sounds/lost_in_time.mp3', image: 'https://example.com/images/lost_in_time.jpg', moods: ['Nostalgic', 'Wistful', 'Melancholy'] },
  { id: 72, name: 'Autumn Wind', category: 'Nature', duration: '6:50', url: 'https://example.com/sounds/autumn_wind.mp3', image: 'https://example.com/images/autumn_wind.jpg', moods: ['Melancholic', 'Somber', 'Wistful'] },
  { id: 73, name: 'Shattered Dreams', category: 'Ambient', duration: '5:55', url: 'https://example.com/sounds/shattered_dreams.mp3', image: 'https://example.com/images/shattered_dreams.jpg', moods: ['Heartbroken', 'Disillusioned', 'Sorrowful'] },
  { id: 74, name: 'Echoes of Silence', category: 'Ambient', duration: '7:25', url: 'https://example.com/sounds/echoes_silence.mp3', image: 'https://example.com/images/echoes_silence.jpg', moods: ['Lonely', 'Empty', 'Desolate'] },
  { id: 75, name: 'Misty Memories', category: 'Instrumental', duration: '6:40', url: 'https://example.com/sounds/misty_memories.mp3', image: 'https://example.com/images/misty_memories.jpg', moods: ['Nostalgic', 'Bittersweet', 'Melancholic'] },
  { id: 76, name: 'Weeping Willow', category: 'Nature', duration: '7:00', url: 'https://example.com/sounds/weeping_willow.mp3', image: 'https://example.com/images/weeping_willow.jpg', moods: ['Sorrowful', 'Mournful', 'Melancholy'] },
  { id: 77, name: 'Hollow Heart', category: 'Ambient', duration: '6:20', url: 'https://example.com/sounds/hollow_heart.mp3', image: 'https://example.com/images/hollow_heart.jpg', moods: ['Empty', 'Numb', 'Heartbroken'] },
  { id: 78, name: 'Fading Light', category: 'Ambient', duration: '5:35', url: 'https://example.com/sounds/fading_light.mp3', image: 'https://example.com/images/fading_light.jpg', moods: ['Melancholic', 'Somber', 'Wistful'] },
  { id: 79, name: 'Lost in Thought', category: 'Instrumental', duration: '7:10', url: 'https://example.com/sounds/lost_in_thought.mp3', image: 'https://example.com/images/lost_in_thought.jpg', moods: ['Pensive', 'Introspective', 'Melancholy'] },
  { id: 80, name: 'Forgotten Lullaby', category: 'Instrumental', duration: '6:45', url: 'https://example.com/sounds/forgotten_lullaby.mp3', image: 'https://example.com/images/forgotten_lullaby.jpg', moods: ['Nostalgic', 'Bittersweet', 'Melancholic'] },
  { id: 81, name: 'Rainy Reflections', category: 'Nature', duration: '7:30', url: 'https://example.com/sounds/rainy_reflections.mp3', image: 'https://example.com/images/rainy_reflections.jpg', moods: ['Gloomy', 'Introspective', 'Melancholy'] },
  { id: 82, name: 'Echoes of Longing', category: 'Ambient', duration: '6:55', url: 'https://example.com/sounds/echoes_longing.mp3', image: 'https://example.com/images/echoes_longing.jpg', moods: ['Yearning', 'Wistful', 'Melancholic'] },
  { id: 83, name: 'Twilight Serenade', category: 'Instrumental', duration: '5:50', url: 'https://example.com/sounds/twilight_serenade.mp3', image: 'https://example.com/images/twilight_serenade.jpg', moods: ['Melancholy', 'Bittersweet', 'Somber'] },
  { id: 84, name: 'Whispers in the Wind', category: 'Nature', duration: '7:15', url: 'https://example.com/sounds/whispers_wind.mp3', image: 'https://example.com/images/whispers_wind.jpg', moods: ['Lonely', 'Wistful', 'Melancholic'] },
  { id: 85, name: 'Calming Ocean Waves', category: 'Nature', duration: '6:45', url: 'https://example.com/sounds/calming_ocean_waves.mp3', image: 'https://example.com/images/calming_ocean_waves.jpg', moods: ['Anxiety', 'Stress', 'Overwhelmed'] },
  { id: 86, name: 'Gentle Rain Meditation', category: 'Nature', duration: '5:30', url: 'https://example.com/sounds/gentle_rain_meditation.mp3', image: 'https://example.com/images/gentle_rain_meditation.jpg', moods: ['Depression', 'Sadness', 'Anxiety'] },
  { id: 89, name: 'Tranquil Lake Ambience', category: 'Nature', duration: '6:20', url: 'https://example.com/sounds/tranquil_lake_ambience.mp3', image: 'https://i.ytimg.com/vi/xD_8xWpaZqg/maxresdefault.jpg', moods: ['Anxiety', 'Depression', 'Overwhelmed'] },
  { id: 90, name: 'Soft Piano Melodies', category: 'Instrumental', duration: '5:55', url: 'https://example.com/sounds/soft_piano_melodies.mp3', image: 'https://i.ytimg.com/vi/4khIPP--FDU/maxresdefault.jpg', moods: ['Stress', 'Sadness', 'Anxiety'] },
  { id: 91, name: 'Flowing Stream', category: 'Nature', duration: '7:00', url: 'https://example.com/sounds/flowing_stream.mp3', image: 'https://i.ytimg.com/vi/qHXFLsnKDq0/hq720_live.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCMO3VCED3IOYkhrD9jPItuJidjGg', moods: ['Stress', 'Anger', 'Overwhelmed'] },
  { id: 92, name: 'Peaceful Garden Sounds', category: 'Nature', duration: '5:40', url: 'https://example.com/sounds/peaceful_garden_sounds.mp3', image: 'https://i1.sndcdn.com/artworks-yiXVCVP1vOT3IKtQ-NuQnXA-t1080x1080.jpg', moods: ['Anxiety', 'Depression', 'Tiredness'] },
  { id: 100, name: 'Peaceful Waterfall', category: 'Nature', duration: '4:55', url: 'https://example.com/sounds/peaceful_waterfall.mp3', image: 'https://i1.sndcdn.com/artworks-yiXVCVP1vOT3IKtQ-NuQnXA-t1080x1080.jpg', moods: ['Stress', 'Anxiety', 'Overwhelmed'] },
  { id: 101, name: 'Soothing Harp Melodies', category: 'Instrumental', duration: '7:05', url: 'https://example.com/sounds/soothing_harp_melodies.mp3', image: 'https://i.ytimg.com/vi/cv6zuSeWPMg/maxresdefault.jpg', moods: ['Sadness', 'Anxiety', 'Stress'] },
  { id: 102, name: 'Gentle Ocean Surf', category: 'Nature', duration: '6:30', url: 'https://example.com/sounds/gentle_ocean_surf.mp3', image: 'https://i.ytimg.com/vi/thdubH7zqP0/maxresdefault.jpg', moods: ['Insomnia', 'Anxiety', 'Stress'] },
  { id: 103, name: 'Relaxing Rainstorm', category: 'Nature', duration: '5:35', url: 'https://example.com/sounds/relaxing_rainstorm.mp3', image: 'https://i1.sndcdn.com/artworks-omAMcxX9DERFHycM-OUiQzQ-t1080x1080.jpg', moods: ['Anxiety', 'Depression', 'Overwhelmed'] },
  { id: 105, name: 'Soft Flowing River', category: 'Nature', duration: '5:25', url: 'https://example.com/sounds/soft_flowing_river.mp3', image: 'https://i.ytimg.com/vi/S0wXgt_3W04/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AHUBoAC4AOKAgwIABABGCYgYihyMA8=&rs=AOn4CLA0ocD1asURRvszFQyng98Q7YecqQ', moods: ['Stress', 'Overwhelmed', 'Anxiety'] },
  { id: 106, name: 'Calming Tibetan Bowls', category: 'Instrumental', duration: '7:20', url: 'https://example.com/sounds/calming_tibetan_bowls.mp3', image: 'https://wp.insighttimer.com/blog/wp-content/uploads/2019/10/singingbowlmeditation.png', moods: ['Anxiety', 'Stress', 'Depression'] },
  { id: 107, name: 'Gentle Mountain Stream', category: 'Nature', duration: '4:45', url: 'https://example.com/sounds/gentle_mountain_stream.mp3', image: 'https://i.ytimg.com/vi/qHXFLsnKDq0/hq720_live.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCMO3VCED3IOYkhrD9jPItuJidjGg', moods: ['Stress', 'Anxiety', 'Overwhelmed'] },
  { id: 108, name: 'Soothing Night Sounds', category: 'Nature', duration: '6:55', url: 'https://example.com/sounds/soothing_night_sounds.mp3', image: 'https://i.ytimg.com/vi/SN3xlcjbvUo/maxresdefault.jpg', moods: ['Insomnia', 'Anxiety', 'Restlessness'] },
  { id: 109, name: 'Calm Forest Ambience', category: 'Nature', duration: '5:20', url: 'https://example.com/sounds/calm_forest_ambience.mp3', image: 'https://i.ytimg.com/vi/eKFTSSKCzWA/maxresdefault.jpg', moods: ['Stress', 'Anxiety', 'Depression'] },
  { id: 110, name: 'Relaxing Classical Guitar', category: 'Instrumental', duration: '6:40', url: 'https://example.com/sounds/relaxing_classical_guitar.mp3', image: 'https://i.ytimg.com/vi/iIjSS_MbCGg/sddefault.jpg', moods: ['Anxiety', 'Stress', 'Sadness'] },
  { id: 111, name: 'Peaceful Meadow Breeze', category: 'Nature', duration: '7:15', url: 'https://example.com/sounds/peaceful_meadow_breeze.mp3', image: 'https://i.ytimg.com/vi/NG5kHtaEJoY/maxresdefault.jpg', moods: ['Stress', 'Overwhelmed', 'Anxiety'] },
  { id: 112, name: 'Calming Beach Waves', category: 'Nature', duration: '5:50', url: 'https://example.com/sounds/calming_beach_waves.mp3', image: 'https://i.ytimg.com/vi/vPhg6sc1Mk4/maxresdefault.jpg', moods: ['Anxiety', 'Depression', 'Stress'] },
  { id: 113, name: 'Soothing Rain on Leaves', category: 'Nature', duration: '6:25', url: 'https://example.com/sounds/soothing_rain_leaves.mp3', image: 'https://i.ytimg.com/vi/fb9J8Ous2g0/maxresdefault.jpg', moods: ['Stress', 'Anxiety', 'Anger'] },
  { id: 114, name: 'Gentle Wind Rustling', category: 'Nature', duration: '4:40', url: 'https://example.com/sounds/gentle_wind_rustling.mp3', image: 'https://i.ytimg.com/vi/ildPMbKp5U4/maxresdefault.jpg', moods: ['Anxiety', 'Stress', 'Overwhelmed'] },
  { id: 115, name: 'Peaceful Lakeside Morning', category: 'Nature', duration: '7:05', url: 'https://example.com/sounds/peaceful_lakeside_morning.mp3', image: 'https://i.ytimg.com/vi/wUX29fg-Y6U/hq720.jpg?sqp=-oaymwE7CK4FEIIDSFryq4qpAy0IARUAAAAAGAElAADIQj0AgKJD8AEB-AH-CYAC0AWKAgwIABABGHIgYSg6MA8=&rs=AOn4CLB5Wa8zzIvd9g-Kf6gHtNIedzLVCA', moods: ['Depression', 'Anxiety', 'Tiredness'] },
  { id: 116, name: 'Calming Meditation Bells', category: 'Instrumental', duration: '5:30', url: 'https://example.com/sounds/calming_meditation_bells.mp3', image: 'https://i.scdn.co/image/ab67616d00001e0275442676513ece03cd79a40e', moods: ['Stress', 'Anxiety', 'Anger'] },
  { id: 117, name: 'Tranquil Forest Creek', category: 'Nature', duration: '6:45', url: 'https://example.com/sounds/tranquil_forest_creek.mp3', image: 'https://i.ytimg.com/vi/xKjOZUVeWKM/maxresdefault.jpg', moods: ['Stress', 'Overwhelmed', 'Anxiety'] },
  { id: 118, name: 'Soft Ambient Synth', category: 'Instrumental', duration: '5:55', url: 'https://example.com/sounds/soft_ambient_synth.mp3', image: 'https://lunacy.audio/wp-content/uploads/2023/06/AdobeStock_593840083.jpeg', moods: ['Anxiety', 'Depression', 'Stress'] },
  { id: 119, name: 'Gentle Rainforest Sounds', category: 'Nature', duration: '7:10', url: 'https://example.com/sounds/gentle_rainforest_sounds.mp3', image: 'https://i.ytimg.com/vi/zQtfnPTlFFE/maxresdefault.jpg', moods: ['Stress', 'Anxiety', 'Overwhelmed'] },
  { id: 120, name: 'Peaceful Garden Birds', category: 'Nature', duration: '6:35', url: 'https://example.com/sounds/peaceful_garden_birds.mp3', image: 'https://i.ytimg.com/vi/G0HNc8yK_VU/maxresdefault.jpg', moods: ['Depression', 'Anxiety', 'Stress'] },
  { id: 121, name: 'Soothing Ocean Meditation', category: 'Nature', duration: '7:00', url: 'https://example.com/sounds/soothing_ocean_meditation.mp3', image: 'https://i.ytimg.com/vi/bn9F19Hi1Lk/maxresdefault.jpg', moods: ['Anxiety', 'Depression', 'Overwhelmed'] },
  { id: 122, name: 'Relaxing Night Crickets', category: 'Nature', duration: '6:50', url: 'https://example.com/sounds/relaxing_night_crickets.mp3', image: 'https://m.media-amazon.com/images/I/4139+yeZDzL._UXNaN_FMjpg_QL85_.jpg', moods: ['Insomnia', 'Anxiety', 'Stress'] },
  { id: 123, name: 'Gentle Mountain Breeze', category: 'Nature', duration: '5:40', url: 'https://example.com/sounds/gentle_mountain_breeze.mp3', image: 'https://i.ytimg.com/vi/cG6ZwqdKKqw/maxresdefault.jpg', moods: ['Stress', 'Anxiety', 'Overwhelmed'] }
];

const allMoods = [...new Set(moodSounds.flatMap(sound => sound.moods))];

const MoodSounds = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSounds, setFilteredSounds] = useState(moodSounds);
  const [isPlaying, setIsPlaying] = useState(null);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [selectedMood, setSelectedMood] = useState('');

  useEffect(() => {
    const filtered = moodSounds.filter(sound =>
      (sound.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      sound.moods.some(mood => mood.toLowerCase().includes(searchQuery.toLowerCase()))) &&
      (selectedMood === '' || sound.moods.includes(selectedMood))
    );
    setFilteredSounds(filtered);
  }, [searchQuery, selectedMood]);
  

  const handleSearch = (e) => {
    e.preventDefault();
    // Search is already handled by the useEffect
  };

  const togglePlay = (id) => {
    setIsPlaying(isPlaying === id ? null : id);
  };

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  return (
    <div className='flex min-h-screen bg-gray-100'>
             
    <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>


      {/* Main Content */}
      <div className="container mx-auto max-w-[900px] overflow-y-auto md:h-[560px] px-4 py-6">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-3xl font-bold text-gray-800">Mood-Enhancing Sounds</h1>
          </div>

          <div className="bg-green-100 border-l-4 border-green-500 text-[#59844F] p-4 mb-6" role="alert">
            <div className="flex">
              <div className="py-1">
                <FontAwesomeIcon icon={faInfoCircle} className="h-6 w-6 mr-4" />
              </div>
              <div>
                <p className="font-bold">Mood Boost</p>
                <p className="text-sm">Discover sounds that can help improve your mood and create a positive atmosphere.</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-4 mb-8">
            <form onSubmit={handleSearch} className="flex-grow">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search for mood-enhancing sounds..."
                  className="flex-grow px-4 py-2 border border-gray-300 rounded-md"
                />
                <button type="submit" className="px-4 py-2 bg-[#59844F] text-white rounded-md hover:bg-[#527949]">
                  <FontAwesomeIcon icon={faSearch} className="h-4 w-4 mr-2" />
                  Search
                </button>
              </div>
            </form>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFilter} className="h-4 w-4 mr-2 text-gray-600" />
              <select
                value={selectedMood}
                onChange={(e) => setSelectedMood(e.target.value)}
                className="px-4 py-2 border border-gray-300 rounded-md"
              >
                <option value="">All Moods</option>
                {allMoods.map(mood => (
                  <option key={mood} value={mood}>{mood}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredSounds.map((sound) => (
              <div key={sound.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{sound.name}</h3>
                  <img src={sound.image} alt={sound.name} className="w-full h-48  rounded-md mb-4" />
                  <p className="text-sm text-gray-500">{sound.category}</p>
                  <p className="text-sm text-gray-500">Duration: {sound.duration}</p>
                  <div className="mt-2">
                    {sound.moods.map((mood, index) => (
                      <span key={index} className="inline-block bg-green-100 text-[#59844F] text-xs px-2 py-1 rounded-full mr-2 mb-2">{mood}</span>
                    ))}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50">
                  <button
                    onClick={() => togglePlay(sound.id)}
                    className={`w-full py-2 rounded-md ${isPlaying === sound.id ? 'bg-green-500 hover:bg-green-600' : 'bg-[#59844F] hover:bg-[#4b7043]'} text-white`}
                  >
                    <FontAwesomeIcon icon={isPlaying === sound.id ? faPause : faPlay} className="h-4 w-4 mr-2" />
                    {isPlaying === sound.id ? 'Pause' : 'Play'}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodSounds;