import React, { useState,useEffect, useRef  } from 'react';
import { Link } from 'react-router-dom';
import Chart from 'chart.js/auto'; // Import Chart.js
import { PlusIcon, SearchIcon, TrashIcon, PencilIcon } from 'lucide-react';
import { faPlus, faSearch, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Healthcareai from '../images/Healthcare-ai.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEdit, faUserCircle, faTrashAlt, faCheckCircle,faGear, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'; // Import axios for making HTTP requests
import { Line } from 'react-chartjs-2';
import { useAuth } from '../components/AuthContext ';
import axiosInstance from '../components/axiosInstance';
import SereniMindlogoMain from '../images/SereniMindLogoMain.png';
import { ClipLoader } from 'react-spinners'; // Install this with 'npm install react-spinners'
import SerenimindDashboardOnboarding  from './InteractiveTour';

const Dashboardmain = () => {
const [isActivitiesOpen, setIsActivitiesOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [todos, setTodos] = useState([]);
  const [completedTodos, setCompletedTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');
  const [deadline, setDeadline] = useState('');
  const [isEditingDeadline, setIsEditingDeadline] = useState(false);
  const [goalRatio, setGoalRatio] = useState('');
  const [moodEntries, setMoodEntries] = useState([]);
  const [username, setUsername] = useState('');
  const refreshToken = localStorage.getItem('refresh_token');
  const [newGoal, setNewGoal] = useState('');
  const [goals, setGoals] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingState, setLoadingState] = useState({ add: false });
  const { token } = useAuth();
  const notesRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    // Check if it's the user's first visit
    const isFirstVisit = localStorage.getItem('dashboardOnboardingComplete') !== 'true';
    if (isFirstVisit) {
      setShowOnboarding(true);
    }
  }, []);

  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    localStorage.setItem('dashboardOnboardingComplete', 'true');
  };
  
  const handleFormMoodSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    
    const formData = new FormData(e.target); // Capture the form data
    const newEntry = {
      notes: formData.get('notes'), // Get the notes value from the form
    };

    // Add the new entry to mood history
    setMoodHistory([newEntry, ...moodHistory]);

    // Optionally reset the form or state if needed
    // e.target.reset(); // Resets the form inputs
  };
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
  
    // Save mood data only on form submission
    saveMoodData();
    
  };
  
  

  useEffect(() => {
    fetchGoals();
  }, []);

  // Fetch the goals (todos) from the backend
  const fetchGoals = async () => {
    try {
      const response = await axiosInstance.get('https://serenimindbackend.onrender.com/api/todos/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setGoals(response.data);
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  // Add a new goal (todo)
  const addGoal = async () => {

    if (!newGoal.trim() || !deadline.trim()) {
      alert("Please enter a goal and set a deadline.");
      return;
    }
  
    setLoadingState({ add: true });
    
    try {
      const response = await axiosInstance.post('https://serenimindbackend.onrender.com/api/todos/', {
        text: newGoal,
        deadline: deadline,

      });
  
      setGoals([...goals, response.data]);
      setNewGoal('');
    } catch (error) {
      console.error('Error adding goal:', error);
      alert('Error adding goal. Please try again.');
    } finally {
      setLoadingState({ add: false });
    }
  };
  

  // Complete a goal (todo)
  const completeGoal = async (id) => {
    setLoading(true); // Show spinner
    try {
      const response = await axiosInstance.put(`https://serenimindbackend.onrender.com/api/todos/${id}/complete/`);
      const updatedGoals = goals.map((goal) =>
        goal.id === id ? { ...goal, completed: true } : goal
      );
      setGoals(updatedGoals);
    } catch (error) {
      console.error('Error completing goal:', error);
    }
    setTimeout(() => {
      setLoading(false);
      console.log('Loading ended');
    }, 1000); // 1 second delay
  };

  // Delete a goal (todo)
  const deleteGoal = async (id) => {
    setLoading(true); // Show spinner
    try {
      await axiosInstance.delete(`https://serenimindbackend.onrender.com/api/todos/${id}`);
      const updatedGoals = goals.filter((goal) => goal.id !== id);
      setGoals(updatedGoals);
    } catch (error) {
      console.error('Error deleting goal:', error);
      alert('Error deleting goal. Please try again.');
    }
    setTimeout(() => {
      setLoading(false);
      console.log('Loading ended');
    }, 1000); // 1 second delay
  };

  // Filter goals based on search
  const filteredGoals = goals.filter((goal) =>
    goal.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Separate completed and incomplete goals
  const completedGoals = filteredGoals.filter((goal) => goal.completed);
  const incompleteGoals = filteredGoals.filter((goal) => !goal.completed);


  useEffect(() => {
    const fetchTodos = async () => {
      try {
        const response = await axios.get('https://serenimindbackend.onrender.com/api/todos/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setTodos(response.data);
      } catch (error) {
        console.error('Error fetching todos:', error);
      }
    };

    fetchTodos();
  }, [token]);
  
  useEffect(() => {
    const calculateGoalRatio = () => {
      if (todos.length > 0) {
        const ratio = completedTodos.length / todos.length;
        setGoalRatio(ratio.toFixed(2)); // Limit to 2 decimal places
      } else {
        setGoalRatio('');
      }
    };

    calculateGoalRatio();
  }, [todos, completedTodos]);

  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get('https://serenimindbackend.onrender.com/api/user-info-dashboard/', {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { username, first_name, last_name } = response.data;
        setUsername(username);
        setFirstName(first_name);
        setLastName(last_name);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

 
  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };  const [isEditName, setIsEditName] = useState(false);

  const [isAnonymous, setIsAnonymous] = useState(false);

  const toggleActivities = () => {
    setIsActivitiesOpen(!isActivitiesOpen);
  };

  const toggleEditName = () => {
    setIsEditName(!isEditName);
  };

  const toggleAnonymous = () => {
    setIsAnonymous(!isAnonymous);
  };
  const handleNewTodoChange = (e) => {
    setNewTodo(e.target.value);
  };
  const getUserId = async () => {
    try {
      const response = await axiosInstance.get('https://serenimindbackend.onrender.com/api/user-info-dashboard/', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { user_id } = response.data; // Assuming the user ID field is named 'user_id'
      return user_id;
    } catch (error) {
      console.error('Error fetching user ID:', error);
      return null;
    }
  };

  const addTodo = async () => {
    const userId = await getUserId(); // Wait for getUserId to fetch the user ID
  
    if (userId && newTodo.trim() !== '') {
      try {
        // Fetch the last todo to determine the next available ID
        const lastTodoResponse = await axiosInstance.get('https://serenimindbackend.onrender.com/api/todos/?ordering=-id&limit=1');
        const lastTodoId = lastTodoResponse.data.length > 0 ? lastTodoResponse.data[0].id : 0;
        const nextTodoId = lastTodoId + 1;
  
        const response = await axiosInstance.post('https://serenimindbackend.onrender.com/api/todos/', {
          id: nextTodoId,
          text: newTodo,
          deadline: deadline,
          user: userId, // Send the user ID (pk) value
        });
        setTodos([...todos, response.data]);
        setNewTodo('');
        setDeadline('');
      } catch (error) {
        console.error('Error adding todo:', error);
        // Handle other types of errors
      }finally {
        setLoadingState({ ...loadingState, add: false });
      }
    }
  };
  
  const completeTodo = async (id) => {
    try {
      const response = await axiosInstance.put(`https://serenimindbackend.onrender.com/api/todos/${id}/complete/`);
      const updatedTodos = todos.filter(todo => todo.id !== id); // Remove completed goal from uncompleted goals list
      const completedTodo = todos.find(todo => todo.id === id);
      setTodos(updatedTodos);
      setCompletedTodos([...completedTodos, completedTodo]); // Add completed goal to completed goals list
    } catch (error) {
      console.error('Error completing todo:', error);
      // Display a user-friendly error message
      alert('Error completing todo. Please try again later.');
    } finally {
      setLoadingState({ ...loadingState, complete: false });
    }
  };  
  
const removeTodo = async (id) => {
  try {
    const access_token = localStorage.getItem('accessToken');

    const response = await axiosInstance.delete(`https://serenimindbackend.onrender.com/api/todos/${id}/`, {
      headers: {
        'Authorization': `Token ${access_token}`
      }
    });

    if (response.status === 204) {
      const updatedTodos = todos.filter(todo => todo.id !== id);
      const updatedCompletedTodos = completedTodos.filter(todo => todo.id !== id);
      setTodos(updatedTodos); // Remove deleted goal from the list
      setCompletedTodos(updatedCompletedTodos);
    } else {
      throw new Error('Failed to remove todo');
    }
  } catch (error) {
    console.error('Error removing todo:', error);
    // Handle error
  } finally {
    setLoadingState({ ...loadingState, remove: false });
  }
};
  
const deleteCompletedTodo = (id) => {
  
  const updatedCompletedTodos = completedTodos.filter(todo => todo.id !== id);
  setCompletedTodos(updatedCompletedTodos);
};


  const [moodData, setMoodData] = useState({
    date: new Date().toISOString().slice(0, 10),
    moodRating: '5',
    notes: '',
  });
  const [moodHistory, setMoodHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [moodStats, setMoodStats] = useState({ totalEntries: 0, averageRating: 0 });
  const [isInputModified, setIsInputModified] = useState(false);

  const handleInputChange = (e) => { 
    const { name, value } = e.target; 
    setMoodData({ 
      ...moodData, 
      [name]: value, 
    }); 
  };
  
  

  const saveMoodData = () => {
    const newEntry = { ...moodData };
    setMoodHistory([newEntry, ...moodHistory]);
  };



  useEffect(() => {
    setFilteredHistory(moodHistory);
    updateMoodStats();
  }, [moodHistory]);

  const updateMoodStats = () => {
    const totalEntries = moodHistory.length;
    const totalRating = moodHistory.reduce((acc, entry) => acc + parseInt(entry.moodRating), 0);
    const averageRating = totalEntries > 0 ? (totalRating / totalEntries).toFixed(1) : 0;
    setMoodStats({ totalEntries, averageRating });
  };

  const filterMoodHistory = (filterOption) => {
    if (filterOption === 'all') {
      setFilteredHistory(moodHistory);
    } else {
      const filtered = moodHistory.filter((entry) => entry.moodRating >= parseInt(filterOption));
      setFilteredHistory(filtered);
    }
  };

  const moodChartData = {
    labels: moodHistory.map((entry) => entry.date),
    datasets: [
      {
        label: 'Mood Rating',
        data: moodHistory.map((entry) => entry.moodRating),
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const moodChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 10,
      },
    },
  };
  const deleteMood = (index) => {
    const updatedHistory = [...moodHistory];
    updatedHistory.splice(index, 1);
    setMoodHistory(updatedHistory);
  };
  return (
    <div className='flex min-h-screen bg-gray-100'>
             
    <div className={`  transform ${isSideNavOpen ? 'translate-x-0' : '-translate-x-full'} w-[280px] bg-white shadow-lg transition-transform duration-300 ease-in-out z-20 md:translate-x-0 smallscreensidenav`}>
<div className="p-6">
 <nav>
   <ul className="space-y-2">
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded"><Link to="/sereniai" className="flex items-center font-semibold">🤖 SereniAI</Link></button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">          <li><Link to="/book-professionals" className="flex items-center font-semibold">📚 Book our Professionals</Link></li>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/activity-lounge" className="flex items-center font-semibold">🎮 Activity Lounge</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/blog" className="flex items-center font-semibold">📝 Blog</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/journal" className="flex items-center font-semibold">✍🏼 Your Journal</Link>
     </button></li>
     <li><button className="w-full text-left py-2 px-4 hover:bg-gray-100 rounded">
     <Link to="/community" className="flex items-center font-semibold">👥 Community</Link>
     </button></li>
   </ul>
 </nav>
</div>

</div>
            
        {/* Profile and To-do List */}
        <div className="w-[900px] overflow-y-auto md:h-[560px]">
        <div className="p-6">
      {/* Add new goal section */}
      <div className="md:flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-center mb-3">Wellness Goals</h1>
        <div className="md:flex">
          <input
            type="text"
            placeholder="Enter new goal"
            value={newGoal}
            onChange={(e) => setNewGoal(e.target.value)}
            className="border border-gray-300 rounded p-2 flex-grow mr-2"
          />
          <input
            type="date"
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
            className="px-4 py-2 mr-4 border border-gray-300 rounded-lg focus:outline-none md:mt-0 mt-3"
          />
          <button
            onClick={addGoal}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 md:mt-0 mt-3"
            disabled={loadingState.add}
          >
            {loadingState.add ? 'Adding...' : <FontAwesomeIcon icon={faPlus} />}
          </button>
        </div>
      </div>

      {/* Search goals */}
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search goals..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 rounded p-2 w-full"
        />
      </div>

      {/* Display incomplete and completed goals */}
      <div className="">
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-green bg-opacity-75 z-50">
          <ClipLoader size={150} color={"#59844F"} />
        </div>
      )}

      
      <div className="grid grid-cols-2 gap-4 mb-6">
        {/* Incomplete Goals */}
        <div className="bg-white p-4 shadow rounded-lg relative">
          <h2 className="text-sm font-medium">Incomplete Goals</h2>
          <ul className="space-y-2">
            {incompleteGoals.map((goal) => (
              <li key={goal.id} className="flex justify-between items-center">
                <span className='w-[100px] break-words' title={goal.text}>{goal.text}</span> {/* Truncate long text */}
                <button onClick={() => completeGoal(goal.id)}>
                  <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Completed Goals */}
        <div className="bg-white p-4 shadow rounded-lg">
          <h2 className="text-sm font-medium">Completed Goals</h2>
          <ul className="space-y-2">
            {completedGoals.map((goal) => (
              <li key={goal.id} className="flex justify-between items-center">
                <span className="line-through truncate" title={goal.text}>{goal.text}</span> {/* Truncate long text */}
                <button onClick={() => deleteGoal(goal.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} className="text-red-500" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </div>
          

            {/* To-do List Section */}
            
                    {/* Mood Stats Section */}
        <div className="container px-4 py-8 mx-auto">
      <h2 className="mb-4 text-2xl font-semibold text-center text-gray-800">Mood Tracker</h2>
      <form onSubmit={handleFormSubmit}>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 w-full max-w-[400px] mx-auto p-4">
  
  <label className="text-left">Date:</label>
  <input
    type="date"
    name="date"
    value={moodData.date}
    onChange={handleInputChange}
    required
    className="bg-[#bdbcbc] px-3 py-1 rounded-xl w-full"
  />

  <label className="text-left">Mood Rating:</label>
  <input
    type="number"
    name="moodRating"
    min="1"
    max="10"
    value={moodData.moodRating}
    onChange={handleInputChange}
    required
    className="bg-[#bdbcbc] px-3 py-1 rounded-xl w-full"
  />

  <label className="text-left sm:col-span-2">Notes:</label>
  <textarea
    name="notes"
    ref={notesRef} 
    value={moodData.notes}
    onChange={handleInputChange}
    maxLength='30'
    required
    className="bg-[#bdbcbc] px-3 py-1 rounded-xl w-full sm:col-span-2"
  ></textarea>

  <button type="submit" className="bg-green-600 w-full sm:w-auto rounded-lg px-4 py-2 text-white sm:col-span-2">
    Save Mood
  </button>
</div>

      </form>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <Line data={moodChartData} options={moodChartOptions} />
      </div>
      
      <div className="p-6 mt-8 bg-white rounded-lg shadow-md">
        <label>Filter Mood History:</label>
        <select onChange={(e) => filterMoodHistory(e.target.value)}>
          <option value="all">All</option>
          <option value="6">6 and above</option>
          <option value="8">8 and above</option>
          <option value="10">10</option>
        </select>
      </div>
      <div className="p-6 mt-8 bg-white rounded-lg shadow-md">
        <h3 className="mb-4 text-xl font-semibold">Mood History</h3>
        <ul className="space-y-2">
          {filteredHistory.map((entry, index) => (
            <li key={entry.id} className="flex items-center justify-between px-4 py-2 bg-gray-100 rounded-lg">
              <span>{entry.date}</span>
              <span>{entry.moodRating}</span>
              <span>{entry.notes}</span>
              <button onClick={() => deleteMood(index)} className="text-red-500 hover:text-red-700">Delete</button>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-6 mt-8 bg-white rounded-lg shadow-md">
        <h3>Mood Statistics</h3>
        <p>Total Entries: {moodStats.totalEntries}</p>
        <p>Average Mood Rating: {moodStats.averageRating}</p>
      </div>
      {showOnboarding && (
        <SerenimindDashboardOnboarding onClose={handleOnboardingClose} />
      )}
    </div>
    </div>
    

        </div>
        
  );
};

export default Dashboardmain;
