import React, { useState } from 'react';
import axios from 'axios'; 

const ForgotPasswordVerification = () => {
  const [code, setCode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make API call to your Django backend for verification
      const response = await axios.post('https://serenimindbackend.onrender.com/api/verification/', { code });
      
      // Handle the response as needed
      console.log(response.data);

      // Redirect or perform other actions based on the response
      window.location.href = '/resetpassword';
    } catch (error) {
      // Handle error
      console.error('Error:', error);
    }
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-md shadow-md">
      <h2 className="mb-4 text-4xl font-bold text-center">Forgot Password <span className='text-[# 0A8754] '>Verification </span></h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="code" className="block mb-2 text-sm font-bold text-gray-700">
              Enter the 6-digit verification code sent to your email
            </label>
            <input
              type="text"
              id="code"
              className="w-full p-2 border rounded-md"
              placeholder="Enter verification code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="bg-[# 0A8754] text-white py-2 px-4 rounded hover:bg-green-600">
          Verify
        </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordVerification;
