import React from 'react'
import MentalCommunity from '../images/MentalCommunity.jpg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';


const CommunityLanding = () => {
  return (

    <div>
        <div>
             {/* First Page in Home */}
      <div className="landing bg-[#efefef] pb-[100px] md:px-12 px-4 ">
        <div className='md:flex'>
          <div className='landing-left md:pt-[60px] pt-[10px]'>
            <h1 className="mb-8 text-3xl font-bold md:text-4xl">
            SereniMind: Embrace, Express, Empower
            </h1>
            <p className="text-lg md:w-[500px]">
            SereniMind is your sanctuary for open expression and support. It's a haven where emotions are embraced, and through shared experiences, we empower one another on the journey to mental well-being.
            </p>
            <button className='bg-[#59844F] text-white mt-8 px-8 py-2 rounded font-bold'>
              <Link to="/community" >Start Your Journey to Serenity Now</Link>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='ml-2' />
            </button>
          </div>
          <div className='image md:ml-[200px] pt-[30px]' >
             <img src={MentalCommunity} alt="" className='w-[600px] h-[400px] rounded s' /> 
          </div>
        </div>
      </div>
        </div>
    </div>
  )
}

export default CommunityLanding