import React from 'react'
import {useRef  } from 'react';
import emailjs from 'emailjs-com';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Contact = () => {
 
  //email sending 
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bt2q0z7', 'template_kwl0ojh', form.current, 'Yz16g8qPG_-f9ArRT')
      .then((result) => {
          console.log(result.text);
          window.alert('Email sent successfully!');
          window.location.reload();
      }, (error) => {
          console.log(error.text);
          window.alert('Failed to send email. Please try again later.');
      });
    };
  
  return (
    <div className="bg-gradient-to-r from-[#eaeaec] to-[#f5f5f7]">
    <div className=" mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl py-8">
      <h1 className="mb-12 text-4xl md:text-5xl font-bold text-center">
        <span className="text-[#59844F]">Contact</span> Us
      </h1>
  
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
        <div className="bg-white rounded-lg shadow-md p-8 transition-all duration-300 hover:shadow-lg">
          <h2 className="mb-6 text-2xl font-semibold text-[#59844F]">Get in Touch</h2>
          <p className="mb-6 text-gray-700">
            We'd love to hear from you! Feel free to reach out to us with any questions, inquiries, or partnership opportunities.
            Our team is ready to assist you.
          </p>
          <div className="space-y-4">
            <div className="flex items-center">
              <svg className="w-6 h-6 text-[#59844F] mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
              </svg>
              <a href="mailto:team@serenimind.com.ng" className="text-blue-600 hover:underline transition-colors duration-300">team@serenimind.com.ng</a>
            </div>
            <div className="flex items-center">
              <svg className="w-6 h-6 text-[#59844F] mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
              </svg>
              <a href="tel:+2347078634362" className="text-blue-600 hover:underline transition-colors duration-300">+234 707 863 4362</a>
            </div>
          </div>
        </div>
  
        <div className="bg-white rounded-lg shadow-md p-8 transition-all duration-300 hover:shadow-lg">
          <h2 className="mb-6 text-2xl font-semibold text-[#59844F]">Visit Us</h2>
          <p className="mb-6 text-gray-700">
            Come and visit our office! We are conveniently located and would be delighted to meet you in person.
          </p>
          <div className="space-y-4">
            <div className="flex items-start">
              <svg className="w-6 h-6 text-[#59844F] mr-3 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
              </svg>
              <address className="not-italic text-gray-700">
                Timingotech Headquarters<br />
                Bello Folawuyo Crescent,<br />
                Lagos State, 10001,<br />
                Nigeria
              </address>
            </div>
          </div>
        </div>
      </div>
  
      <div className="mb-16">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.3890697656566!2d3.3814973999999998!3d6.5984742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b92f7be04a25f%3A0xc88fd941399f558!2sBello%20Folawiyo%20Cres%2C%20Ikosi%20Ketu%2C%20Ikosi%20Ketu%2FMile%2012%2FAgiliti%2FMaidan%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1723572936790!5m2!1sen!2sng"
          width="100%" 
          height="450" 
          style={{border: 0}} 
          allowFullScreen="" 
          loading="lazy"
          className="rounded-lg shadow-md">
        </iframe>
      </div>
  
      <div className='bg-white rounded-lg shadow-md p-8'>
        <h2 className="mb-8 text-3xl font-bold text-center">Leave a <span className='text-[#59844F]'>Message</span></h2>
        <div className='flex justify-center'>
          <form ref={form} onSubmit={sendEmail} className="space-y-6 w-full max-w-[500px]">
            <div>
              <label htmlFor="name" className="block mb-2 text-lg font-medium text-gray-700">Name:</label>
              <input type="text" id="name" name="user_name" placeholder="Your name" className="w-full px-4 py-3 border rounded-md bg-[#f5f5f7] focus:outline-none focus:ring-2 focus:ring-[#0A8754]" required />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-lg font-medium text-gray-700">Email:</label>
              <input type="email" id="email" name="user_email" placeholder="Your email" className="w-full px-4 py-3 border rounded-md bg-[#f5f5f7] focus:outline-none focus:ring-2 focus:ring-[#0A8754]" required />
            </div>
            <div>
              <label htmlFor="message" className="block mb-2 text-lg font-medium text-gray-700">Message:</label>
              <textarea id="message" name="message" placeholder="Your message" className="w-full px-4 py-3 border rounded-md bg-[#f5f5f7] focus:outline-none focus:ring-2 focus:ring-[#0A8754]" rows="4" required></textarea>
            </div>
            <button type="submit" className="w-full bg-[#59844F] text-white px-8 py-3 rounded-md font-bold transition-colors duration-300 hover:bg-[#086A43]">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  
    <a href="https://wa.me/2347078634362?text=Hello%2C%20I%20have%20a%20question%20concerning%20your%20service." className="fixed bottom-6 right-6 bg-[#25D366] text-white p-4 rounded-full shadow-lg hover:bg-[#128C7E] transition-colors duration-300">
      <FontAwesomeIcon icon={faWhatsapp} className="text-3xl" />
    </a>
  </div>
  )
}

export default Contact