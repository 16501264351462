import React, {useState}from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser, faCalendar, faL } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import BlogData from "../blogpost/BlogData";
import {useRef  } from 'react';
import emailjs from 'emailjs-com';
import { ClipLoader } from 'react-spinners'; // Install this with 'npm install react-spinners'

const BlogPost = () => {
  const { id } = useParams();
  const form = useRef();
  const blog = BlogData.find(blog => blog.id === parseInt(id));
  const [loading, setLoading] = useState(false); // New loading state

  if (!blog) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-2xl font-bold text-gray-800">Blog post not found</h1>
      </div>
    );
  }

    //email sending 

    const sendEmail = (e) => {
      setLoading(true);
      e.preventDefault();
      emailjs.sendForm('service_bt2q0z7', 'template_kwl0ojh', form.current, 'Yz16g8qPG_-f9ArRT')
        .then((result) => {
            console.log(result.text);
            setLoading(false);
            window.alert('Email sent successfully!');
        }, (error) => {
            console.log(error.text);
            window.alert('Failed to send email. Please try again later.');
        });
      };
    
  const { title, author, date, readingTime, maincontent, image, socialMedia } = blog;

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <article className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img 
          src={image} 
          alt={title} 
          className="w-full md:h-[350px] object-cover object-center"
        />
        <div className="p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">{title}</h1>
          <div className="flex items-center text-sm text-gray-600 mb-6">
            <span className="mr-3">
              <FontAwesomeIcon icon={faUser} className="mr-1" />
              {author}
            </span>
            <span className="mr-3">
              <FontAwesomeIcon icon={faCalendar} className="mr-1" />
              {date}
            </span>
            <span>
              <FontAwesomeIcon icon={faClock} className="mr-1" />
              {readingTime}
            </span>
          </div>
          <div 
            className="prose max-w-none"
            dangerouslySetInnerHTML={{ __html: maincontent }} 
          />
        </div>
      </article>
      
      <div className="mt-8 bg-gray-100 p-6 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Connect with {author}</h2>
        <div className="flex items-center">
          {socialMedia.twitter && (
            <a 
              href={socialMedia.twitter} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-2xl text-blue-400 hover:text-blue-600 mr-4 transition-colors"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          )}
          {socialMedia.instagram && (
            <a 
              href={socialMedia.instagram} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-2xl text-pink-500 hover:text-pink-700 transition-colors"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          )}
          {socialMedia.linkedin && (
            <a 
              href={socialMedia.linkedin} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-2xl text-blue-500 hover:text-blue-700 transition-colors ml-4"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          )}
        </div>
      </div>
      <div className='bg-white rounded-lg shadow-md p-8'>
        <h2 className="mb-8 text-3xl font-bold text-center">Give us your <span className='text-[#59844F]'>Feedback</span></h2>
        <div className='flex justify-center'>
          <form ref={form} onSubmit={sendEmail} className="space-y-6 w-full max-w-[500px]">
            <div>
              <label htmlFor="name" className="block mb-2 text-lg font-medium text-gray-700">Name:</label>
              <input type="text" id="name" name="user_name" placeholder="Your name" className="w-full px-4 py-3 border rounded-md bg-[#f5f5f7] focus:outline-none focus:ring-2 focus:ring-[#0A8754]" required />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-lg font-medium text-gray-700">Email:</label>
              <input type="email" id="email" name="user_email" placeholder="Your email" className="w-full px-4 py-3 border rounded-md bg-[#f5f5f7] focus:outline-none focus:ring-2 focus:ring-[#0A8754]" required />
            </div>
            <div>
              <label htmlFor="message" className="block mb-2 text-lg font-medium text-gray-700">Message:</label>
              <textarea id="message" name="message" placeholder="Your message" className="w-full px-4 py-3 border rounded-md bg-[#f5f5f7] focus:outline-none focus:ring-2 focus:ring-[#0A8754]" rows="4" required></textarea>
            </div>
            <button type="submit" className="w-full bg-[#59844F] text-white px-8 py-3 rounded-md font-bold transition-colors duration-300 hover:bg-[#086A43]">Send Message</button>
          </form>
        </div>
        {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-green bg-opacity-75 z-50">
          <ClipLoader size={150} color={"#59844F"} />
        </div>
      )}
      </div>
    </div>
  );
};

export default BlogPost;