import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post('https://serenimindbackend.onrender.com/api/password_reset/', { email });
      setSuccessMessage('Password reset link sent. Check your email.');
      setIsLoading(false);
      setErrorMessage('');
      navigate('/resetpasswordsuccess');
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage('Email not found in our records. Please try again.');
      setIsLoading(false);
    }
  };
  
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white rounded-md shadow-md">
        <h2 className="mb-4 text-4xl font-bold text-center">
          Forget <span className="text-[# 0A8754]">Password</span>
        </h2>
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 text-sm font-bold text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border rounded-md"
              placeholder="Enter your email"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-[#59844F] text-white py-2 px-4 rounded hover:bg-green-600"
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Reset Password'}
          </button>
        </form>
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}      </div>
    </div>
  );
};

export default ForgotPassword;
