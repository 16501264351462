import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "./AuthContext ";
import MoodAssessment from '../accessible-components/MoodAssessment'; // Import the mood assessment component

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setToken, setRefreshToken } = useContext(AuthContext);
  const [showMoodQuiz, setShowMoodQuiz] = useState(false); // State to control quiz visibility

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const user = { username, password };

    try {
      const { data } = await axios.post(
        "https://serenimindbackend.onrender.com/api/token/",
        user,
        { headers: { "Content-Type": "application/json" }, withCredentials: true }
      );
      setToken(data.access);
      setRefreshToken(data.refresh);
      localStorage.setItem("token", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;
      const isFirstLogin = true; // Replace this with actual logic to check the user's first login
      if (isFirstLogin) {
        setShowMoodQuiz(true); // Show the quiz
      } else {
        navigate("/dashboardmain");
      }

      onLogin();
    } catch (error) {
      setErrorMessage("Your username or password is incorrect" || "An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseMoodQuiz = () => {
    setShowMoodQuiz(false);
    navigate("/dashboardmain"); // Navigate to the dashboard after closing the quiz
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 px-4">
  <div className="absolute top-0 right-0 w-48 h-48 bg-green-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
  <div className="absolute bottom-0 left-0 w-48 h-48 bg-yellow-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
  <div className="absolute top-0 left-1/4 w-48 h-48 bg-pink-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>

  <div className="w-full max-w-md">
    <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
      <div className="px-8 py-10">
        <h2 className="mb-6 text-4xl font-bold text-center text-gray-800">
          <span className="text-[#59844F]">Welcome</span> back!
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59844F] focus:border-transparent transition"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {errorMessage && (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded" role="alert">
              <p>{errorMessage}</p>
            </div>
          )}
          <button
            type="submit"
            className="w-full bg-[#59844F] text-white py-3 px-4 rounded-md hover:bg-[#4b7443] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#59844F] transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            {isLoading ? (
              <svg className="animate-spin h-5 w-5 mx-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              "Login"
            )}
          </button>
        </form>
        <div className="mt-8 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{" "}
            <Link to="/signup" className="font-medium text-[#59844F] hover:text-[#4b7443] transition">
              Sign Up
            </Link>
          </p>
        </div>
        <div className="mt-4 text-center">
          <Link to="/forgotpassword" className="text-sm font-medium text-[#59844F] hover:text-[#4b7443] transition">
            Forgot your password?
          </Link>
        </div>
      </div>
    </div>
  </div>
  <MoodAssessment isOpen={showMoodQuiz} onClose={handleCloseMoodQuiz} />
</div>
  );
};

export default Login;
