import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext ";

const PrivateRoutes = ({ onLogin }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Or a spinner
  }

  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
