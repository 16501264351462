import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import HappySpounge from '../images/HappySpounge.png'

const ResetPasswordSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <img src={HappySpounge} alt="Success" className="mb-8 h-[300px] animate-bounce" />
      <h1 className="mb-4 text-3xl font-bold">Password Reset Link Sent</h1>
      <p className="mb-6 text-lg text-center text-gray-700">
        A password reset link has been sent to your email. Please check your inbox and follow the instructions to reset your password.
      </p>
      <Link to="/login" className="text-blue-500 hover:text-blue-700">Back to Login</Link>
    </div>
  );
};

export default ResetPasswordSuccess;
